import { Box } from "@chakra-ui/layout";
import React from "react";
import ClientesComponent from "../../components/Vendedor/Clientes/Clientes";
const Clientes = () => {
  return (
    <Box bg={"gray.50"} h={"100vh"}>
      {/* <Header /> */}
      <ClientesComponent />
    </Box>
  );
};

export default Clientes;
