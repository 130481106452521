import * as AWS from "aws-sdk";
import { customAlphabet } from "nanoid";
//AWS.config.update({ region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' }); //DESARROLLO
AWS.config.update({
  region: "us-east-1",
  secretAccessKey: "3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi",
  accessKeyId: "AKIARVGY33F62XNKJJK3",
}); //PRODUCCION
const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: "2022-01-01" });
const nanoid = customAlphabet("0123456789", 30);
//CONTRATOS
export const get_proyectos = (user) => async (dispatch) => {
  dispatch({ type: "LOAD_PROYECTOS" });
  try {
    var params = {
      TableName: "Proyectos",
      FilterExpression: "#vendedor = :vendedor",
      ExpressionAttributeNames: {
        "#vendedor": "vendedor",
      },
      ExpressionAttributeValues: {
        ":vendedor": user,
      },
    };
    docClient.scan(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        dispatch({ type: "GET_PROYECTOS", payload: data.Items });
      }
    });
  } catch (error) {
    return [];
  }
};
export const get_proyecto_by_id = (id) => async (dispatch) => {
  dispatch({ type: "LOAD_PROYECTOS" });
  try {
    var params = {
      TableName: "Proyectos",
      FilterExpression: "#id = :id",
      ExpressionAttributeNames: {
        "#id": "id",
      },
      ExpressionAttributeValues: {
        ":id": id,
      },
    };
    docClient.scan(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        dispatch({ type: "GET_PROYECTO_BY_ID", payload: data.Items });
      }
    });
  } catch (error) {
    return [];
  }
};
export const edit_proyecto =
  (
    id,
    nombreProyecto,
    oportunidad,
    tipo,
    lineaNegocio,
    lineaNegocioNombre,
    categoria,
    nombreEmpresa,
  ) =>
  async (dispatch) => {
    //, #oportunidad = :oportunidad, #tipo = :tipo, #lineaNegocio = :lineaNegocio, #lineaNegocioNombre = :lineaNegocioNombre, #categoria = :categoria , #nombreEmpresa = :nombreEmpresa
    const params = {
      TableName: "Proyectos",
      Key: {
        id: id,
      },
      UpdateExpression:
        "set #nombreProyecto = :nombreProyecto, #oportunidad = :oportunidad , #tipo = :tipo, #lineaNegocio =:lineaNegocio, #lineaNegocioNombre = :lineaNegocioNombre, #categoria = :categoria",
      ExpressionAttributeNames: {
        "#nombreProyecto": "nombreProyecto",
        "#oportunidad": "oportunidad",
        "#tipo": "tipo",
        "#lineaNegocio": "lineaNegocio",
        "#lineaNegocioNombre": "lineaNegocioNombre",
        "#categoria": "categoria",
        // "#nombreEmpresa": "nombreEmpresa"
      },
      ExpressionAttributeValues: {
        ":nombreProyecto": nombreProyecto,
        ":oportunidad": oportunidad,
        ":tipo": tipo,
        ":lineaNegocio": lineaNegocio,
        ":lineaNegocioNombre": lineaNegocioNombre,
        ":categoria": categoria,
        // ":nombreEmpresa": nombreEmpresa
      },
    };

    docClient.update(params, function (err, data) {
      if (err) {
        console.log("Error", err);
      } else {
        dispatch({ type: "UPDATE_VIDEOS", payload: "Guardado..." });
      }
    });
  };

export const delete_proyecto = (id) => async (dispatch) => {
  //console.log("Este es el nuevo estatus---", id)

  const params = {
    TableName: "Proyectos",
    Key: {
      id: id,
    },
  };

  docClient.delete(params, function (err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      dispatch({ type: "DELETE_PROYECTO" });
    }
  });
};
