import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Login/Login";
import Validar from "./Validar";
import HomeVendedor from "../pages/HomeVendedor";
import HomeGerente from "../pages/HomeGerente";
import HomeAdmin from "../pages/HomeAdmin";
import HomeCliente from "../pages/HomeClientes";
import useToken from "./useToken";
import ForgotPassword from "../pages/ForgotPassword";
import Propuestas from "../pages/Propuestas";
import Presentaciones from "../pages/Presentaciones";
import NewPassword from "../components/Login/Newpassword";
import ResetPasswordConfirmate from "../components/Login/ResetPasswordConfirmate";
import ForgotConfirmate from "../components/Login/ForgotConfirmate";
import NewClientesAdmin from "../pages/Admin/New";
import ClientesVendedor from "../pages/Vendedor/Clientes";
import NewClientesVendedor from "../pages/Vendedor/New";
import VendedoresGerente from "../pages/Gerentes/Vendedores";
import DetallesVededoresClientes from "../pages/Vendedor/Detalles";
import VendedorPresentaciones from "../components/Vendedor/Presentaciones/presentaciones";
import VendedorPropuestas from "../components/Vendedor/Propuestas/propuestas";
import VendedorPropuestasEditor from "../components/Vendedor/Propuestas/Editor";
import VendedorMedia from "../components/Vendedor/Media/media";
import VendedorMediaVideo from "../components/Vendedor/Media/subirVideo";
import VendedorContratos from "../components/Vendedor/Contratos/contratos";
import VendedorProyectos from "../pages/Vendedor/Proyectos";
import VendedorPerfil from "../pages/Vendedor/Perfil";
import DetallesCliente from "../pages/Clientes/Detalles";
import VendedoresAdmin from "../components/Admin/Vendedores/Vendedores";
import ClientesAdmin from "../components/Admin/Clientes/Clientes";
import ProyectosGerente from "../pages/Gerentes/Proyectos";
import CatalogosAdmin from "../components/Admin/Utilidades/Catalogos";
import Share from "./Vendedor/Clientes/Preview";
import ConfiguracionAdmin from "./Admin/Utilidades/Configuracion";
import Compartir from "./Vendedor/Clientes/Compartir";
import CompartirDominio from "./Vendedor/Clientes/CompartirDominio";
import WhatsApp from "./Paginas/whatsapp/compartir";
import GerentesLista from "./Admin/Gerentes/Lista";
import Graficas from "../pages/Gerentes/Graficas";
import Attachments from "./Vendedor/Attachment";
import Videos from "./Vendedor/Videos";
import VideosEdit from "./Vendedor/Videos/edit";
import ClientesGerente from "../components/Gerente/Clientes/clientes";
import ComponentesAdmin from "../components/Admin/Utilidades/Componentes";
import Inicio from "../pages/Vendedor/Inicio";
import Clean from "./clean";
// function setToken(userToken) {
//     console.log("setToken-routes", userToken)
//     sessionStorage.setItem('token', JSON.stringify(userToken));
// }

// function getToken() {
//     const tokenString = sessionStorage.getItem('token');
//   const userToken = JSON.parse(tokenString);
//   return userToken?.token
// }

function Application(params) {
  const { token, setToken } = useToken();

  if (
    !token &&
    window.location.pathname !== "/signup" &&
    window.location.pathname !== "/menu" &&
    window.location.pathname !== "/welcome" &&
    window.location.pathname !== "/forgotpassword" &&
    window.location.pathname !== "/forgotconfirmate" &&
    window.location.pathname !== "/resetpassword" &&
    window.location.pathname !== "/resetpasswordconfirmate" &&
    window.location.pathname !== "/vendedor/media/video"
  ) {
    return <Login setToken={setToken} />;
  }
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Validar />} />
      </Routes>
      <Routes>
        <Route path="/vendedor" element={<ClientesVendedor />} />
        <Route path="/inicio" element={<Inicio />} />
      </Routes>

      <Routes>
        <Route path="/admin" element={<HomeAdmin />} />
        <Route path="/admin/vendedores" element={<VendedoresAdmin />} />
        <Route path="/admin/clientes" element={<HomeVendedor />} />
        <Route path="/admin/catalogos" element={<CatalogosAdmin />} />
        <Route path="/admin/componentes" element={<ComponentesAdmin />} />
        <Route path="/admin/configuracion" element={<ConfiguracionAdmin />} />
        <Route path="/admin/gerentes/lista" element={<GerentesLista />} />
      </Routes>
      <Routes>
        <Route path="/gerente" element={<HomeGerente />} />
        <Route path="/gerente/vendedores" element={<VendedoresAdmin />} />
        <Route path="/gerente/clientes" element={<ClientesAdmin />} />
        <Route path="/gerente/proyectos" element={<ProyectosGerente />} />
        <Route path="/gerente/graficas" element={<Graficas />} />
        <Route
          path="/gerente/presentaciones"
          element={<VendedorPresentaciones />}
        />
        <Route path="/gerente/propuestas" element={<VendedorPropuestas />} />
        <Route path="/gerente/attachments" element={<Attachments />} />
        <Route path="/gerente/crear_proyecto" element={<ClientesGerente />} />
        <Route path="/gerente/preview" element={<Share />} />
        <Route
          path="/compartir/whatsApp/:proyecto/:id/:nombreCliente"
          element={<WhatsApp />}
        />
      </Routes>
      <Routes>
        <Route path="/cliente" element={<HomeCliente />} />
        <Route path="/cliente/detalles" element={<DetallesCliente />} />
      </Routes>
      <Routes>
        <Route path="/login" element={<Login />} />
      </Routes>
      <Routes>
        <Route path="/forgotconfirmate" element={<ForgotConfirmate />} />
      </Routes>
      <Routes>
        <Route
          path="/resetpasswordconfirmate"
          element={<ResetPasswordConfirmate />}
        />
      </Routes>
      <Routes>
        <Route path="/forgotpassword" element={<ForgotPassword />} />
      </Routes>
      <Routes>
        <Route path="/resetpassword" element={<NewPassword />} />
      </Routes>
      {/* Rutas para Administradores */}

      <Routes>
        <Route path="/admin/clientes/new" element={<NewClientesAdmin />} />
      </Routes>
      {/* Rutas para Gerentes */}
      <Routes>
        <Route path="/gerente/vendedores" element={<VendedoresGerente />} />
      </Routes>

      {/* Rutas para Vendedores */}
      <Routes>
        <Route path="/vendedor/clientes" element={<ClientesVendedor />} />
      </Routes>
      <Routes>
        <Route
          path="/vendedor/clientes/new"
          element={<NewClientesVendedor />}
        />
      </Routes>
      <Routes>
        <Route
          path="/vendedor/clientes/detalles/:id/:nombreCliente"
          element={<DetallesVededoresClientes />}
        />
        <Route
          path="/vendedor/presentaciones"
          element={<VendedorPresentaciones />}
        />
        <Route path="/vendedor/propuestas" element={<VendedorPropuestas />} />
        <Route
          path="/vendedor/propuestas/editor"
          element={<VendedorPropuestasEditor />}
        />
        <Route path="/vendedor/contratos" element={<VendedorContratos />} />
        <Route path="/vendedor/media" element={<VendedorMedia />} />
        <Route path="/vendedor/media/video" element={<VendedorMediaVideo />} />
        <Route
          path="/vendedor/proyectos/:proyecto/:id/:nombreCliente"
          element={<VendedorProyectos />}
        />
        <Route path="/vendedor/perfil" element={<VendedorPerfil />} />
        <Route path="/vendedor/preview" element={<Share />} />
        <Route
          path="/vendedor/compartir/:proyecto/:id/:nombreProyecto/:nombreCliente"
          element={<Compartir />}
        />
        <Route
          path="/vendedor/compartirDominio/:proyecto/:id/:nombreProyecto/:nombreCliente"
          element={<CompartirDominio />}
        />
        <Route path="/vendedor/attachments" element={<Attachments />} />
        <Route path="/vendedor/videos" element={<Videos />} />
        <Route path="/vendedor/videos/edit/:id" element={<VideosEdit />} />
      </Routes>
      {/* Rutas para las Propuestas */}
      <Routes>
        <Route path="/propuestas" element={<Propuestas />} />
      </Routes>
      {/* Rutas para las Presentaciones */}
      <Routes>
        <Route path="/presentaciones" element={<Presentaciones />} />
      </Routes>
      <Routes>
        <Route path="/clean_bucket" element={<Clean />} />
      </Routes>
    </Router>
  );
}

export default Application;
