import React, { useState } from "react";
import { Spinner, Input, Button, Box, Center, Flex } from "@chakra-ui/react";
import Header from "../../Header";
import useUser from "../../context/useUser";
import { useQuery } from "react-query";
import MenuVendedor from "../Utilidades/MenuVendedor";
import { useParams } from "react-router-dom";
import * as AWS from "aws-sdk";
//AWS.config.update({ region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' });
AWS.config.update({
  region: "us-east-1",
  secretAccessKey: "3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi",
  accessKeyId: "AKIARVGY33F62XNKJJK3",
});

const Perfil = () => {
  let { id } = useParams();
  const [urlStrapi] = useState(process.env.REACT_APP_URL_STRAPI);
  const [perfil, setPerfil] = useState([]);
  const { user } = useUser();
  const [puesto, setPuesto] = useState();
  const [telefono, setTelefono] = useState();
  const [email, setEmail] = useState();
  const [whatsapp, setWhatsApp] = useState();
  const [descripcion, setDescripcion] = useState();
  const docClient = new AWS.DynamoDB.DocumentClient({
    apiVersion: "2022-01-01",
  });
  async function fetchClientes() {
    const clientes = fetch(urlStrapi + "/clientes/" + id).then((response) =>
      response.json(),
    );
    return clientes;
  }
  const fetchData = () => {
    //console.log("fetchDayaID:", id)
    var params = {
      TableName: "Perfil",
      Limit: 1,
    };
    docClient.scan(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        // console.log("Query succeeded.", data);
        setPerfil(data.Items);
        // data.Items.forEach(function(item) {
        //     console.log(item);

        // });
      }
    });
  };

  const putData = (tableName, data) => {
    console.log(tableName, data, puesto);
    var params = {
      TableName: tableName,
      Item: data,
    };

    docClient.put(params, function (err, data) {
      if (err) {
        console.log("Error", err);
      } else {
        window.location = "/vendedor";
      }
    });
  };

  const crearPerfil = async () => {
    const perfilData = {
      id: user.id.toString(),
      name: user.username.S,
      puesto,
      telefono,
      email,
      whatsapp,
      descripcion,
    };

    await putData("Perfil", perfilData);
  };
  useQuery("Perfil", fetchData, {
    cacheTime: Infinity,
  });

  const { isLoading } = useQuery("CLIENTES", fetchClientes, {
    cacheTime: Infinity,
  });
  if (isLoading) {
    return (
      <Center mt={"10%"}>
        <Spinner color={"green.700"} />
      </Center>
    );
  }

  const handlerPuesto = (e) => {
    setPuesto(e.target.value);
  };
  const handlerEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlerTelefono = (e) => {
    setTelefono(e.target.value);
  };
  const handlerWhatsApp = (e) => {
    setWhatsApp(e.target.value);
  };
  const handlerDescripcion = (e) => {
    setDescripcion(e.target.value);
  };
  console.log("perfil", perfil);

  return (
    <>
      <Flex direction={{ base: "column", lg: "row" }} bg={"pink"} h={"100vh"}>
        <MenuVendedor />
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>

            <Box bg={"gray.200"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
              <Flex direction={"column"}>
                <Box bg={"gray.100"} h={{ base: "25vh", lg: "25vh" }}>
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bgGradient="linear(to-r, gray.50, gray.200)"
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"gray.200"}
                      w={{ base: "100%", lg: "60%" }}
                      h={{ base: "25vh", lg: "25vh" }}
                    >
                      <Flex direction={"column"}>
                        <Box bg={"gray.300"} h={"12.5vh"}>
                          <Flex direction={"row"}>
                            <Box bg={"gray.500"} w={"70%"} h={"12.5vh"}>
                              <Flex direction={"column"}>
                                <Box bg={"gray.200"} w={"100%"} h={"8.25vh"}>
                                  <Box
                                    alignSelf={"end"}
                                    fontSize={{
                                      lg: "21px",
                                      base: "23px",
                                      "2xl": "33px",
                                    }}
                                    fontWeight={"semibold"}
                                    color={"gray.700"}
                                    fontFamily={"system-ui, sans-serif"}
                                    ml={{ lg: "0", base: "5" }}
                                    mt={{ lg: "6", base: "5" }}
                                  >
                                    Mi perfil{" "}
                                  </Box>
                                </Box>
                                <Box bg={"gray.200"} w={"100%"}>
                                  <Box
                                    fontSize={"17px"}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    ml={{ lg: "0", base: "5" }}
                                    fontFamily={"system-ui, sans-serif"}
                                  ></Box>
                                </Box>
                                <Box bg={"gray.200"} w={"100%"} h={"4.25vh"}>
                                  <Box
                                    fontSize={"sm"}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    fontFamily={"system-ui, sans-serif"}
                                    ml={{ lg: "0", base: "5" }}
                                    mb={{ lg: "10", base: "0" }}
                                  >
                                    Aquí podras cambiar datos básicos, como tú
                                    nombre, puesto etc.
                                  </Box>
                                </Box>
                              </Flex>
                            </Box>
                            <Box bg={"gray.200"} w={"30%"} h={"12.5vh"}>
                              <Flex direction={"column"} justifyContent={"end"}>
                                <Box
                                  bg={"grya.100"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                ></Box>
                                <Box
                                  bg={"grya.100"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                ></Box>
                              </Flex>
                            </Box>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bgGradient="linear(to-r, gray.200, gray.50)"
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
                <Box
                  bg={"white"}
                  h={{ base: "65vh", lg: "65vh" }}
                  pl={{ base: 0, lg: 0 }}
                  pr={{ base: 0, lg: 0 }}
                >
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"white"}
                      w={{ base: "0", lg: "90%" }}
                      h={{ base: "0", lg: "65vh" }}
                    >
                      <Box bg={"white"} w={"100%"} mt={5}>
                        <Flex
                          justify={"center"}
                          direction={"column"}
                          w={"100%"}
                        >
                          <Box
                            alignSelf={"center"}
                            fontSize={{
                              lg: "21px",
                              base: "23px",
                              "2xl": "33px",
                            }}
                            color={"gray.700"}
                          >
                            Hola {user.username}
                          </Box>
                          <Box
                            alignSelf={"center"}
                            fontSize={{
                              lg: "21px",
                              base: "23px",
                              "2xl": "23px",
                            }}
                            color={"gray.700"}
                            mb={"5"}
                          >
                            Cuentanos más de tí
                          </Box>
                          <Flex
                            alignSelf={"center"}
                            bg={"gray.100"}
                            direction={"column"}
                            w={"40%"}
                            rounded={"15px"}
                          >
                            <Flex
                              justify={"center"}
                              direction={"column"}
                              p={"5"}
                            >
                              <Box>
                                <Box
                                  fontFamily={"system-ui"}
                                  fontSize={"15px"}
                                  my={3}
                                >
                                  Título de tu puesto
                                </Box>
                                <Box
                                  color={"gray.500"}
                                  fontFamily={"system-ui"}
                                  fontStyle={"italic"}
                                  fontSize={"15px"}
                                  my={3}
                                >
                                  {perfil.length === 0 ? "" : perfil[0].puesto}
                                </Box>
                                <Input
                                  onChange={(e) => handlerPuesto(e)}
                                  rounded={"10px"}
                                  type={"text"}
                                  placeholder={"Escribe aquí...."}
                                  bg={"white"}
                                  fontFamily={"system-ui"}
                                  fontSize={"15px"}
                                ></Input>
                              </Box>
                              <Box>
                                <Box
                                  fontFamily={"system-ui"}
                                  fontSize={"15px"}
                                  my={3}
                                >
                                  Ingresa un correo electrónico
                                </Box>
                                <Box
                                  color={"gray.500"}
                                  fontFamily={"system-ui"}
                                  fontStyle={"italic"}
                                  fontSize={"15px"}
                                  my={3}
                                >
                                  {perfil.length === 0 ? "" : perfil[0].email}
                                </Box>
                                <Input
                                  onChange={handlerEmail}
                                  rounded={"10px"}
                                  type={"email"}
                                  placeholder={"Escribe aquí...."}
                                  bg={"white"}
                                  fontFamily={"system-ui"}
                                  fontSize={"15px"}
                                ></Input>
                              </Box>
                              <Box>
                                <Box
                                  fontFamily={"system-ui"}
                                  fontSize={"15px"}
                                  my={3}
                                >
                                  Ingresa un Teléfono
                                </Box>
                                <Box
                                  color={"gray.500"}
                                  fontFamily={"system-ui"}
                                  fontStyle={"italic"}
                                  fontSize={"15px"}
                                  my={3}
                                >
                                  {perfil.length === 0
                                    ? ""
                                    : perfil[0].telefono}
                                </Box>
                                <Input
                                  onChange={handlerTelefono}
                                  rounded={"10px"}
                                  type={"phone"}
                                  placeholder={"Escribe aquí...."}
                                  bg={"white"}
                                  fontFamily={"system-ui"}
                                  fontSize={"15px"}
                                ></Input>
                              </Box>
                              <Box>
                                <Box
                                  fontFamily={"system-ui"}
                                  fontSize={"15px"}
                                  my={3}
                                >
                                  Ingresa un Teléfono WhatsApp
                                </Box>
                                <Box
                                  color={"gray.500"}
                                  fontFamily={"system-ui"}
                                  fontStyle={"italic"}
                                  fontSize={"15px"}
                                  my={3}
                                >
                                  {perfil.length === 0
                                    ? ""
                                    : perfil[0].whatsapp}
                                </Box>
                                <Input
                                  onChange={handlerWhatsApp}
                                  rounded={"10px"}
                                  type={"text"}
                                  placeholder={"Escribe aquí...."}
                                  bg={"white"}
                                  fontFamily={"system-ui"}
                                  fontSize={"15px"}
                                ></Input>
                              </Box>
                              <Box>
                                <Box
                                  fontFamily={"system-ui"}
                                  fontSize={"15px"}
                                  my={3}
                                >
                                  Escribe una breve descripción sobre tí
                                </Box>
                                <Box
                                  color={"gray.500"}
                                  fontFamily={"system-ui"}
                                  fontStyle={"italic"}
                                  fontSize={"15px"}
                                  my={3}
                                >
                                  {perfil.length === 0
                                    ? ""
                                    : perfil[0].descripcion}
                                </Box>
                                <Input
                                  onChange={handlerDescripcion}
                                  rounded={"10px"}
                                  type={"text"}
                                  placeholder={"Escribe aquí...."}
                                  bg={"white"}
                                  fontFamily={"system-ui"}
                                  fontSize={"15px"}
                                ></Input>
                              </Box>
                              <Box my={"5"} alignSelf={"end"}>
                                <Button
                                  onClick={crearPerfil}
                                  bg={"orange"}
                                  _hover={{ bg: "orage.500" }}
                                >
                                  Guardar
                                </Button>
                              </Box>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Box>
                    </Box>
                    {/* Tabla para movil*/}
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Perfil;
