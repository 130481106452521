import React, { useEffect, useState } from "react";
import {
  Spinner,
  Input,
  Button,
  Box,
  Center,
  useDisclosure,
  Flex,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tag,
} from "@chakra-ui/react";
import Header from "../../Header";
import useUser from "../../context/useUser";
import { useQuery } from "react-query";
import axios from "axios";
import MenuVendedor from "../Utilidades/MenuVendedor";
import { BiSlideshow } from "react-icons/bi";
import Moment from "react-moment";
import "moment-timezone";
import UploadVideosS3 from "../../UploadVideoS3";
import VideoConverter from "convert-video";
import * as AWS from "aws-sdk";
//AWS.config.update({ region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' });
AWS.config.update({
  region: "us-east-1",
  secretAccessKey: "3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi",
  accessKeyId: "AKIARVGY33F62XNKJJK3",
});

const Media = () => {
  const [datos, setDatos] = useState([]);
  const [urlVideos] = useState(process.env.REACT_APP_URL_VIDEOS);
  const { user } = useUser();
  const { isOpen, onClose } = useDisclosure();
  const [nombreVideo, setNombreVideo] = useState();
  const [archivo, setArchivo] = useState();
  const [qr, setQr] = useState();
  const docClient = new AWS.DynamoDB.DocumentClient({
    apiVersion: "2022-01-01",
  });
  async function generateQR() {
    //const url = `https://nctechnegocio.vercel.app/vendedor/media/video?token=${user.id.toString()}` //Cambiar para producción
    const url = `${urlVideos}/vendedor/media/video?token=${user.id.toString()}`; //Cambiar para producción
    const urlQR =
      await `http://api.qrserver.com/v1/create-qr-code/?data=${url}&size=300x300`;
    setQr(urlQR);
  }
  const fetchData = () => {
    var params = {
      TableName: "Videos",
      FilterExpression: "#nom = :name",
      ExpressionAttributeNames: {
        "#nom": "usuario",
      },
      ExpressionAttributeValues: {
        ":name": user.id.toString(),
      },
    };
    docClient.scan(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        setDatos(data.Items);
      }
    });
  };

  const deleteData = (id) => {
    var params = {
      TableName: "Videos",
      Key: {
        id: id,
      },
    };

    docClient.delete(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        console.log("Eliminado correctamente");
        refetch();
      }
    });
  };

  async function fetchVideos() {
    //console.log("refetch")
    //const urlC = `${urlCassandra}collections/videos${user.id.toString()}?page-size=20`
    const urlC = `https://servicesemail-production.herokuapp.com/api/videos/${user.id.toString()}`;
    const ventasData = await axios(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    console.log(ventasData.response);
    const videos = await Object.keys(ventasData.data).map((item) => ({
      id: item,
      ...ventasData.data[item],
    }));
    return videos;
  }
  async function actualizar() {
    //const urlC = `${urlCassandra}collections/videos${user.id.toString()}?page-size=20`
    const urlC = `https://servicesemail-production.herokuapp.com/api/videos/${user.id.toString()}`;
    const ventasData = await axios(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    console.log(ventasData.response);
    const videos = await Object.keys(ventasData.data).map((item) => ({
      id: item,
      ...ventasData.data[item],
    }));
    return videos;
  }
  useEffect(() => {
    generateQR();
  }, []);

  const { isLoading, isFetching, data, refetch } = useQuery(
    "videos",
    fetchData,
    {
      onSuccess: (data) => {
        console.log(data);
        //setDatos(data)
      },
      cacheTime: Infinity,
    },
  );
  if (isLoading) {
    return (
      <Center mt={"10%"}>
        <Spinner color={"green.700"} />
      </Center>
    );
  }

  const addCliente = () => {
    window.location.href = "/vendedor/clientes/new";
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // fetchCliente(event.target.value)
    }
  };

  const handlerNombreVideo = (e) => {
    setNombreVideo(e);
  };

  async function convertVideo(input) {
    let sourceVideoFile = input.target.files[0];
    let targetVideoFormat = "mp4";
    let convertedVideoDataObj = await VideoConverter.convert(
      sourceVideoFile,
      targetVideoFormat,
    );
    setArchivo(convertedVideoDataObj);
  }
  function downloadVideo(convertedVideoDataObj) {
    console.log(archivo);
    let a = document.createElement("a");
    a.href = archivo.data;
    a.download = archivo.name + "." + archivo.format;
    a.click();
  }

  const camaraDesk = () => {
    window.open(
      `${urlVideos}/vendedor/media/video?token=${user.id.toString()}`,
      "_blank",
    );
  };

  return (
    <>
      <Modal
        size={"xl"}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        zIndex={"9999"}
      >
        <div>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              fontSize={"lg"}
              fontWeight={"bold"}
              fontFamily={"system-ui, sans-serif"}
            >
              Subir nuevo video
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Box>
                <Box
                  my={1}
                  fontSize={"sm"}
                  fontWeight={"medium"}
                  color={"gray.500"}
                  fontFamily={"system-ui, sans-serif"}
                >
                  Escribe un nombre para tu video
                </Box>
                <Input
                  onChange={(e) => handlerNombreVideo(e.target.value)}
                  type={"text"}
                  placeholder={"Video de Prueba"}
                  fontSize={"sm"}
                  fontWeight={"normal"}
                  color={"gray.800"}
                  fontFamily={"system-ui, sans-serif"}
                />
              </Box>
              <UploadVideosS3
                nombre={nombreVideo}
                cerrar={onClose}
                query={actualizar}
              />
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </div>
      </Modal>
      <Flex direction={{ base: "column", lg: "row" }} bg={"pink"} h={"100vh"}>
        <MenuVendedor />
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>

            <Box bg={"gray.200"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
              <Flex direction={"column"}>
                <Box bg={"gray.100"} h={{ base: "25vh", lg: "25vh" }}>
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bgGradient="linear(to-r, gray.50, gray.200)"
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"gray.200"}
                      w={{ base: "100%", lg: "60%" }}
                      h={{ base: "25vh", lg: "25vh" }}
                    >
                      <Flex direction={"column"}>
                        <Box bg={"gray.300"} h={"12.5vh"}>
                          <Flex direction={"row"}>
                            <Box bg={"gray.500"} w={"70%"} h={"12.5vh"}>
                              <Flex direction={"column"}>
                                <Box bg={"gray.200"} w={"100%"} h={"8.25vh"}>
                                  <Box
                                    alignSelf={"end"}
                                    fontSize={{
                                      lg: "21px",
                                      base: "23px",
                                      "2xl": "33px",
                                    }}
                                    fontWeight={"semibold"}
                                    color={"gray.700"}
                                    fontFamily={"system-ui, sans-serif"}
                                    ml={{ lg: "0", base: "5" }}
                                    mt={{ lg: "6", base: "5" }}
                                  >
                                    Biblioteca de Videos
                                  </Box>
                                </Box>
                                <Box bg={"gray.200"} w={"100%"}>
                                  <Box
                                    fontSize={"17px"}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    ml={{ lg: "0", base: "5" }}
                                    fontFamily={"system-ui, sans-serif"}
                                  ></Box>
                                </Box>
                                <Box bg={"gray.200"} w={"100%"} h={"4.25vh"}>
                                  <Box
                                    fontSize={"sm"}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    fontFamily={"system-ui, sans-serif"}
                                    ml={{ lg: "0", base: "5" }}
                                    mb={{ lg: "10", base: "0" }}
                                  >
                                    Aquí podras subir y ver tus videos para
                                    compartir con tu cliente.
                                  </Box>
                                </Box>
                              </Flex>
                            </Box>
                            <Box bg={"gray.200"} w={"30%"} h={"12.5vh"}>
                              {/* <Flex direction={'column'} justifyContent={'end'}>
                                                                <Box bg={'gray.200'} w={'100%'} h={'4.16vh'} align={'end'} mt={8} pr={{base:10,lg:0}}>
                                                                <Tooltip label="Subir nuevo video" aria-label='A tooltip' placement='right' borderRadius={'sm'}>
                                                                <Button colorScheme={'orange'} onClick={onOpen}>
                                                                        <AddIcon  /> 
                                                                    </Button>
                                                                </Tooltip>
                                                                </Box>
                                                                <Box bg={'grya.100'} w={'100%'} h={'4.16vh'}></Box>
                                                                <Box bg={'grya.100'} w={'100%'} h={'4.16vh'}></Box>
                                                            </Flex> */}
                            </Box>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bgGradient="linear(to-r, gray.200, gray.50)"
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
                <Box
                  bg={"white"}
                  h={{ base: "65vh", lg: "65vh" }}
                  pl={{ base: 0, lg: 0 }}
                  pr={{ base: 0, lg: 0 }}
                >
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"white"}
                      w={{ base: "0", lg: "90%" }}
                      h={{ base: "0", lg: "65vh" }}
                    >
                      {datos ? (
                        <Box bg={"white"} w={"100%"} mt={5}>
                          <Center>
                            <Flex direction={"column"} justify={"center"}>
                              <Box
                                color={"gray.500"}
                                alignSelf={"center"}
                                fontWeight={"bold"}
                                my={2}
                              >
                                Sube un video
                              </Box>
                              <Box
                                p={5}
                                border={"1px"}
                                borderColor={"gray.100"}
                                rounded={"15px"}
                                mx={2}
                              >
                                <img src={qr} alt="" />
                              </Box>
                              <Box textAlign={"center"} fontSize={"21px"}>
                                o
                              </Box>
                              <Button
                                bg={"orange"}
                                color={"gray.900"}
                                onClick={camaraDesk}
                              >
                                Comenzar a grabar
                              </Button>
                            </Flex>
                            <Box
                              borderWidth={1}
                              borderRadius={"12px"}
                              zIndex={1}
                              py={3}
                            >
                              <Table
                                variant="simple"
                                size={"lg"}
                                fontFamily={"system-ui, sans-serif"}
                                p={10}
                              >
                                <Thead>
                                  <Tr>
                                    <Th
                                      fontSize={"sm"}
                                      fontWeight={"medium"}
                                      fontFamily={"system-ui, sans-serif"}
                                    >
                                      Nombre del video
                                    </Th>
                                    <Th
                                      fontSize={"sm"}
                                      fontWeight={"medium"}
                                      fontFamily={"system-ui, sans-serif"}
                                    >
                                      Estatus
                                    </Th>
                                    <Th
                                      fontSize={"sm"}
                                      fontWeight={"medium"}
                                      fontFamily={"system-ui, sans-serif"}
                                    >
                                      Fecha de Creación
                                    </Th>
                                    <Th
                                      fontSize={"sm"}
                                      fontWeight={"medium"}
                                      fontFamily={"system-ui, sans-serif"}
                                    ></Th>
                                  </Tr>
                                </Thead>
                                {datos
                                  .sort(
                                    (a, b) => b.fechaCreacion - a.fechaCreacion,
                                  )
                                  .map((p, index) => {
                                    const url = p.url;
                                    return (
                                      <Tbody size={"sm"} key={index}>
                                        <Tr key={p.id}>
                                          <Td
                                            fontSize={"sm"}
                                            fontWeight={"medium"}
                                            fontFamily={"system-ui, sans-serif"}
                                            color={"gray.700"}
                                          >
                                            <Link href={url} target={"_blank"}>
                                              <Flex>
                                                <Box
                                                  fontSize={"21px"}
                                                  fontWeight={"medium"}
                                                  color={"gray.700"}
                                                  mr={"3"}
                                                >
                                                  <BiSlideshow />
                                                </Box>
                                                {p.nombreVideo}
                                              </Flex>
                                            </Link>
                                          </Td>
                                          <Td
                                            fontSize={"15px"}
                                            color={"gray.600"}
                                            fontWeight={"normal"}
                                          >
                                            <Tag colorScheme={"green"}>
                                              {p.status}
                                            </Tag>
                                          </Td>
                                          <Td
                                            fontSize={"15px"}
                                            color={"gray.600"}
                                            fontWeight={"normal"}
                                          >
                                            <Moment
                                              tz="America/Mexico_City"
                                              locale="ES"
                                              format="DD/MM/yyyy hh:mm:ss"
                                            >
                                              {p.fechaCreacion}
                                            </Moment>
                                          </Td>
                                          <Td
                                            fontSize={"15px"}
                                            color={"gray.600"}
                                            fontWeight={"normal"}
                                          >
                                            <Button
                                              onClick={() => deleteData(p.id)}
                                            >
                                              Eliminar
                                            </Button>
                                          </Td>
                                          <Td
                                            fontSize={"15px"}
                                            color={"gray.600"}
                                            fontWeight={"normal"}
                                          >
                                            <Box
                                              overflow={"hidden"}
                                              rounded={"lg"}
                                              w={"200px"}
                                            >
                                              <video
                                                controls
                                                preload="auto"
                                                width="40%"
                                                height="40%"
                                              >
                                                <source src={url} controls />
                                              </video>
                                            </Box>
                                          </Td>
                                        </Tr>
                                      </Tbody>
                                    );
                                  })}
                              </Table>
                            </Box>
                          </Center>
                        </Box>
                      ) : (
                        "Aún no cuentas con videos"
                      )}
                    </Box>
                    {/* Tabla para movil*/}
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Media;
