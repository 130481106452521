import { Box } from "@chakra-ui/layout";
import React from "react";
import GerenteProyectos from "../../components/Gerente/Proyectos";
const Vendedores = () => {
  return (
    <Box bg={"gray.50"} h={"100vh"}>
      <GerenteProyectos />
    </Box>
  );
};

export default Vendedores;
