import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Text,
  Box,
  Center,
  Flex,
  Link,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
} from "@chakra-ui/react";

import MenuAdmin from "./MenuAdmin";
import Header from "../../Header";
import { HiDotsHorizontal } from "react-icons/hi";
import useUser from "../../context/useUser";
import "moment-timezone";
import { connect } from "react-redux";
import { get_link, create_link, delete_link } from "../../../actions";
import { Table } from "antd";
import axios from "axios";
const _Componentes = (props) => {
  const { user } = useUser();
  const [linkInput, setLinkInput] = useState();
  const [clientes, setClientes] = useState(false);
  const { link, crm } = props || {};
  const { Column } = Table;
  const [urlCassandra] = useState(process.env.REACT_APP_URL_CASSANDRA);
  const [tokenCasandra] = useState(process.env.REACT_APP_TOKEN_CASSANDRA);
  const [datos, setDatos] = useState([]);

  const checkCollectionExists = async () => {
    //const url = `${urlCassandra}collections/crm`
    const url =
      "https://servicesemail-production.herokuapp.com/api/clientes_crm/";
    const response = await fetch(url, {
      headers: {
        accept: "application/json",
        //'X-Cassandra-Token': tokenCasandra
      },
    });

    return response.ok;
  };
  async function fetchConfiguracion() {
    //console.log("refetch")
    //const urlC = `${urlCassandra}collections/attachmens${user.id.toString()}?page-size=20`
    //const urlC = `${urlCassandra}collections/crm`
    const urlC =
      "https://servicesemail-production.herokuapp.com/api/clientes_crm/";
    const result = await axios(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        //'X-Cassandra-Token': tokenCasandra
      },
    });

    //const configuracion =  await Object.keys(ventasData.data.data).map((item) => ({id: item, ...ventasData.data.data[item]}))
    setDatos(result.data.undefined);
    console.log(result.data.undefined);
    return result.data.undefined;
  }

  const initialize = async () => {
    const collectionExists = await checkCollectionExists();
    if (!collectionExists) {
      await createCollection();
    }
  };
  const createCollection = async (a) => {
    //const url = `${urlCassandra}collections/crm`
    const url = "https://servicesemail-production.herokuapp.com/api/configcrm";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        // 'X-Cassandra-Token': tokenCasandra
      },
      body: JSON.stringify({
        local: false,
      }),
    });
    return response;
  };
  const configClientes = async (local, id) => {
    //const url = `${urlCassandra}collections/crm/${id}`
    const url = `https://servicesemail-production.herokuapp.com/api/updateConfigCrm/${id}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        //'X-Cassandra-Token': tokenCasandra
      },
      body: JSON.stringify({
        local: local,
      }),
    });
    return response;
  };

  const configClientesFirst = async (local) => {
    //const url = `${urlCassandra}collections/crm`
    const url = "https://servicesemail-production.herokuapp.com/api/configcrm";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        //'X-Cassandra-Token': tokenCasandra
      },
      body: JSON.stringify({
        local: local,
      }),
    });
    return response;
  };

  useEffect(() => {
    initialize();
    fetchConfiguracion();
    props.get_link();
  }, []);

  function actualizarCambio(id) {
    //mutation_b.mutate(id)
    props.create_link(linkInput);
    setTimeout(() => {
      props.get_link();
    }, 400);
  }

  const deleteLink = (id) => {
    props.delete_link(id);
    setTimeout(() => {
      props.get_link();
    }, 400);
  };
  const handlerCheckbox = (id) => {
    setClientes(!datos.local);

    configClientes(!datos.local, id);
    setTimeout(() => {
      fetchConfiguracion();
    }, 500);
  };
  const handlerCheckboxFirst = (id) => {
    setClientes(!clientes);

    configClientesFirst(!clientes);
    setTimeout(() => {
      fetchConfiguracion();
    }, 500);
  };

  return (
    <>
      {/* ModalLinea */}
      <Flex direction={{ base: "column", lg: "row" }} bg={"pink"} h={"100vh"}>
        <MenuAdmin />
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>

            <Box bg={"gray.200"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
              <Flex direction={"column"}>
                <Box bg={"white"} h={{ base: "25vh", lg: "25vh" }}>
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"pink.400"}
                      w={{ base: "100%", lg: "60%" }}
                      h={{ base: "25vh", lg: "25vh" }}
                    >
                      <Flex direction={"column"}>
                        <Box bg={"gray.300"} h={"12.5vh"}>
                          <Flex direction={"row"}>
                            <Box bg={"gray.500"} w={"70%"} h={"12.5vh"}>
                              <Flex direction={"column"}>
                                <Box bg={"white"} w={"100%"} h={"8.25vh"}>
                                  <Box
                                    alignSelf={"end"}
                                    fontSize={{
                                      lg: "17px",
                                      base: "23px",
                                      "2xl": "33px",
                                    }}
                                    fontWeight={"semibold"}
                                    color={"gray.700"}
                                    fontFamily={"Roboto"}
                                    ml={{ lg: "0", base: "5" }}
                                    mt={{ lg: "8", base: "5" }}
                                  >
                                    Hola! {user.username}{" "}
                                  </Box>
                                </Box>
                                <Box bg={"white"} w={"100%"} h={"4.25vh"}>
                                  <Box
                                    fontSize={{
                                      lg: "13px",
                                      base: "15px",
                                      "2xl": "15px",
                                    }}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    ml={{ lg: "0", base: "5" }}
                                    mb={{ lg: "10", base: "0" }}
                                  >
                                    <Link href="#"> Configuración </Link>
                                  </Box>
                                </Box>
                              </Flex>
                            </Box>
                            <Box bg={"white"} w={"30%"} h={"12.5vh"}>
                              <Flex direction={"column"} justifyContent={"end"}>
                                <Box
                                  bg={"white"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                  align={"end"}
                                  mt={8}
                                  pr={{ base: 10, lg: 0 }}
                                ></Box>
                                <Box bg={"white"} w={"100%"} h={"4.16vh"}></Box>
                                <Box bg={"white"} w={"100%"} h={"4.16vh"}></Box>
                              </Flex>
                            </Box>
                          </Flex>
                        </Box>
                        <Box bg={"white"} h={"12.5vh"}>
                          <Center></Center>
                        </Box>
                        <Divider />
                      </Flex>
                    </Box>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
                <Box
                  bg={"white"}
                  h={{ base: "65vh", lg: "65vh" }}
                  pl={{ base: 0, lg: 0 }}
                  pr={{ base: 0, lg: 0 }}
                >
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"white"}
                      w={{ base: "0", lg: "90%" }}
                      h={{ base: "0", lg: "65vh" }}
                    >
                      <Box w={"100%"}>
                        <Center>
                          <Flex w={"50%"}>
                            <Flex
                              direction={"column"}
                              bg={"gray.50"}
                              my={3}
                              py={5}
                              px={5}
                              w={"100%"}
                              mx={5}
                              rounded={"lg"}
                            >
                              <Box>
                                <Flex justify={"space-between"}>
                                  <Text
                                    alignSelf={"center"}
                                    fontFamily={"Roboto"}
                                    fontWeight={"medium"}
                                    fontSize={"15pt"}
                                  >
                                    Configuración de link
                                  </Text>
                                  <Box justifyContent={"center"} ml={3}></Box>
                                </Flex>
                                <Box
                                  fontFamily={"Roboto"}
                                  fontSize={"14px"}
                                  fontWeight={"normal"}
                                  color={"gray.500"}
                                >
                                  Configura en link para el uso de las
                                  oportunidades.
                                </Box>
                              </Box>
                              <>
                                <Box>
                                  <Box
                                    fontSize={"11pt"}
                                    fontWeight={"medium"}
                                    fontFamily={"system-ui, sans-serif"}
                                    my={3}
                                  >
                                    Ingresa el link
                                  </Box>
                                  <Input
                                    fontSize={"11pt"}
                                    fontWeight={"normal"}
                                    fontFamily={"system-ui, sans-serif"}
                                    type={"text"}
                                    placeholder={"https://crm/parametros"}
                                    bg={"white"}
                                    onChange={(e) =>
                                      setLinkInput(e.target.value)
                                    }
                                  ></Input>
                                </Box>

                                {link.length == 0 ? (
                                  <Button
                                    my={2}
                                    colorScheme={"orange"}
                                    onClick={() => actualizarCambio()}
                                    w={"20%"}
                                  >
                                    Guardar
                                  </Button>
                                ) : (
                                  <Button my={2} colorScheme={"gray"} w={"20%"}>
                                    Guardar
                                  </Button>
                                )}
                              </>
                            </Flex>
                          </Flex>
                        </Center>
                      </Box>
                      <Center>
                        <Box w={"50%"}>
                          <Table
                            dataSource={link}
                            bordered
                            size="sm"
                            pagination={{
                              pageSize: 5,
                            }}
                          >
                            <Column
                              title="Link"
                              dataIndex="link"
                              key="link"
                              render={(text, record, index) => {
                                return <Box key={index}>{record.link}</Box>;
                              }}
                            />
                            <Column
                              title="Acciones"
                              dataIndex={"acciones"}
                              key={"acciones"}
                              render={(text, record, index) => {
                                return (
                                  <Menu>
                                    <MenuButton
                                      px={4}
                                      py={2}
                                      transition="all 0.2s"
                                      borderRadius="md"
                                      borderWidth="1px"
                                      bgGradient="linear(to-l, #e8e8e4,#d8e2dc )"
                                      _hover={{
                                        bg: "gray.400",
                                        color: "white",
                                      }}
                                      _expanded={{
                                        bg: "gray.500",
                                        color: "white",
                                      }}
                                      _focus={{ boxShadow: "outline" }}
                                      as={Button}
                                    >
                                      <HiDotsHorizontal />
                                    </MenuButton>
                                    <MenuList>
                                      <MenuItem
                                        onClick={() => deleteLink(record.id)}
                                      >
                                        Eliminar
                                      </MenuItem>
                                    </MenuList>
                                  </Menu>
                                );
                              }}
                            />
                          </Table>
                        </Box>
                      </Center>
                      <Center>
                        <Flex direction="column" mt={10}>
                          <Box fontWeight={"bold"}>
                            Configuración de clientes
                          </Box>
                          <Box>
                            <>
                              {datos ? (
                                <>
                                  Deseas utilizar los clientes del CRM?
                                  <Checkbox
                                    w={8}
                                    isChecked={datos.local}
                                    onChange={() => handlerCheckbox(datos.id)}
                                    ml={2}
                                    alignSelf={"center"}
                                    mt={1}
                                  />
                                </>
                              ) : (
                                <>
                                  Deseas utilizar los clientes del CRM?
                                  <Checkbox
                                    w={8}
                                    isChecked={clientes}
                                    onChange={() => handlerCheckboxFirst()}
                                    ml={2}
                                    alignSelf={"center"}
                                    mt={1}
                                  />
                                </>
                              )}
                            </>
                          </Box>
                        </Flex>
                      </Center>
                    </Box>
                    {/* Tabla para movil*/}
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

//REDUX
const mapStateProps = (state) => ({
  link: state.linkReducer.link,
});

const Componentes = connect(mapStateProps, {
  create_link,
  get_link,
  delete_link,
})(_Componentes);
export default Componentes;
