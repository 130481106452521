import { Box } from '@chakra-ui/layout';
import React from 'react';
import Header from '../../components/Header';
import NewComponent from '../../components/Vendedor/Clientes/New';
const New = () => {
    return (
        <Box bg={'gray.50'} h={'100vh'}>
            <Header/>
            <NewComponent />
        </Box>
    );
};

export default New;