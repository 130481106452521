import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import {
  Spinner,
  Button,
  Box,
  Center,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Progress,
  Flex,
  Image,
} from "@chakra-ui/react";
import useToken from "../../useToken";
import useUser from "../../context/useUser";
import { connect } from "react-redux";
import {
  update_clientes_batch,
  load_clientes,
  ready_clientes,
  clean_clientes,
  clean_mensaje_clientes,
} from "../../../actions/index";
import { nanoid } from "nanoid";
import SuccessImg from "../../../assets/success.gif";
function _ExcelReader(props) {
  const [excelData, setExcelData] = useState([]);
  const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI);
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  const { mensaje_clientes, mensaje_load, finish } = props || {};
  //   const handleFileUpload = (event) => {
  //     const file = event.target.files[0];
  //     const reader = new FileReader();

  //     reader.onload = (event) => {
  //       const data = new Uint8Array(event.target.result);
  //       const workbook = XLSX.read(data, { type: 'array' });
  //       const sheetName = workbook.SheetNames[0];
  //       const sheet = workbook.Sheets[sheetName];
  //       const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 0 });
  //       setExcelData(jsonData);
  //     };

  //     reader.readAsArrayBuffer(file);
  //   };
  //   const headerKeys = Object.keys(Object.assign({}, ...excelData));
  //   const jsonData = {
  //     header: headerKeys,
  //     data: excelData,
  //   };
  //   const batchData = excelData.slice(1).map((item) => {
  //     return {
  //       method: 'POST',
  //       path: '/mi-endpoint',
  //       body: JSON.stringify(item)
  //     }
  //   });
  const handleFileUpload = (event) => {
    props.load_clientes();

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 0 });
      setExcelData(jsonData);
      props.ready_clientes(jsonData.length);
      // Call function that depends on excelData
    };

    reader.readAsArrayBuffer(file);
  };

  const handleClickProcessData = (event) => {
    props.clean_clientes();
    if (excelData) {
      processData(excelData);
    } else {
      console.log("No hay datos para procesar");
    }
  };

  const headerKeys = Object.keys(Object.assign({}, ...excelData));

  const processData = async (data) => {
    const promises = data.map(async (item) => {
      const a = "@";
      const str = item.Nombre ? item.Nombre.toLowerCase() : "";
      const r = str.replace(/\s/g, "");
      const username = a + r;
      //urlStrapi, token, razonSocial, nombre, direccion, estado,pais, nombreContacto, correo, telefono, vendedor, nanoid, username, cardid
      //props.update_clientes_batch(urlStrapi, token, item.RazonSocial, str,item.Direccion,item.Estado, item.Pais, item.NombreContacto,item.Correo,item.Telefono,user.id.toString(),nanoid(),username,item.CARDID.toString())

      props.update_clientes_batch(
        urlStrapi, //1
        token, //2
        str, //nombre 3
        item.RazonSocial, //nombreComercial 4
        item.Ciudad, // 5
        item.Estado, // 6
        item.Pais, //7
        item.Calle, // 8
        item.Numero, //9
        item.Colonia, //10
        item.Codigo, //11
        item.Tipo, //12
        user.id.toString(), //13
        nanoid(), //14
        username, //15
        item.CARDID.toString(), //16
      );

      // console.log("[DATA EXCEL]", urlStrapi, token,
      //   item.CARDID.toString(),
      //   str, //nombre
      //   item.RazonSocial, //nombreComercial
      //   item.Ciudad,
      //   item.Estado,
      //   item.Pais,
      //   item.Calle,
      //   item.Numero,
      //   item.Colonia,
      //   item.Codigo,
      //   item.Tipo,
      //   user.id.toString(),
      //   nanoid(),
      //   username)

      //   const response = await fetch(urlStrapi + '/clientes', {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: `Bearer ${token}`,
      //     },
      //     body: JSON.stringify({
      //       razonSocial: item.RazonSocial ? item.RazonSocial.toLowerCase() : '',
      //       nombre: str,
      //       direccion: item.Direccion,
      //       estado: item.Estado,
      //       pais: item.Pais,
      //       nombreContacto: item.NombreContacto,
      //       correo: item.Correo,
      //        telefono: item.Telefono,
      //        vendedor: user.id.toString(),
      //        token: nanoid(),
      //        username,
      //        estatus: 'Nuevo',
      //       fechaCreacion: new Date(),
      //        CARDID: item.CARDID.toString(),
      //     }),
      //   });

      //   return response;
    });

    await Promise.all(promises);

    setExcelData([]);

    // Do something after all data has been sent, if necessary
  };

  useEffect(() => {
    props.clean_clientes();
  }, []);
  console.log("[MENSAJE]", mensaje_clientes);

  return (
    <Box>
      <Box mb={5} textAlign={"center"}>
        {mensaje_load == null ? (
          ""
        ) : (
          <Flex direction={"column"}>
            <Box>{mensaje_load}</Box>
            <Progress size="xs" isIndeterminate />
          </Flex>
        )}
      </Box>
      <Box mb={5} textAlign={"center"}>
        {mensaje_clientes == null ? (
          ""
        ) : (
          <Flex direction={"column"}>
            <Box>{mensaje_clientes}</Box>
            {finish == true ? (
              <Center>
                <Image src={SuccessImg} w="400px" h={"400px"} />
              </Center>
            ) : (
              <Progress size="xs" isIndeterminate />
            )}
          </Flex>
        )}
      </Box>

      {excelData.length == 0 ? (
        <Center>
          {finish == true ? (
            ""
          ) : (
            <input type="file" onChange={handleFileUpload} />
          )}
        </Center>
      ) : (
        <Center>
          {mensaje_clientes == null ? (
            <Button
              onClick={handleClickProcessData}
              bg={"orange.500"}
              color={"white"}
              mb={10}
            >
              Subir clientes
            </Button>
          ) : (
            <Button
              isLoading={true}
              isDisabled={true}
              bg={"orange.500"}
              color={"white"}
              mb={10}
            >
              Subir clientes
            </Button>
          )}
        </Center>
      )}
      <Table variant="simple" colorScheme={"gray"} size={"md"} p={10}>
        <Thead>
          <Tr key={"header"}>
            {headerKeys.map((key) => (
              <Th>{key} </Th>
            ))}
          </Tr>
        </Thead>
        <>
          {excelData ? (
            <>
              <Tbody size={"sm"}>
                {excelData.map((item) => {
                  return (
                    <Tr key={item.id}>
                      {Object.values(item).map((val) => (
                        <Td>{val}</Td>
                      ))}
                    </Tr>
                  );
                })}
              </Tbody>
            </>
          ) : (
            <Box
              fontSize={15}
              px={5}
              py={5}
              color={"gray.500"}
              fontWeight={"medium"}
            >
              No hay proyectos
            </Box>
          )}
        </>
      </Table>
    </Box>
  );
}

//REDUX
const mapStateProps = (state) => ({
  mensaje_clientes: state.clientesReducer.mensaje_clientes,
  finish: state.clientesReducer.finish,
  mensaje_load: state.loadClientesReducer.mensaje_load,
});

const ExcelReader = connect(mapStateProps, {
  update_clientes_batch,
  load_clientes,
  ready_clientes,
  clean_clientes,
  clean_mensaje_clientes,
})(_ExcelReader);
export default ExcelReader;
