import React, { useContext, useState } from "react";
import {
  Spinner,
  Input,
  VStack,
  Toast,
  SimpleGrid,
  LinkOverlay,
  HStack,
  Image,
  Stack,
  useToast,
  Button,
  Text,
  Heading,
  Container,
  Box,
  Center,
  Collapse,
  Fade,
  ScaleFade,
  Slide,
  SlideFade,
  useDisclosure,
  Flex,
  Spacer,
  Link,
  LinkBox,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Avatar,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Tooltip,
} from "@chakra-ui/react";
import Header from "../../components/Header";
import { PhoneIcon, AddIcon, WarningIcon } from "@chakra-ui/icons";
import Pattern1 from "../../assets/cover1.png";
import Pattern2 from "../../assets/cover2.png";
import Pattern3 from "../../assets/patterns3.svg";
import useUser from "../context/useUser";
import useToken from "../useToken";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import MenuAdmin from "./Utilidades/MenuAdmin";
import { Hidden } from "@material-ui/core";

const Home = () => {
  const queryClient = useQueryClient();
  const [datos, setDatos] = useState([]);
  const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI);
  const { token, setToken } = useToken();
  const [clientes, setClientes] = useState([]);
  const { user, setUser } = useUser();
  const fuente =
    "Montserrat";
  async function fetchClientes() {
    const clientes = fetch(urlStrapi + "/users?type=2").then((response) =>
      response.json(),
    );
    return clientes;
  }

  async function fetchCliente(b) {
    const urlC = `${urlStrapi}/users?username_contains=${b}`;
    const ventasData = await axios.get(urlC, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const dat = ventasData;
    setDatos(dat.data);
    // console.log(dat)
    if (b === "") {
      refetch();
    }
    return dat;
  }
  const { isLoading, isFetching, data, refetch } = useQuery(
    "CLIENTES",
    fetchClientes,
    {
      onSuccess: (data) => {
        console.log("data:", data);
        setDatos(data);
      },
      cacheTime: Infinity,
    },
  );
  if (isLoading) {
    return (
      <Center mt={"10%"}>
        <Spinner color={"green.700"} />
      </Center>
    );
  }
  const addCliente = () => {
    window.location.href = "/vendedor/clientes/new";
  };
  function buscar(e) {
    fetchCliente(e);
  }
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      fetchCliente(event.target.value);
    }
  };

  return (
    <>
      <Flex
        direction={{ base: "column", lg: "row" }}
        bg={"gray.50"}
        h={"100vh"}
      >
        <MenuAdmin />
        <Box
          bg={"gray.50"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>

            <Center mt={{ base: "15", "2xl": 28, lg: 16 }}>
              <Flex direction={"column"}>
                <Box bg={"white"} rounded={"15"} mb={2} shadow={"sm"}>
                  <Box
                    my={"10"}
                    fontSize={"2.0rem"}
                    fontFamily={fuente}
                    fontWeight={"bold"}
                    color={"gray.600"}
                    pl={5}
                  >
                    ¡Dashboard!
                  </Box>
                </Box>

                <VStack>
                  <Center>
                    <SimpleGrid
                      columns={{ base: 1, lg: 3, "2xl": 3 }}
                      spacing={4}
                      w={"full"}
                    >
                      <LinkBox
                        shadow={"sm"}
                        bg={"white"}
                        borderWidth={"1px"}
                        w={"400px"}
                        h={"250px"}
                        borderRadius={"lg"}
                        _hover={{ bg: "gray.100" }}
                        overflow={"hidden"}
                      >
                        <LinkOverlay href="/admin/vendedores">
                          <Box
                            h={"50%"}
                            w={"100%"}
                            bgGradient="linear(to-r, #ace0f9,#ffffff )"
                          ></Box>
                          <Text
                            ml={3}
                            mt={6}
                            fontWeight={"bold"}
                            fontSize={"24px"}
                            fontFamily={fuente}
                            color={"gray.700"}
                          >
                            Vendedores
                          </Text>
                          <Text
                            ml={3}
                            mt={0}
                            fontWeight={"regular"}
                            fontSize={"16px"}
                            fontFamily={fuente}
                            color={"gray.500"}
                          >
                            Agrega y Administra a tus vendedores.{" "}
                          </Text>
                        </LinkOverlay>
                      </LinkBox>

                      <LinkBox
                        shadow={"sm"}
                        bg={"white"}
                        borderWidth={"1px"}
                        w={"100%"}
                        h={"250px"}
                        borderRadius={"lg"}
                        _hover={{ bg: "gray.100" }}
                        overflow={"hidden"}
                      >
                        <LinkOverlay href="/admin/gerentes/lista">
                          <Box
                            h={"50%"}
                            w={"100%"}
                            bgGradient="linear(to-r, #fff1eb, #ace0f9)"
                          ></Box>
                          <Text
                            ml={3}
                            mt={6}
                            fontWeight={"bold"}
                            fontSize={"24px"}
                            fontFamily={fuente}
                            color={"gray.700"}
                          >
                            Gerentes
                          </Text>
                          <Text
                            ml={3}
                            mt={1}
                            fontWeight={"regular"}
                            fontSize={"16px"}
                            fontFamily={fuente}
                            color={"gray.500"}
                          >
                            Agrega y Administra a tus gerentes.
                          </Text>
                        </LinkOverlay>
                      </LinkBox>

                      <LinkBox
                        shadow={"sm"}
                        bg={"white"}
                        borderWidth={"1px"}
                        w={"100%"}
                        h={"250px"}
                        borderRadius={"lg"}
                        _hover={{ bg: "gray.100" }}
                        overflow={"hidden"}
                      >
                        <LinkOverlay href="/admin/catalogos">
                          <Box
                            h={"50%"}
                            w={"100%"}
                            bgGradient="linear(to-r, #e9defa, #fbfcdb)"
                          ></Box>
                          <Text
                            ml={3}
                            mt={6}
                            fontWeight={"bold"}
                            fontSize={"24px"}
                            fontFamily={fuente}
                            color={"gray.700"}
                          >
                            Catálogos
                          </Text>
                          <Text
                            ml={3}
                            mt={1}
                            fontWeight={"regular"}
                            fontSize={"16px"}
                            fontFamily={fuente}
                            color={"gray.500"}
                          >
                            Administra tus catálogos
                          </Text>
                        </LinkOverlay>
                      </LinkBox>

                      <LinkBox
                        shadow={"sm"}
                        bg={"white"}
                        borderWidth={"1px"}
                        w={"100%"}
                        h={"250px"}
                        borderRadius={"lg"}
                        _hover={{ bg: "gray.100" }}
                        overflow={"hidden"}
                      >
                        <LinkOverlay href="/admin/clientes">
                          <Box
                            h={"50%"}
                            w={"100%"}
                            bgGradient="linear(to-r, #e9defa, #fbfcdb)"
                          ></Box>
                          <Text
                            ml={3}
                            mt={6}
                            fontWeight={"bold"}
                            fontSize={"24px"}
                            fontFamily={fuente}
                            color={"gray.700"}
                          >
                            Clientes
                          </Text>
                          <Text
                            ml={3}
                            mt={1}
                            fontWeight={"regular"}
                            fontSize={"16px"}
                            fontFamily={fuente}
                            color={"gray.500"}
                          >
                            Importar Clientes
                          </Text>
                        </LinkOverlay>
                      </LinkBox>
                      <LinkBox
                        shadow={"sm"}
                        bg={"white"}
                        borderWidth={"1px"}
                        w={"100%"}
                        h={"250px"}
                        borderRadius={"lg"}
                        _hover={{ bg: "gray.100" }}
                        overflow={"hidden"}
                      >
                        <LinkOverlay href="/admin/componentes">
                          <Box
                            h={"50%"}
                            w={"100%"}
                            bgGradient="linear(to-r, #e9defa, #fbfcdb)"
                          ></Box>
                          <Text
                            ml={3}
                            mt={6}
                            fontWeight={"bold"}
                            fontSize={"24px"}
                            fontFamily={fuente}
                            color={"gray.700"}
                          >
                            Configuración
                          </Text>
                          <Text
                            ml={3}
                            mt={1}
                            fontWeight={"regular"}
                            fontSize={"16px"}
                            fontFamily={fuente}
                            color={"gray.500"}
                          >
                            Configura tu link para CRM
                          </Text>
                        </LinkOverlay>
                      </LinkBox>
                    </SimpleGrid>
                  </Center>
                </VStack>
              </Flex>
            </Center>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Home;
