import React from "react";
import ClienteDetalles from "../../components/Vendedor/Clientes/Detalles";

const Detalles = () => {
  return (
    <div>
      <ClienteDetalles />
    </div>
  );
};

export default Detalles;
