import React, {  useState } from "react";
import {
  Spinner,
  Stack,
  useToast,
  Button,
  Box,
  Center,
  useDisclosure,
  Flex,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Input,
  Table,
} from "@chakra-ui/react";
import Header from "../../Header";
import useUser from "../../context/useUser";
import useToken from "../../useToken";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import MenuVendedor from "../Utilidades/MenuAdmin";
import { nanoid } from "nanoid";
import "antd/dist/antd.css";
import { RenderIf } from "../../../hooks/renderIF";
import * as XLSX from "xlsx";
import Blur from "react-css-blur";
import Excel from "./excel";
const Clientes = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [datos, setDatos] = useState([]);
  const [razonSocial, setRazonSocial] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [direccion, setDireccion] = React.useState("");
  const [direccionEstado, setDireccionEstado] = React.useState("");
  const [direccionPais, setDireccionPais] = React.useState("");
  const [nombreContacto, setNombreContacto] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [telefono, setTelefono] = React.useState("");
  const [cardId, setCardId] = React.useState("");
  const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI);
  const { token, setToken } = useToken();
  const [clientes, setClientes] = useState([]);
  const { user, setUser } = useUser();
  const [cargando, setCargando] = useState(false);
  const { Column, ColumnGroup } = Table;
  const [sortedInfo, setSortedInfo] = useState({});
  const [fuente] = useState(
    "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
  );
  const [blurOn, setBlurOn] = useState(false);

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const fileReader = new FileReader();
  const [array, setArray] = useState([]);

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    setArray(array);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  const headerKeys = Object.keys(Object.assign({}, ...array));

  async function fetchClientes() {
    const clientes = fetch(
      urlStrapi + "/clientes?vendedor=" + user.id.toString(),
    ).then((response) => response.json());
    return clientes;
  }
  async function fetchCliente(b) {
    //const urlC = `${urlStrapi}/clientes?nombre_contains=${b}&vendedor=${user.id.toString()}`
    const urlC = `${urlStrapi}/clientes`;
    const ventasData = await axios.get(urlC, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const dat = ventasData;
    setDatos(dat.data);
    // console.log(dat)
    if (b === "") {
      refetch();
    }
    return dat;
  }
  const eliminar = async (proyectId) => {
    const urlC = urlStrapi + "/clientes/" + proyectId;
    const response = await fetch(urlC, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);

    return response;
  };
  const crear = async () => {
    setCargando(true);
    const a = "@";
    const str = nombre.toLowerCase();
    const r = str.replace(/\s/g, "");
    const username = a + r;

    const urlC = urlStrapi + "/clientes";
    const response = await fetch(urlC, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        razonSocial: razonSocial.toLowerCase(),
        nombre: nombre.toLowerCase(),
        direccion: direccion.toLowerCase(),
        estado: direccionEstado.toLowerCase(),
        pais: direccionPais.toLowerCase(),
        nombreContacto: nombreContacto.toLowerCase(),
        correo: correo.toLowerCase(),
        telefono: telefono.toLowerCase(),
        vendedor: user.id.toString(),
        token: nanoid(),
        username,
        estatus: "Nuevo",
        fechaCreacion: new Date(),
        CARDID: cardId,
      }),
    });
    return response;
  };
  const mutation_a = useMutation(eliminar, {
    onSuccess: () => {
      console.log("Eliminado");
      queryClient.invalidateQueries("CLIENTES");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation = useMutation(crear, {
    onSuccess: () => {
      queryClient.invalidateQueries("CLIENTES");
      setCargando(false);
      onClose();
      setRazonSocial("");
      setNombre("");
      setDireccion("");
      setDireccionEstado("");
      setDireccionPais("");
      setNombreContacto("");
      setCorreo("");
      setTelefono("");

      //window.location.href = '/vendedor/clientes'
      //console.log('success')
    },
    onError: (error) => {
      console.log(error);
    },
  });
  function handleonclick() {
    {
      (nombre && razonSocial && direccion) ||
      nombreContacto ||
      correo ||
      telefono
        ? mutation.mutate()
        : toas();
    }
  }
  function handleonclickDelete(id) {
    mutation_a.mutate(id);
  }
  const { isLoading, isFetching, data, refetch } = useQuery(
    "CLIENTES",
    fetchClientes,
    {
      onSuccess: (data) => {
        //console.log("data:", data)
        setDatos(data);
      },
      cacheTime: Infinity,
    },
  );
  if (isLoading) {
    return (
      <Center mt={"10%"}>
        <Spinner color={"green.700"} />
      </Center>
    );
  }
  const setAgeSort = () => {
    setSortedInfo({
      order: "descend",
      columnKey: "id",
    });
  };

  const addCliente = () => {
    window.location.href = "/vendedor/clientes/new";
  };

  function buscar(e) {
    fetchCliente(e);
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      fetchCliente(event.target.value);
    }
  };

  const toas = () => {
    toast({
      title: "Error.",
      description: "Error, verifica la información y vuelve a intentar",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };
  const onChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      console.log(data);
    };
    reader.readAsBinaryString(file);
  };
  const BlurOn = (blurOn) => {
    setBlurOn(blurOn);
  };
  const handlerClickModal = () => {
    onOpen();
    BlurOn(true);
  };
  const handlerClickCloseModal = () => {
    onClose();
    BlurOn(false);
  };
  return (
    <>
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={handlerClickCloseModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontFamily={fuente}
            fontSize={"22px"}
            fontWeight={"medium"}
          >
            Nuevo Cliente
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack spacing={4}>
              <InputGroup size="md">
                <InputLeftAddon
                  children="CARD ID"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={15}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setCardId(e.target.value)}
                  required={true}
                  placeholder="Ingresa el número de cliente"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              <InputGroup size="md">
                <InputLeftAddon
                  children="Nombre cliente"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={15}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setNombre(e.target.value)}
                  required={true}
                  placeholder="Ingresa el nombre del cliente"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              <InputGroup size="md">
                <InputLeftAddon
                  children="Razón social"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={15}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setRazonSocial(e.target.value)}
                  type={"tel"}
                  required={true}
                  placeholder="Ingresa la razón social"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              <Divider />
              <InputGroup size="md">
                <InputLeftAddon
                  children="Domicilio"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={15}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setDireccion(e.target.value)}
                  required={true}
                  placeholder="Dirección completa"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              <InputGroup size="md">
                <InputLeftAddon
                  children="Estado"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={15}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setDireccionEstado(e.target.value)}
                  required={true}
                  placeholder="Escribe un estado"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              <InputGroup size="md">
                <InputLeftAddon
                  children="País"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={15}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setDireccionPais(e.target.value)}
                  required={true}
                  placeholder="Escribe un país"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              <InputGroup size="md">
                <InputLeftAddon
                  children="Contacto"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={15}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setNombreContacto(e.target.value)}
                  required={true}
                  placeholder="ingresa el nombre del contacto de la empresa"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              <InputGroup size="md">
                <InputLeftAddon
                  children="Teléfono"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={15}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setTelefono(e.target.value)}
                  required={true}
                  placeholder="Ingresa el teléfono del contacto"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              <InputGroup size="md">
                <InputLeftAddon
                  children="Correo"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={15}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setCorreo(e.target.value)}
                  required={true}
                  placeholder="Ingresa el correo electrónico del contacto"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={cargando}
              onClick={handleonclick}
              bg="orange.500"
              color={"white"}
              fontFamily={fuente}
              mr={3}
            >
              Crear
            </Button>
            <Button onClick={handlerClickCloseModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex direction={{ base: "column", lg: "row" }} bg={"pink"} h={"100vh"}>
        <RenderIf isTrue={user.type !== 3}>
          <MenuVendedor />
        </RenderIf>
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>
            <Blur radius={blurOn ? "1px" : "0"} transition="400ms">
              <Box bg={"white"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
                <Flex direction={"column"}>
                  <Box
                    bg={"white"}
                    h={{ base: "65vh", lg: "65vh" }}
                    pl={{ base: 0, lg: 0 }}
                    pr={{ base: 0, lg: 0 }}
                  >
                    <Flex direction={{ base: "column", lg: "row" }}>
                      <Box
                        display={{ base: "none", lg: "flex" }}
                        bg={"white"}
                        w={{ base: "0", lg: "5%" }}
                        h={{ base: "0", lg: "65vh" }}
                      ></Box>{" "}
                      {/* Box 1 */}
                      <Box
                        bg={"white"}
                        w={{ base: "0", lg: "90%" }}
                        h={{ base: "0", lg: "65vh" }}
                      >
                        <Box
                          borderWidth={1}
                          borderRadius={"12px"}
                          py={3}
                          bg={"white"}
                          w={"100%"}
                          mt={5}
                        >
                          <Center>
                            <Flex direction={"column"}>
                              <Box
                                alignSelf={"center"}
                                mt={10}
                                mb={10}
                                mr={3}
                                fontSize={{
                                  lg: "21px",
                                  base: "23px",
                                  "2xl": "33px",
                                }}
                                fontFamily={fuente}
                                fontWeight={"bold"}
                                color={"gray.700"}
                              >
                                Importación de Clientes
                              </Box>
                              <Excel />
                            </Flex>
                          </Center>
                        </Box>
                      </Box>
                      {/* Tabla para movil*/}
                      <Box
                        display={{ base: "none", lg: "flex" }}
                        bg={"white"}
                        w={{ base: "0", lg: "5%" }}
                        h={{ base: "0", lg: "65vh" }}
                      ></Box>{" "}
                      {/* Box 3 */}
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Blur>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Clientes;
