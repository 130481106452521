import * as AWS from "aws-sdk";
import axios from "axios";
import { customAlphabet } from "nanoid";
//AWS.config.update({ region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' }); //DESARROLLO
AWS.config.update({
  region: "us-east-1",
  secretAccessKey: "3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi",
  accessKeyId: "AKIARVGY33F62XNKJJK3",
}); //PRODUCCION
const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: "2022-01-01" });
const nanoid = customAlphabet("0123456789", 30);
//CONTRATOS
export const create_video = (nombre, url, tipo) => async (dispatch) => {
  const dad = nanoid().toString();
  const datos = {
    id: dad,
    nombre: nombre,
    tipo: "main",
  };
  var params = {
    TableName: "Video_Menu",
    Item: datos,
  };
  docClient.put(params, function (err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      dispatch({ type: "CREATE_VIDEO" });
    }
  });
};
export const create_video_proyecto =
  (ids, estatus, id, nombre, url, proyecto, usuario) => async (dispatch) => {
    const videosData = {
      id: ids,
      videoId: ids,
      estatus: estatus,
      fechaCreacion: Date.now(),
      proyecto: id,
      nombreVideo: nombre,
      url,
      cliente: proyecto,
      usuario: usuario,
      tipo: "presentacion",
      novisible: [],
    };
    var params = {
      TableName: "Video_Menu_Proyecto",
      Item: videosData,
    };
    docClient.put(params, function (err, data) {
      if (err) {
        console.log("Error", err);
      } else {
        dispatch({ type: "CREATE_VIDEO" });
      }
    });
  };
export const create_video_children =
  (nombre, url, tipo, padre, subpadre) => async (dispatch) => {
    if (tipo === "Hijo") {
      const datos = {
        id: nanoid().toString(),
        nombre: nombre,
        url: url,
        tipo: tipo,
        padre: padre,
        subPadre: subpadre,
      };
      var params = {
        TableName: "Video_Menu",
        Item: datos,
      };
      docClient.put(params, function (err, data) {
        if (err) {
          console.log("Error", err);
        } else {
          dispatch({ type: "CREATE_VIDEO" });
        }
      });
    }
    if (tipo === "Padre") {
      const datos = {
        id: nanoid().toString(),
        nombre: nombre,
        url: url,
        tipo: tipo,
        padre: padre,
        subPadre: "",
      };
      var params = {
        TableName: "Video_Menu",
        Item: datos,
      };
      docClient.put(params, function (err, data) {
        if (err) {
          console.log("Error", err);
        } else {
          dispatch({ type: "CREATE_VIDEO" });
        }
      });
    }
  };
export const get_videos = () => async (dispatch) => {
  dispatch({ type: "LOAD_VIDEOS" });
  try {
    var params = {
      TableName: "Video_Menu",
      FilterExpression: "#tipo = :tipo",
      ExpressionAttributeNames: {
        "#tipo": "tipo",
      },
      ExpressionAttributeValues: {
        ":tipo": "main",
      },
    };
    docClient.scan(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        dispatch({ type: "GET_VIDEOS", payload: data.Items });
      }
    });
  } catch (error) {
    return [];
  }
};
export const get_videos_proyecto = (id) => async (dispatch) => {
  console.log("[ID]", id);
  dispatch({ type: "LOAD_VIDEOS" });
  try {
    var params = {
      TableName: "Video_Menu_Proyecto",
      FilterExpression: "#proyecto = :proyecto",
      ExpressionAttributeNames: {
        "#proyecto": "proyecto",
      },
      ExpressionAttributeValues: {
        ":proyecto": id,
      },
    };
    docClient.scan(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        console.log("REDUCER VIDEOS PROYECTOS", data);
        dispatch({ type: "GET_VIDEOS_PROYECTO", payload: data.Items });
      }
    });
  } catch (error) {
    return [];
  }
};
export const get_videos_children = (id) => async (dispatch) => {
  dispatch({ type: "LOAD_VIDEOS" });
  try {
    var params = {
      TableName: "Video_Menu",
      FilterExpression: "#padre = :padre",
      ExpressionAttributeNames: {
        "#padre": "padre",
      },
      ExpressionAttributeValues: {
        ":padre": id,
      },
    };
    docClient.scan(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        console.log(data);
        dispatch({ type: "GET_VIDEOS_CHILDREN", payload: data.Items });
      }
    });
  } catch (error) {
    return [];
  }
};
export const get_videos_by_id = (id) => async (dispatch) => {
  dispatch({ type: "LOAD_VIDEOS" });
  try {
    var params = {
      TableName: "Video_Menu",
      FilterExpression: "#id = :id",
      ExpressionAttributeNames: {
        "#id": "id",
      },
      ExpressionAttributeValues: {
        ":id": id,
      },
    };
    docClient.scan(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        console.log(data);
        dispatch({ type: "GET_VIDEOS", payload: data.Items[0] });
      }
    });
  } catch (error) {
    return [];
  }
};
export const delete_video = (id) => async (dispatch) => {
  //console.log("Este es el nuevo estatus---", id)

  const params = {
    TableName: "Video_Menu",
    Key: {
      id: id,
    },
  };

  docClient.delete(params, function (err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      dispatch({ type: "DELETE_VIDEO" });
    }
  });
};
export const delete_video_proyecto = (id) => async (dispatch) => {
  //console.log("Este es el nuevo estatus---", id)

  const params = {
    TableName: "Video_Menu_Proyecto",
    Key: {
      id: id,
    },
  };

  docClient.delete(params, function (err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      dispatch({ type: "DELETE_VIDEO" });
    }
  });
};
//CLIENTES CRM
