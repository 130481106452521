import React, { useState } from "react";
import { uploadFile } from "react-s3";
import { Button, Box, Flex } from "@chakra-ui/react";
import { BiSlideshow } from "react-icons/bi";
import props from "prop-types";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useUser from "./context/useUser";

const S3_BUCKET = "nctech-attachmens";
const REGION = "us-east-1";
//const ACCESS_KEY = 'AKIAWLUS3YRU4LTKLQHG';
//const SECRET_ACCESS_KEY = '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';
const ACCESS_KEY = "AKIARVGY33F62XNKJJK3";
const SECRET_ACCESS_KEY = "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";

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

const UploadPDFtoS3 = (props) => {
  const [cargando, setCargando] = useState(false);

  const [urlCassandra, setUrlCassandra] = useState(
    process.env.REACT_APP_URL_CASSANDRA,
  );
  const [tokenCasandra, setTokenCassandra] = useState(
    process.env.REACT_APP_TOKEN_CASSANDRA,
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const { user, setUser } = useUser();
  const queryClient = useQueryClient();

  // const crearAttachments = async(data) => {

  //   //const urlC = `${urlCassandra}collections/attachmens${user.id.toString()}`
  //     const urlC = `${urlCassandra}collections/attachmens`
  //     const response = await fetch(urlC, {
  //         method: 'POST',
  //         headers: {
  //             'accept': 'application/json',
  //             'Content-Type': 'application/json',
  //             'X-Cassandra-Token': tokenCasandra
  //         },
  //         body: JSON.stringify({
  //             nombreArchivo: props.nombre,
  //             fechaCreacion: Date.now(),
  //             status: "Activo",
  //             url: data.fileData.location,
  //             provider: "s3",
  //             tipo: data.extension,
  //             print: false,
  //             download: false
  //         })
  //     })

  //     if(response.status == 500){
  //         console.log("Segundo Intento")
  //         //const urlC = `${urlCassandra}collections/attachmens${user.id.toString()}`
  //         const urlC = `${urlCassandra}collections/attachmens`
  //         const response = await fetch(urlC, {
  //         method: 'POST',
  //         headers: {
  //             'accept': 'application/json',
  //             'Content-Type': 'application/json',
  //             'X-Cassandra-Token': tokenCasandra
  //         },
  //         body: JSON.stringify({
  //             nombreArchivo: props.nombre,
  //             fechaCreacion: Date.now(),
  //             status: "Activo",
  //             url: data.fileData.location,
  //             provider: "s3",
  //             tipo: data.extension,
  //             print: false,
  //             download: false
  //         })
  //     })
  //     props.query()

  //     }

  //     console.log(response)
  //     return response

  // }

  const crearAttachments = async (data) => {
    const urlC = `https://servicesemail-production.herokuapp.com/api/documentos`;
    const response = await fetch(urlC, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nombreArchivo: props.nombre,
        fechaCreacion: Date.now(),
        status: "Activo",
        url: data.fileData.location,
        provider: "s3",
        tipo: data.extension,
        print: false,
        download: false,
        categoria: props.categoria,
        tipoAplicacion: props.tipoAplicacion,
        tipoArchivo: props.tipoArchivo,
      }),
    });
    if (response.status == 500) {
      console.log("Segundo Intento");
      //const urlC = `${urlCassandra}collections/attachmens${user.id.toString()}`
      const urlC = `https://servicesemail-production.herokuapp.com/api/documentos`;
      const response = await fetch(urlC, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nombreArchivo: props.nombre,
          fechaCreacion: Date.now(),
          status: "Activo",
          url: data.fileData.location,
          provider: "s3",
          tipo: data.extension,
          print: false,
          download: false,
          categoria: props.categoria,
          tipoAplicacion: props.tipoAplicacion,
          tipoArchivo: props.tipoArchivo,
        }),
      });
      props.query();
    }
    console.log(response);
    return response;
  };

  const mutation = useMutation(crearAttachments, {
    onSuccess: () => {
      queryClient.invalidateQueries("ATTACHMENS");
    },
    onSettled: () => {
      props.query();
      props.cerrar();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  // const handleUpload = async (file) => {

  //     uploadFile(file, config)
  //         .then(data =>

  //             mutation.mutate(data),
  //             setCargando(true)
  //         )
  //         .catch(err => console.error(err))
  // }

  const handleUpload = async (file) => {
    const filename = file.name;
    const extension = filename.split(".").pop();
    let updatedExtension = extension;

    if (extension === "pdf") {
      updatedExtension = "propuesta";
    } else if (extension === "ppt" || extension === "pptx") {
      updatedExtension = "presentacion";
    } else if (extension === "jpg" || extension === "png") {
      updatedExtension = "imagen";
    }
    uploadFile(file, config)
      .then((data) => {
        const mutationData = {
          fileData: data,
          extension: updatedExtension,
        };
        mutation.mutate(mutationData);
        setCargando(true);
      })
      .catch((err) => console.error(err));
  };

  return (
    <Box>
      <label onChange={handleFileInput} htmlFor="filec">
        <input
          name=""
          type="file"
          id="filec"
          hidden
          accept=".ppt, .pptx, .pdf"
        />
        <Flex my={6} cursor={"pointer"}>
          <Box alignSelf={"center"}>
            <BiSlideshow size={"20px"} color={"gray.500"} />
          </Box>
          <Box
            alignContent={"center"}
            ml={2}
            fontSize={"sm"}
            fontWeight={"medium"}
            color={"gray.500"}
            fontFamily={"system-ui, sans-serif"}
          >
            Clic aquí para seleccionar tu archivo
          </Box>
          <Box
            alignContent={"center"}
            ml={2}
            fontSize={"sm"}
            fontWeight={"medium"}
            color={"gray.800"}
            fontFamily={"system-ui, sans-serif"}
          >
            {fileName}
          </Box>
        </Flex>
      </label>
      {fileName ? (
        <Button
          isLoading={cargando}
          fontSize={"sm"}
          fontWeight={"medium"}
          color={"gray.800"}
          fontFamily={"system-ui, sans-serif"}
          bg={"orange"}
          font-color={"gray.700"}
          variant={"outline"}
          onClick={() => handleUpload(selectedFile)}
        >
          {" "}
          Subir archivo{" "}
        </Button>
      ) : (
        <Button
          fontSize={"sm"}
          fontWeight={"medium"}
          color={"gray.800"}
          fontFamily={"system-ui, sans-serif"}
          bg={"orange"}
          font-color={"gray.700"}
          variant={"outline"}
          disabled
        >
          {" "}
          Subir archivo{" "}
        </Button>
      )}
    </Box>
  );
};

export default UploadPDFtoS3;
