import React from "react";
import HomeGerente from "../components/Gerente/Home";
const Home = () => {
  return (
    <>
      <HomeGerente />
    </>
  );
};

export default Home;
