import { Box } from "@chakra-ui/layout";
import React from "react";
import PerfilComponent from "../../components/Vendedor/Perfil/Perfil";
const Perfil = () => {
  return (
    <Box bg={"gray.50"} h={"100vh"}>
      <PerfilComponent />
    </Box>
  );
};

export default Perfil;
