import React, { useEffect } from "react";
import useUser from "./context/useUser";

const Validar = () => {
  const { user, setUser } = useUser();
  useEffect(() => {
    if (user.type === "1") {
      window.location.href = "/cliente";
    }
    if (user.type === "2") {
      //window.location.href = '/vendedor'
      window.location.href = "/inicio";
    }
    if (user.type === "3") {
      window.location.href = "/gerente";
    }
    if (user.type === "4") {
      window.location.href = "/admin";
    }
  }, []);
  return <></>;
};

export default Validar;
