import React, { useContext, useEffect, useState } from "react";
import {
  Spinner,
  Input,
  VStack,
  Toast,
  SimpleGrid,
  LinkOverlay,
  HStack,
  Image,
  Stack,
  useToast,
  Button,
  Text,
  Heading,
  Container,
  Box,
  Center,
  Flex,
  Spacer,
  Link,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Avatar,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  LinkBox,
} from "@chakra-ui/react";

import Header from "../Header";
import {
  ChevronDownIcon,
  PhoneIcon,
  AddIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import useUser from "../context/useUser";
import useToken from "../useToken";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import MenuCliente from "../Cliente/Utilidades/MenuCliente";
import userEvent from "@testing-library/user-event";
import { useParams } from "react-router-dom";
import {
  BsCalendar2DateFill,
  BsPinMapFill,
  BsPeopleFill,
  BsDot,
} from "react-icons/bs";
import { MdEmail, MdAttachFile } from "react-icons/md";
import { HiDocumentText, HiDotsHorizontal } from "react-icons/hi";
import { GoDotFill } from "react-icons/go";

const Home = () => {
  let { id } = useParams();
  const [cliente, setCliente] = useState();
  const queryClient = useQueryClient();
  const [datos, setDatos] = useState([]);
  const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI);
  const { token, setToken } = useToken();
  const [clientes, setClientes] = useState([]);
  const { user, setUser } = useUser();
  async function fetchClientes() {
    const clientes = fetch(urlStrapi + "/clientes/" + id).then((response) =>
      response.json(),
    );
    return clientes;
  }

  useEffect(() => {
    setCliente(id);
    console.log(id);
  }, []);

  // const { status } = useQuery(["CLIENTE", { busqueda }], fetchCliente ,{
  //         onSuccess: (data) => {
  //             //queryClient.invalidateQueries('CLIENTES')
  //             console.log("data:", data)
  //             setDatos(data)

  //         }
  //     }

  //   );

  const { isLoading, isFetching, data, refetch } = useQuery(
    "CLIENTES",
    fetchClientes,
    {
      onSuccess: (data) => {
        console.log("data:", data.nombre);
        setDatos(data);
      },
      cacheTime: Infinity,
    },
  );
  if (isLoading) {
    return (
      <Center mt={"10%"}>
        <Spinner color={"green.700"} />
      </Center>
    );
  }

  const addCliente = () => {
    window.location.href = "/vendedor/clientes/new";
  };

  // function handleChange(e) {
  //     setBusqueda(e.target.value)

  //     const result = datos.filter(nombre => nombre.nombre.toLowerCase().includes(e.target.value.toLowerCase()));

  //     if (e.target.value === "") {

  //         refetch()

  //     }
  //     setDatos(result)

  // }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // fetchCliente(event.target.value)
    }
  };

  return (
    <>
      <Flex direction={{ base: "column", lg: "row" }} bg={"pink"} h={"100vh"}>
        <MenuCliente />
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>

            <Box bg={"gray.200"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
              <Flex direction={"column"}>
                <Box bg={"white"} h={{ base: "25vh", lg: "25vh" }}>
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"pink.400"}
                      w={{ base: "100%", lg: "60%" }}
                      h={{ base: "25vh", lg: "25vh" }}
                    >
                      <Flex direction={"column"}>
                        <Box bg={"gray.300"} h={"12.5vh"}>
                          <Flex direction={"row"}>
                            <Box bg={"gray.500"} w={"70%"} h={"12.5vh"}>
                              <Flex direction={"column"}>
                                <Box bg={"white"} w={"100%"} h={"8.25vh"}>
                                  <Box
                                    alignSelf={"end"}
                                    fontSize={{
                                      lg: "17px",
                                      base: "23px",
                                      "2xl": "33px",
                                    }}
                                    fontWeight={"semibold"}
                                    color={"gray.700"}
                                    fontFamily={"Roboto"}
                                    ml={{ lg: "0", base: "5" }}
                                    mt={{ lg: "8", base: "5" }}
                                  >
                                    Hola! Juan Carlos Santiago
                                  </Box>
                                </Box>
                                <Box bg={"white"} w={"100%"} h={"4.25vh"}>
                                  <Box
                                    fontSize={{
                                      lg: "13px",
                                      base: "15px",
                                      "2xl": "15px",
                                    }}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    ml={{ lg: "0", base: "5" }}
                                    mb={{ lg: "10", base: "0" }}
                                  >
                                    <Link href="/vendedor/clientes/detalles/1">
                                      {" "}
                                      Panel Principal{" "}
                                    </Link>
                                  </Box>
                                </Box>
                              </Flex>
                            </Box>
                            <Box bg={"white"} w={"30%"} h={"12.5vh"}>
                              <Flex direction={"column"} justifyContent={"end"}>
                                <Box
                                  bg={"white"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                  align={"end"}
                                  mt={8}
                                  pr={{ base: 10, lg: 0 }}
                                >
                                  <Tooltip
                                    label="Crear nuevo proyecto"
                                    aria-label="A tooltip"
                                    placement="right"
                                    borderRadius={"sm"}
                                  >
                                    <Menu>
                                      <MenuButton
                                        px={4}
                                        py={2}
                                        transition="all 0.2s"
                                        borderRadius="md"
                                        borderWidth="1px"
                                        _hover={{
                                          bg: "gray.400",
                                          color: "white",
                                        }}
                                        _expanded={{
                                          bg: "gray.500",
                                          color: "white",
                                        }}
                                        _focus={{ boxShadow: "outline" }}
                                        as={Button}
                                      >
                                        <HiDotsHorizontal />
                                      </MenuButton>
                                      <MenuList>
                                        <MenuItem>Dejar de compartir</MenuItem>
                                        <MenuDivider />
                                        <MenuItem>Eliminar</MenuItem>
                                      </MenuList>
                                    </Menu>
                                  </Tooltip>
                                </Box>
                                <Box bg={"white"} w={"100%"} h={"4.16vh"}></Box>
                                <Box bg={"white"} w={"100%"} h={"4.16vh"}></Box>
                              </Flex>
                            </Box>
                          </Flex>
                        </Box>
                        <Box bg={"white"} h={"12.5vh"}>
                          <Center>
                            <Box
                              w={"100%"}
                              mt={2}
                              bg={"white"}
                              pb={5}
                              pl={{ base: 5, lg: 0 }}
                              pr={{ base: 5, lg: 0 }}
                            >
                              <Flex
                                direction={"row"}
                                justifyContent={"space-between"}
                              >
                                <Box
                                  fontSize={{ lg: "15px", base: "15px" }}
                                  fontWeight={"bold"}
                                  color={"gray.700"}
                                  ml={{ lg: "0", base: "5" }}
                                  mb={{ lg: "10", base: "0" }}
                                >
                                  Vendedor
                                  <Flex justify={"start"}>
                                    <Text
                                      fontWeight={"normal"}
                                      color={"gray.500"}
                                    >
                                      José Pérez
                                    </Text>
                                  </Flex>
                                </Box>
                                <Box
                                  fontSize={{ lg: "15px", base: "15px" }}
                                  fontWeight={"bold"}
                                  color={"gray.700"}
                                  ml={{ lg: "0", base: "5" }}
                                  mb={{ lg: "10", base: "0" }}
                                >
                                  Estado
                                  <Flex justify={"start"}>
                                    <Box
                                      h={"2vh"}
                                      justifySelf={"center"}
                                      fontSize={"15px"}
                                      mt={"1"}
                                      mr={1}
                                      color={"green.500"}
                                    >
                                      <GoDotFill color={"green.400"} />
                                    </Box>
                                    <Text
                                      fontWeight={"normal"}
                                      color={"gray.500"}
                                    >
                                      Compartido
                                    </Text>
                                  </Flex>
                                </Box>
                                <Box
                                  fontSize={{ lg: "15px", base: "15px" }}
                                  fontWeight={"bold"}
                                  color={"gray.700"}
                                  ml={{ lg: "0", base: "5" }}
                                  mb={{ lg: "10", base: "0" }}
                                >
                                  Fecha de Creación
                                  <Flex justify={"start"}>
                                    <Box
                                      h={"2vh"}
                                      justifySelf={"center"}
                                      fontSize={"15px"}
                                      mt={"1"}
                                      mr={3}
                                    >
                                      <BsCalendar2DateFill />
                                    </Box>
                                    <Text
                                      fontWeight={"normal"}
                                      color={"gray.500"}
                                    >
                                      20/12/2021
                                    </Text>
                                  </Flex>
                                </Box>
                              </Flex>
                            </Box>
                          </Center>
                        </Box>
                        <Divider />
                      </Flex>
                    </Box>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
                <Box
                  bg={"white"}
                  h={{ base: "65vh", lg: "65vh" }}
                  pl={{ base: 0, lg: 0 }}
                  pr={{ base: 0, lg: 0 }}
                >
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"white"}
                      w={{ base: "0", lg: "90%" }}
                      h={{ base: "0", lg: "65vh" }}
                    >
                      <Box bg={"white"} w={"100%"} mt={5}>
                        <Center>
                          <Flex direction={"column"} w={"100%"}>
                            <Box
                              w={"100%"}
                              fontSize={"21px"}
                              fontWeight={"medium"}
                              fontFamily={"Roboto"}
                            >
                              Listado de proyectos
                            </Box>

                            <Flex direction={"row"}>
                              <Box
                                borderWidth={1}
                                bg={"gray.100"}
                                w={"300px"}
                                h={"15vh"}
                                borderRadius={"md"}
                                mr={2}
                                my={2}
                                cursor={"pointer"}
                                _hover={{ bg: "gray.200" }}
                              >
                                <Link href="/cliente/detalles">
                                  <Flex direction={"column"} h={"15vh"}>
                                    <Flex direction={"row"}>
                                      <Flex direction={"column"}>
                                        <Box
                                          mt={5}
                                          ml={3}
                                          fontWeight={"medium"}
                                          fontSize={"15px"}
                                          fontFamily={"Roboto"}
                                          color="gray.700"
                                        >
                                          Título del Proyecto
                                        </Box>
                                        <Box
                                          ml={"3"}
                                          fontWeight={"normal"}
                                          fontSize={"13px"}
                                          fontFamily={"Roboto"}
                                          color="gray.500"
                                        >
                                          Categoría del proyecto
                                        </Box>
                                      </Flex>
                                      <Spacer />
                                      <Box
                                        alignSelf={"center"}
                                        mx={"5"}
                                        fontSize={"13px"}
                                      >
                                        <Flex direction={"row"}>
                                          <Box
                                            fontSize={"15px"}
                                            color={"green.500"}
                                          >
                                            <BsDot fontSize={"21px"} />
                                          </Box>
                                          <Box>Activo</Box>
                                        </Flex>
                                      </Box>
                                    </Flex>
                                    <Spacer />
                                    <Box w={"100%"} alignSelf={"end"}>
                                      <Flex
                                        justifyContent={"space-between"}
                                        mx={3}
                                        my={3}
                                      >
                                        <Box
                                          fontSize={"13px"}
                                          alignSelf={"end"}
                                          color={"gray.500"}
                                        >
                                          1 Presentación
                                        </Box>
                                        <Box
                                          fontSize={"13px"}
                                          alignSelf={"end"}
                                          color={"gray.500"}
                                        >
                                          0 Propuestas
                                        </Box>
                                        <Box
                                          fontSize={"13px"}
                                          alignSelf={"end"}
                                          color={"gray.500"}
                                        >
                                          0 Contratos
                                        </Box>
                                      </Flex>
                                    </Box>
                                  </Flex>
                                </Link>
                              </Box>

                              <Box
                                bg={"gray.500"}
                                w={"200px"}
                                h={"15vh"}
                                borderRadius={"md"}
                                mx={2}
                                my={2}
                              ></Box>
                            </Flex>
                          </Flex>
                        </Center>
                      </Box>
                    </Box>
                    {/* Tabla para movil*/}
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
      {/* <Box >
            <Box fontFamily={'Roboto'} fontSize={'33px'} fontWeight={'semibold'} color={'gray.800'} align={'center'} p={'5'}>Panel Inicial</Box>
            
            <Box  w={'100%'}  bg={'gray.50'} p={2}>
        
                    <Flex direction={'column'} justifyContent={'center'}>
                    <Center>
                    <Box w='50%' align={'end'} mb={'5'} mt={'2'}>
                        <Button w={'200px'} variant={'outline'} colorScheme={'green'} onClick={addCliente}>
                           <AddIcon mr={'5'} /> Nuevo Cliente
                        </Button>
                    </Box>
                    </Center>
                        <Center >
                            <Box w={'50%'}>
                        <FormControl id='razon'>
                            <FormLabel>Buscar Cliente</FormLabel>
                            <Input type='text'  autoFocus onKeyPress={handleKeyPress} bg={'white'} />
                            <FormHelperText>Presiona la tecla Enter para inicar la busqueda</FormHelperText>
                        </FormControl>
                        </Box>
                        </Center>
                    </Flex>
                    <VStack >
                        <Center>
                            <Table variant='simple' colorScheme={'orange'} size={'md'} fontFamily={'Roboto'} borderWidth={1} borderRadius={'lg'} p={10}>
                                <Thead>
                                    <Tr>
                                        <Th fontSize={'13px'} fontFamily={'Roboto'}>Razón Social</Th>
                                        <Th fontSize={'13px'} fontFamily={'Roboto'}>Nombre</Th>
                                        <Th fontSize={'13px'} fontFamily={'Roboto'}>Dirección</Th>
                                        <Th fontSize={'13px'} fontFamily={'Roboto'}>Contacto</Th>
                                        <Th fontSize={'13px'} fontFamily={'Roboto'}>Email</Th>
                                        <Th fontSize={'13px'} fontFamily={'Roboto'}>Teléfono</Th>
                                        <Th fontSize={'13px'} fontFamily={'Roboto'}>Acciones</Th>
                                    </Tr>
                                </Thead>
                                {datos.map((item, index) => {
                                    return (
                                        <Tbody size={'sm'}>
                                            <Tr>
                                                <Td fontSize={'13px'} fontWeight={'medium'} color={'orange.500'} ><Avatar name={item.nombre} size='sm' mr={'3'} /> <Link href='/vendedor/clientes/detalles' >{item.razonSocial}</Link></Td>
                                                <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}>{item.nombre} </Td>
                                                <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}>{item.direccion}</Td>
                                                <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}>{item.nombreContacto} </Td>
                                                <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}>{item.correo}</Td>
                                                <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}>{item.telefono}</Td>
                                                <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}><Button>...</Button></Td>
                                            </Tr>
                                        </Tbody>
                                    )
                                }
                                )}

                            </Table>
                        </Center>
                    </VStack>
            </Box>
            
            </Box> */}
    </>
  );
};

export default Home;
