import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Spinner,
  Input,
  Button,
  Text,
  Box,
  Center,
  useDisclosure,
  Flex,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";
import Header from "../../Header";
import useUser from "../../context/useUser";
import useToken from "../../useToken";
import { useQuery, useMutation, useQueryClient } from "react-query";
import MenuVendedor from "../Utilidades/MenuVendedor";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { IoMdSend } from "react-icons/io";
import * as AWS from "aws-sdk";
import { nanoid } from "nanoid";
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import MenuGerente from "../../Gerente/Utilidades/MenuGerente";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { RenderIf } from "../../../hooks/renderIF";

AWS.config.update({
  region: "us-east-1",
  secretAccessKey: "3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi",
  accessKeyId: "AKIARVGY33F62XNKJJK3",
});
//AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' });
const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: "2022-01-01" });
export default function Compartir() {
  const form = useRef();
  let { id, proyecto, nombreProyecto, nombreCliente } = useParams();
  const {
    isOpen: isContactoModalOpen,
    onOpen: onOpenContactoModal,
    onClose: onCloseContactoModal,
  } = useDisclosure();
  const [cliente, setCliente] = useState();
  const queryClient = useQueryClient();
  const [datos, setDatos] = useState([]);
  const [datos_c, setDatos_c] = useState([]);
  const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI);
  const [urlCassandra, setUrlCassandra] = useState(
    process.env.REACT_APP_URL_CASSANDRA,
  );
  const [tokenCasandra, setTokenCassandra] = useState(
    process.env.REACT_APP_TOKEN_CASSANDRA,
  );
  const [urlNotificaciones, setUrlNotificaciones] = useState(
    process.env.REACT_APP_URL_NOTIFICACIONES,
  );
  const [templateID, setTemplateID] = useState(
    process.env.REACT_APP_TEMPLATEID,
  );
  const [urlCliente, setUrlCliente] = useState(
    process.env.REACT_APP_URL_CLIENTE,
  );
  const { token, setToken } = useToken();
  const [clientes, setClientes] = useState([]);
  const { user, setUser } = useUser();
  const [correos, setCorreos] = useState();
  const [asunto, setAsunto] = useState();
  const [comentarios, setComentarios] = useState();
  const [cargando, setCargando] = useState(false);
  const [para, setPara] = useState("");
  const [contactosAdicionales, setContactosAdicionales] = useState();
  const [pickerItems, setPickerItems] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [pickerItemsCC, setPickerItemsCC] = useState({});
  const [selectedItemsCC, setSelectedItemsCC] = useState([]);
  const [pickerItemsCCO, setPickerItemsCCO] = useState({});
  const [selectedItemsCCO, setSelectedItemsCCO] = useState([]);
  const [nombre, setNombre] = useState("");
  const [categoria, setCategoria] = useState("");
  const [nombreContacto, setNombreContacto] = useState("");
  const [whatsapp, setWhatsApp] = useState("");
  const [contactoCorreo, setContactoCorreo] = useState("");
  const [contactoPuesto, setContactoPuesto] = useState("");
  const [fuente] = useState(
    "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
  );
  const [value, setValue] = useState("");
  const [valueText, setValueText] = useState("");
  const [phone, setPhone] = useState("52");
  const toast = useToast();
  async function fetchContactos() {
    try {
      var params = {
        TableName: "Contactos_Adicionales",
        FilterExpression: "#idProyecto = :idProyecto",
        ExpressionAttributeNames: {
          "#idProyecto": "idProyecto",
        },
        ExpressionAttributeValues: {
          ":idProyecto": proyecto,
        },
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          console.log("Query succeeded---contactos.");
          const objeto = data.Items.map((a) => {
            return {
              value: a.correo,
              label: a.correo,
            };
          });
          setPickerItems(objeto);
          setPickerItemsCC(objeto);
          setPickerItemsCCO(objeto);
          //console.log("fetch-datos", objeto)
          //return objeto
        }
      });
    } catch (error) {
      return [];
    }
  }

  async function fetchClientes() {
    const clientes = fetch(urlStrapi + "/clientes/" + proyecto).then(
      (response) => response.json(),
    );
    return clientes;
  }

  const actualizaEstado = async (estado) => {
    setCargando(true);

    try {
      const params = {
        TableName: "Proyectos",
        Key: {
          id: id,
        },
        UpdateExpression: "set #estatus = :valor, #comentarios = :valor2",
        ExpressionAttributeNames: {
          "#estatus": "estatus",
          "#comentarios": "comentarios",
        },
        ExpressionAttributeValues: {
          ":valor": estado,
          ":valor2": value,
        },
      };

      docClient.update(params, function (err, data) {
        if (err) {
          console.log("Error", err);
        } else {
          envio(estado);
        }
      });
    } catch (error) {}
  };

  const envio = async (estado) => {
    if (estado === "Compartido") {
      console.log("Compartido:", `${datos.token}*${user.id},${proyecto}`);
      const urlPublic = `https://portalcl.nctech.com.mx/oneaccess/${proyecto}/${id}/${user.id}/${user.username}`; 
      //const urlC = `http://localhost:8080/api/mail`
      const urlC = urlNotificaciones;
      //const str = nombre.toLowerCase()
      //const r = form.current[1].value.toString().replace(/\s/g, '');
      //const co = form.current[2].value.toString().replace(/\s/g, '');
      const body = {
        templateId: "d-612962b334954e459cb7f1b11cf59f93", //Cambio de template a producción
        to: selectedItems.map((a) => a.value),
        cc: selectedItemsCC.map((a) => a.value),
        bcc: user.email,
        subject: asunto || "¡Tenemos un proyecto que compartir contigo!",
        vendedor: user.username,
        cliente: datos.username,
        proyecto: nombreProyecto,
        url: urlPublic,
        comentarios: value,
      };
      const response = await fetch(urlC, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      });
      if (response.status == 201) {
        window.location = `/vendedor/proyectos/${proyecto}/${id}/${nombreCliente}`;
      }
    }
    setCargando(false);
    //return guardar
  };

  const fetchData = () => {
    //console.log("fetchDayaID:", id)
    var params = {
      TableName: "Templates",
      //   FilterExpression: "#nom = :name",
      //  ExpressionAttributeNames: {
      //      "#nom": "proyecto",
      //  },
      //  ExpressionAttributeValues: {
      //      ":name": id
      //  }
    };
    docClient.scan(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        console.log("Query succeeded.");
        setDatos_c(data.Items);
      }
    });
  };

  useEffect(() => {
    setCliente(id);

    // console.log("Este es el ID:", proyecto)
  }, []);

  const putData_contactos = (tableName, data) => {
    var params = {
      TableName: tableName,
      Item: data,
    };
    docClient.put(params, function (err, data) {
      if (err) {
        console.log("Error", err);
      } else {
        console.log("Success--vendedor", data);

        refetchContactos();
        //refetch_c()
      }
    });
  };
  const crearContactos = async (t) => {
    const Data = {
      id: nanoid(),
      nombre: nombreContacto,
      whatsapp: `+${whatsapp}`,
      correo: contactoCorreo,
      fechaCreacion: Date.now(),
      puesto: contactoPuesto,
      idProyecto: proyecto,
      telOficina: `+${phone}`,
    };
    await putData_contactos("Contactos_Adicionales", Data);
  };
  function handleonclickContactos(t) {
    mutation_b.mutate(t);
  }

  const mutation = useMutation(actualizaEstado, {
    onSuccess: () => {
      queryClient.invalidateQueries("CLIENTES");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation_b = useMutation(crearContactos, {
    onSuccess: () => {
      queryClient.invalidateQueries("CONTACTOS");
      setCargando(false);
      onCloseContactoModal();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  function shareClick(estado) {
    //setCargar(true)
    mutation.mutate(estado);
  }
  function AlertAsunto(estado) {
    //setCargar(true)
    toast("El campo asunto no puede estar vacio");
  }
  function onChangePara(e) {
    setPara(e.target.value);
    console.log(para + 1);
  }
  const { data_b } = useQuery("TEMPLATES", fetchData);

  const { refetch: refetchContactos } = useQuery("CONTACTOS", fetchContactos);

  const { isLoading, isFetching, data, refetch } = useQuery(
    "CLIENTES",
    fetchClientes,
    {
      onSuccess: (data) => {
        console.log("data:", data.nombre);
        setDatos(data);
      },
      cacheTime: Infinity,
    },
  );
  if (isLoading) {
    return (
      <Center mt={"10%"}>
        <Spinner color={"green.700"} />
      </Center>
    );
  }
  const config = {
    placeholderText: "Escribe tu texto aquí!",
    charCounterCount: true,
  };
  const asignaTemplate = (e) => {
    setValue(e);
  };
  const handleCreateItem = (item) => {
    setPickerItems((curr) => [...curr, item]);
    setSelectedItems((curr) => [...curr, item]);
  };
  const handleCreateItemCC = (itemCC) => {
    //console.log("itemCC",itemCC)
    setPickerItemsCC((currCC) => [...currCC, itemCC]);
    setSelectedItemsCC((currCC) => [...currCC, itemCC]);
  };
  const handleCreateItemCCO = (itemCCO) => {
    setPickerItemsCCO((currCCO) => [...currCCO, itemCCO]);
    setSelectedItemsCCO((currCCO) => [...currCCO, itemCCO]);
  };

  const handleSelectedItemsChange = (selectedItems, selectedItemsCC) => {
    if (selectedItems) {
      setSelectedItems(selectedItems);
    }
    if (selectedItemsCC) {
      setSelectedItemsCC(selectedItemsCC);
    }
  };
  const handleSelectedItemsChangeCC = (selectedItemsCC) => {
    if (selectedItemsCC) {
      setSelectedItemsCC(selectedItemsCC);
    }
  };
  const handleSelectedItemsChangeCCO = (selectedItemsCCO) => {
    if (selectedItemsCCO) {
      setSelectedItemsCCO(selectedItemsCCO);
    }
  };
  const back = () => {
    window.location = `/vendedor/proyectos/${proyecto}/${id}/${nombreCliente}`;
  };
  const handleQuillChange = (content, delta, source, editor) => {
    // Obtener el texto sin las etiquetas HTML
    //const plainText = editor.getText().split('').reverse().join('');
    //setValueText(plainText);
    setValue(content);
  };

  return (
    <>
      <Flex direction={{ base: "column", lg: "row" }} h={"100vh"}>
        <RenderIf isTrue={user.type !== 3}>
          <MenuVendedor />
        </RenderIf>
        <RenderIf isTrue={user.type == 3}>
          <MenuGerente />
        </RenderIf>
        <Modal
          size={"lg"}
          closeOnOverlayClick={false}
          isOpen={isContactoModalOpen}
          onClose={onCloseContactoModal}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              fontSize={"15pt"}
              fontWeight={"bold"}
              fontFamily={fuente}
              textTransform={"uppercase"}
            >
              Nuevo contacto
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Text
                fontSize={"15px"}
                fontWeight={"normal"}
                fontFamily={fuente}
                my={2}
              >
                Nombre del contacto
              </Text>
              <Input
                onChange={(e) => setNombreContacto(e.target.value)}
                mt={"1"}
                required={true}
                placeholder="Escribe un nombre completo para tú contacto"
                fontSize={"11pt"}
                fontFamily={fuente}
                fontWeight={"normal"}
              />
              <Text
                fontSize={"11pt"}
                fontWeight={"normal"}
                fontFamily={fuente}
                my={2}
              >
                Ingresa el número de WhatsApp
              </Text>
              <PhoneInput
                country={"mx"}
                enableSearch={true}
                value={whatsapp || ""}
                onChange={(whatsapp) => setWhatsApp(whatsapp)}
              />
              <Text
                fontSize={"11pt"}
                fontWeight={"normal"}
                fontFamily={fuente}
                my={2}
              >
                Teléfono de Oficina
              </Text>
              <PhoneInput
                country={"mx"}
                enableSearch={true}
                value={phone || ""} // Si phone es undefined, establece un valor predeterminado
                onChange={(phone) => setPhone(phone)}
                containerStyle={{ height: "40px" }}
              />
              <Text
                fontSize={"11pt"}
                fontWeight={"normal"}
                fontFamily={fuente}
                my={2}
              >
                Correo electrónico
              </Text>
              <Input
                type={"email"}
                onChange={(e) =>
                  setContactoCorreo(e.target.value.toLowerCase())
                }
                mt={"1"}
                required={true}
                placeholder="Escribe un correo electrónico."
                fontSize={"11pt"}
                fontFamily={fuente}
                fontWeight={"normal"}
              />
              <Text
                fontSize={"11pt"}
                fontWeight={"normal"}
                fontFamily={fuente}
                my={2}
              >
                Puesto
              </Text>
              <Input
                type={"text"}
                onChange={(e) => setContactoPuesto(e.target.value)}
                mt={"1"}
                required={true}
                placeholder="Escribe un puesto para tú contacto."
                fontSize={"11pt"}
                fontFamily={fuente}
                fontWeight={"normal"}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={cargando}
                onClick={handleonclickContactos}
                colorScheme="orange"
                variant={"solid"}
                mr={3}
              >
                Crear
              </Button>
              <Button onClick={onCloseContactoModal}>Cancelar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>
            <Box bg={"white"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
              <Center>
                <Flex direction={"row"} w={"100%"}>
                  <Flex
                    w={"100%"}
                    border={"1px"}
                    my={5}
                    mx={5}
                    rounded={"lg"}
                    h={"80vh"}
                    borderColor={"gray.100"}
                    direction={"column"}
                  >
                    <Box
                      mx={5}
                      my={5}
                      color={"gray.600"}
                      fontSize={"25px"}
                      fontWeight={"bold"}
                      fontFamily={fuente}
                    >
                      Comparte tu proyecto
                    </Box>
                    <Flex direction={"column"}>
                      <form ref={form}>
                        <Flex h={"auto"}>
                          <Box
                            alignSelf={"center"}
                            mx={5}
                            fontSize={"sm"}
                            fontFamily={fuente}
                            w={"70px"}
                            fontWeight={"bold"}
                            rounded={5}
                            color={"gray.500"}
                            p={"2"}
                            bg={"gray.100"}
                          >
                            Para:
                          </Box>
                          <Input
                            mx={5}
                            w={"50%"}
                            hidden
                            value={para}
                            onChange={(e) => setPara(e.target.value)}
                            name="to_email"
                            mt={"1"}
                            placeholder="agrega un correo electrónico"
                            className={"font"}
                            fontSize={"11pt"}
                            fontFamily={fuente}
                            fontWeight={"normal"}
                          />
                          <Box mx={5} w={"50%"}>
                            <CUIAutoComplete
                              tagStyleProps={{
                                rounded: "full",
                              }}
                              placeholder="agrega un correo electrónico"
                              onCreateItem={handleCreateItem}
                              items={pickerItems}
                              selectedItems={selectedItems}
                              onSelectedItemsChange={(changes) =>
                                handleSelectedItemsChange(changes.selectedItems)
                              }
                            />
                          </Box>
                          <Button
                            alignSelf={"end"}
                            onClick={onOpenContactoModal}
                            mb={6}
                          >
                            <svg
                              width={"24px"}
                              height={"24px"}
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="#000000"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <title></title>{" "}
                                <g id="Complete">
                                  {" "}
                                  <g id="user-add">
                                    {" "}
                                    <g>
                                      {" "}
                                      <path
                                        d="M17,21V19a4,4,0,0,0-4-4H5a4,4,0,0,0-4,4v2"
                                        fill="none"
                                        stroke="#000000"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                      ></path>{" "}
                                      <circle
                                        cx="9"
                                        cy="7"
                                        fill="none"
                                        r="4"
                                        stroke="#000000"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                      ></circle>{" "}
                                      <line
                                        fill="none"
                                        stroke="#000000"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        x1="17"
                                        x2="23"
                                        y1="11"
                                        y2="11"
                                      ></line>{" "}
                                      <line
                                        fill="none"
                                        stroke="#000000"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        x1="20"
                                        x2="20"
                                        y1="8"
                                        y2="14"
                                      ></line>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>
                            </svg>
                            <Box ml={2}>Dar de alta a nuevo contacto</Box>
                          </Button>
                        </Flex>
                        <Flex my={3} h={"auto"}>
                          <Box
                            alignSelf={"center"}
                            mx={5}
                            fontSize={"sm"}
                            fontFamily={fuente}
                            w={"70px"}
                            fontWeight={"bold"}
                            rounded={5}
                            color={"gray.500"}
                            p={"2"}
                            bg={"gray.100"}
                          >
                            CC:
                          </Box>
                          <Box mx={5} w={"50%"}>
                            <CUIAutoComplete
                              placeholder="Eje. cc@example.com"
                              onCreateItem={handleCreateItemCC}
                              items={pickerItemsCC}
                              selectedItems={selectedItemsCC}
                              onSelectedItemsChange={(changesCC) =>
                                handleSelectedItemsChangeCC(
                                  changesCC.selectedItems,
                                )
                              }
                            />
                          </Box>
                        </Flex>
                        {/* <Flex h={"auto"}>
                          <Box
                            alignSelf={"center"}
                            mx={5}
                            fontSize={"sm"}
                            fontFamily={fuente}
                            w={"70px"}
                            fontWeight={"bold"}
                            rounded={5}
                            color={"gray.500"}
                            p={"2"}
                            bg={"gray.100"}
                          >
                            CCO:
                          </Box>
                          <Input
                            type={"email"}
                            hidden
                            name="to_email"
                            fontSize={"sm"}
                            placeholder="Eje. cco@example.com"
                            mx={5}
                            w={"50%"}
                            fontFamily={fuente}
                          />
                          <Box mx={5} w={"50%"}>
                            <CUIAutoComplete
                              placeholder="Eje. cco@example.com"
                              onCreateItem={handleCreateItemCCO}
                              items={pickerItemsCCO}
                              selectedItems={selectedItemsCCO}
                              onSelectedItemsChange={(changes) =>
                                handleSelectedItemsChangeCCO(
                                  changes.selectedItems,
                                )
                              }
                            />
                          </Box>
                        </Flex> */}
                        <Flex h={"auto"}>
                          <Box
                            alignSelf={"center"}
                            mx={5}
                            fontSize={"sm"}
                            fontFamily={fuente}
                            w={"80px"}
                            fontWeight={"bold"}
                            rounded={5}
                            color={"gray.500"}
                            p={"2"}
                            bg={"gray.100"}
                          >
                            ASUNTO
                          </Box>
                          <Input
                            type={"email"}
                            hidden
                            name="to_email"
                            fontSize={"sm"}
                            placeholder="Eje. cco@example.com"
                            mx={5}
                            w={"50%"}
                            fontFamily={fuente}
                          />
                          <Box mx={2} w={"50%"}>
                            <Input
                              placeholder="Escribe aquí tu asunto"
                              value={asunto}
                              onChange={(changes) =>
                                setAsunto(changes.target.value)
                              }
                            />
                          </Box>
                        </Flex>
                        <Divider ml={5} my={5} w={"55%"} />
                        <Box my={3} mx={5}>
                          Selecciona un template (Opcional)
                        </Box>
                        <Flex direction={"row"} mx={3} my={5}>
                          {datos_c.map((o, i) => {
                            return (
                              <Button
                                key={i}
                                variant={"outline"}
                                colorScheme={"blue"}
                                ml={2}
                                bg={"gray.50"}
                                mr={2}
                                onClick={(e) => asignaTemplate(o.template)}
                              >
                                {o.nombre}
                              </Button>
                            );
                          })}
                        </Flex>
                        <Box w={"56%"} ml={5}>
                          <Divider ml={5} my={5} w={"55%"} />
                          <Box my={3} fontFamily={fuente}>
                            Cuerpo del correo
                          </Box>
                          <ReactQuill
                            theme="snow"
                            value={value}
                            onChange={handleQuillChange}
                            name="message"
                          />
                          <Flex my={7}>
                            <>
                              {asunto ? (
                                <Button
                                  isLoading={cargando}
                                  mr={5}
                                  bg={"orange.400"}
                                  value="Compartir"
                                  color={"white"}
                                  fontFamily={fuente}
                                  fontSize={"sm"}
                                  onClick={() => shareClick("Compartido")}
                                >
                                  <Box mr={2}>
                                    <IoMdSend />
                                  </Box>{" "}
                                  Enviar
                                </Button>
                              ) : (
                                <Button
                                  isLoading={cargando}
                                  mr={5}
                                  bg={"gray.400"}
                                  value="Compartir"
                                  color={"white"}
                                  fontFamily={fuente}
                                  fontSize={"sm"}
                                  onClick={() =>
                                    toast({
                                      title: `El campo asunto no puede estar vacio`,
                                      status: "warning",
                                      isClosable: true,
                                    })
                                  }
                                >
                                  <Box mr={2}>
                                    <IoMdSend />
                                  </Box>{" "}
                                  Enviar
                                </Button>
                              )}
                            </>
                            <Button
                              variant={"outline"}
                              fontFamily={fuente}
                              fontSize={"sm"}
                              onClick={() => back()}
                            >
                              Cancelar
                            </Button>
                          </Flex>
                        </Box>
                      </form>
                    </Flex>
                  </Flex>
                </Flex>
              </Center>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
}

const countries = [
  { value: "krlos013@gmail.com", label: "krlos013@gmail.com" },
];
