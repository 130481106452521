import { useState } from 'react';
export default function useUser() {
    const getMe = () => {
        const userString = localStorage.getItem('token');
        const user = JSON.parse(userString);
        return user?.user//.id
    };
    const [user, setUser] = useState(getMe());
    const saveUser = user => {
        setUser(user.user);
    };
    return {
        setUser: saveUser,
        user,
    }

}