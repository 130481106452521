import {
  combineReducers,
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import thunk from "redux-thunk";
const initialState = {
  propuesta: "",
  presentacion: [],
  vendedor: {
    avatarImage: null,
  },
  media: {
    tipo: null,
  },
  mensaje: null,
  isLoading: false,
  mensaje_load: null,
  finish: false,
  link: [],
  contratos: [],
  local: false,
  usuario: {},
  videos: [],
  videosChildren: [],
  videosProyecto: [],
  proyectos: [],
  proyecto: [],
};
export const propuestaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_PROPUESTA":
      return {
        ...state,
        propuesta: action.payload,
      };
    default:
      return state;
  }
};
export const presentacionesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_PRESENTACIONES":
      return {
        ...state,
        presentacion: action.payload,
      };
    default:
      return state;
  }
};
export const vendedorReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_VENDEDOR":
      return {
        ...state,
        avatarImage: action.payload,
        isLoading: false,
        error: "", // Borra el mensaje de error en caso de que se hayan encontrado datos
      };
    case "LOAD_VENDEDOR_REQUEST":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "VENDEDOR_ERROR":
      return {
        ...state,
        isLoading: false,
        error: `Error al cargar la imagen...`, // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
      };
    default:
      return state;
  }
};
export const clienteReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_CLIENTE":
      return {
        ...state,
        avatarImage: action.payload,
        isLoading: false,
        error: "",
        mensaje: "Logo subido exitosamente.", // Borra el mensaje de error en caso de que se hayan encontrado datos
      };
    case "LOAD_CLIENTE_REQUEST":
      return {
        ...state,
        isLoading: true,
        error: null,
        mensaje: "Espere, estamos subiendo su logo",
      };
    case "CLIENTE_ERROR":
      return {
        ...state,
        isLoading: false,
        error: `Error al cargar la imagen...`, // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
      };
    default:
      return state;
  }
};
export const mensajeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_MENSAJE":
      return {
        isLoading: true, // Borra el mensaje de error en caso de que se hayan encontrado datos
        mensaje: "Espere, estamos subiendo su logo",
      };
    case "SUCCESS_MENSAJE":
      return {
        isLoading: false,
        mensaje: "Logo subido exitosamente.",
      };
    case "CLEAN_MENSAJE":
      return {
        isLoading: true,
        mensaje: null, // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
      };
    default:
      return state;
  }
};
export const updateMediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_MEDIA":
      return {
        ...state,
        tipo: action.payload,
        isLoading: false,
        error: "", // Borra el mensaje de error en caso de que se hayan encontrado datos
      };
    case "LOAD_MEDIA_REQUEST":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "MEDIA_ERROR":
      return {
        ...state,
        isLoading: false,
        error: `Error al actualizar el video...`, // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
      };
    default:
      return state;
  }
};
export const clientesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_MENSAJE_CLIENTES":
      return {
        isLoading: true, // Borra el mensaje de error en caso de que se hayan encontrado datos
        mensaje_clientes: "Espere...",
        finish: false,
      };
    case "SUCCESS_MENSAJE_CLIENTES":
      return {
        isLoading: false,
        mensaje_clientes: "Subiendo..",
        finish: false,
      };
    case "EXITOSO_MENSAJE_CLIENTES":
      return {
        isLoading: false,
        mensaje_clientes:
          "Gracias por la espera, hemos subido tus clientes con exito.",
        finish: true,
      };
    case "CLEAN_MENSAJE_CLIENTES":
      return {
        isLoading: true,
        mensaje_clientes: null, // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
        finish: false,
      };
    default:
      return state;
  }
};
export const loadClientesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_CLIENTES":
      return {
        isLoading: true, // Borra el mensaje de error en caso de que se hayan encontrado datos
        mensaje_load: "Cargando la información del archivo de excel...",
      };
    case "SUCCESS_CLIENTES":
      return {
        isLoading: false,
        mensaje_load: `Estamos listos para comenzar a subir tus ${action.payload} clientes a tu base de datos`,
      };
    case "CLEAN_CLIENTES":
      return {
        isLoading: true,
        mensaje_load: null, // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
      };
    default:
      return state;
  }
};
export const uploadFilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_FILES":
      return {
        ...state,
        tipo: action.payload,
        isLoading: false,
        error: "", // Borra el mensaje de error en caso de que se hayan encontrado datos
      };
    case "LOAD_FILES_REQUEST":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "FILES_ERROR":
      return {
        ...state,
        isLoading: false,
        error: `Error al subir el archivo...`, // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
      };
    default:
      return state;
  }
};
export const linkReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_LINK":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "GET_LINK":
      return {
        ...state,
        isLoading: false,
        link: action.payload,
        error: null,
      };
    case "CREATE_LINK":
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case "DELETE_LINK":
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case "LINK_ERROR":
      return {
        ...state,
        isLoading: false,
        error: `Error al crear el link...`, // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
      };
    default:
      return state;
  }
};
export const contratosReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_CONTRATOS":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "GET_CONTRATOS":
      return {
        ...state,
        isLoading: false,
        contratos: action.payload,
        error: null,
      };
    case "CREATE_CONTRATOS":
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case "DELETE_CONTRATOS":
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case "UPDATE_CONTRATOS":
      return {
        ...state,
        contratos: action.payload,
      };
    case "LINK_ERROR":
      return {
        ...state,
        isLoading: false,
        error: `Error al crear el link...`, // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
      };
    default:
      return state;
  }
};
export const crmReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CRM":
      return {
        ...state,
        local: action.payload,
        isLoading: false,
      };
    case "LOAD_CRM":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "ERROR_CRM":
      return {
        ...state,
        isLoading: false,
        error: `Error al traer los datos de Cassandra...`, // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
      };
    default:
      return state;
  }
};
export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CUSTOMERS":
      return {
        ...state,
        customers: action.payload,
        isLoading: false,
      };

    case "LOAD_CUSTOMER":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "ERROR_CUSTOMER":
      return {
        ...state,
        isLoading: false,
        error: `Error al traer los datos de Cassandra...`, // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
      };
    default:
      return state;
  }
};

export const usuarioReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_USUARIO":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "GET_USUARIO":
      return {
        ...state,
        usuario: action.payload,
        isLoading: false,
      };
    case "ERROR_USUARIO":
      return {
        ...state,
        isLoading: false,
        error: `Error al traer los datos de Cassandra...`, // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
      };
    default:
      return state;
  }
};

export const videosReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_VIDEOS":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "GET_VIDEOS":
      return {
        ...state,
        videos: action.payload,
        isLoading: false,
      };
    case "GET_VIDEOS_CHILDREN":
      return {
        ...state,
        videosChildren: action.payload,
        isLoading: false,
      };
    case "UPDATE_VIDEOS":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_VIDEOS_PROYECTO":
      return {
        ...state,
        videosProyecto: action.payload,
        isLoading: false,
      };
    case "CREATE_VIDEO":
      return {
        ...state,
        isLoading: false,
      };
    case "DELETE_VIDEO":
      return {
        ...state,
        isLoading: false,
      };
    case "ERROR_VIDEO":
      return {
        ...state,
        isLoading: false,
        error: `Error al traer los datos de Cassandra...`, // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
      };
    default:
      return state;
  }
};

export const proyectosReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_PROYECTOS":
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case "GET_PROYECTOS":
      return {
        ...state,
        proyectos: action.payload,
        isLoading: false,
      };
    case "GET_PROYECTO_BY_ID":
      return {
        ...state,
        proyecto: action.payload,
        isLoading: false,
      };
    case "CREATE_PROYECTO":
      return {
        ...state,
        isLoading: false,
      };
    case "DELETE_PROYECTO":
      return {
        ...state,
        isLoading: false,
      };
    case "ERROR_PROYECTO":
      return {
        ...state,
        isLoading: false,
        error: `Error al traer los datos de Cassandra...`, // Actualiza el mensaje de error en caso de que no se hayan encontrado datos
      };
    default:
      return state;
  }
};

//root reducer
export const rootReducer = combineReducers({
  propuestaReducer,
  presentacionesReducer,
  vendedorReducer,
  updateMediaReducer,
  clienteReducer,
  mensajeReducer,
  clientesReducer,
  loadClientesReducer,
  uploadFilesReducer,
  linkReducer,
  contratosReducer,
  crmReducer,
  customerReducer,
  usuarioReducer,
  videosReducer,
  proyectosReducer,
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//store
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);
