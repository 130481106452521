import { Box } from '@chakra-ui/layout';
import React from 'react';
import Header from '../../components/Header';
import VendedoresComponent from '../../components/Gerente/Vendedores/Vendedores';
const Vendedores = () => {
    return (
        <Box bg={'gray.50'} h={'100vh'}>
            <Header />
            <VendedoresComponent />
        </Box>
    );
};

export default Vendedores;