import React, { useRef } from "react";
import EmailEditor from "react-email-editor";
import { Box } from "@chakra-ui/react";
const Editor = () => {
  const emailEditorRef = useRef(null);

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    //emailEditorRef.current.editor.loadDesign(templateJson);
    emailEditorRef.current.editor.init({
      id: "editor-container",
      displayMode: "web",
      appearance: {
        theme: "dark",
      },
    });
  };

  const onReady = () => {
    // editor is ready
    console.log("onReady");
  };

  const exportar = () => {
    emailEditorRef.current.editor.exportHtml(function (data) {
      //var json = data.design; // design json
      //var html = data.html; // final html
      // Do something with the json and html
    });
  };

  return (
    <Box h={"100vh"}>
      <EmailEditor
        ref={emailEditorRef}
        onLoad={onLoad}
        onReady={onReady}
        exportHtml={exportar}
        minHeight={"100%"}
        locale={"es-ES"}
        brand={"false"}
      />
    </Box>
  );
};

export default Editor;
