// import React, { useEffect } from 'react';
// import AWS from 'aws-sdk';

// const S3BucketCleanup = () => {
//   useEffect(() => {
//     cleanupS3Bucket();
//   }, []);

//   const cleanupS3Bucket = async () => {
//     // Configura tus credenciales de AWS

//     //const S3_BUCKET ='nctech-attachmens';
//     const S3_BUCKET = 'nctech-attachmens'
//     const REGION ='us-east-1';
//     //const ACCESS_KEY = 'AKIAWLUS3YRU4LTKLQHG'; //mostrilio
//     //const SECRET_ACCESS_KEY = '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'; //mostrilio
//     const ACCESS_KEY ='AKIARVGY33F62XNKJJK3'; //nctech
//     const SECRET_ACCESS_KEY ='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'; //nctech

//     AWS.config.update({
//       accessKeyId: ACCESS_KEY,
//       secretAccessKey: SECRET_ACCESS_KEY,
//       region: REGION
//     });

//     // Crea una instancia del servicio S3
//     const s3 = new AWS.S3();

//     // Configura los parámetros para listar los objetos en el bucket
//     const params = {
//       Bucket: S3_BUCKET
//     };

//     try {
//       // Lista los objetos en el bucket
//       const data = await s3.listObjects(params).promise();

//       // Obtiene las keys de los objetos en el bucket
//       const keys = data.Contents.map(obj => ({ Key: obj.Key }));

//       // Configura los parámetros para eliminar los objetos del bucket
//       const deleteParams = {
//         Bucket: S3_BUCKET,
//         Delete: {
//           Objects: keys
//         }
//       };

//       // Elimina los objetos del bucket
//       await s3.deleteObjects(deleteParams).promise();

//       console.log('Bucket limpiado exitosamente');
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return <div>Limpieza del bucket en progreso...</div>;
// };

// export default S3BucketCleanup;

import React, { useEffect } from "react";
import AWS from "aws-sdk";

const DynamoDBTableCleanup = () => {
  useEffect(() => {
    cleanupDynamoDBTable();
  }, []);

  const cleanupDynamoDBTable = async () => {
    // Configura tus credenciales de AWS
    //const S3_BUCKET ='nctech-attachmens';
    const TABLA = "Templates";
    const REGION = "us-east-1";
    //const ACCESS_KEY = 'AKIAWLUS3YRU4LTKLQHG'; //mostrilio
    //const SECRET_ACCESS_KEY = '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'; //mostrilio
    const ACCESS_KEY = "AKIARVGY33F62XNKJJK3"; //nctech
    const SECRET_ACCESS_KEY = "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"; //nctech

    AWS.config.update({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });

    // Crea una instancia del servicio DynamoDB
    const dynamodb = new AWS.DynamoDB.DocumentClient();

    // Configura los parámetros para escanear la tabla
    const params = {
      TableName: TABLA,
      ProjectionExpression: "#key",
      ExpressionAttributeNames: {
        "#key": "id",
      },
    };

    try {
      // Escanea la tabla para obtener los elementos
      const data = await dynamodb.scan(params).promise();

      // Obtiene los elementos a eliminar
      const items = data.Items;

      // Elimina los elementos de la tabla
      for (const item of items) {
        const deleteParams = {
          TableName: TABLA,
          Key: item,
        };

        await dynamodb.delete(deleteParams).promise();
      }

      console.log("Tabla vaciada exitosamente");
    } catch (error) {
      console.log(error);
    }
  };

  return <div>Vaciando la tabla...</div>;
};

export default DynamoDBTableCleanup;
