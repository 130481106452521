import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Spinner,
  Button,
  Box,
  Center,
  Flex,
  Divider,
  Select,
} from "@chakra-ui/react";
import Header from "../../Header";
import { PhoneIcon, AddIcon, WarningIcon } from "@chakra-ui/icons";
import useUser from "../../context/useUser";
import useToken from "../../useToken";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import userEvent from "@testing-library/user-event";
import { useParams } from "react-router-dom";
import { BiSlideshow } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { BsFillCameraVideoFill } from "react-icons/bs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { IoMdSend } from "react-icons/io";
import * as AWS from "aws-sdk";
import { nanoid } from "nanoid";
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import { Result } from "antd";
import Item from "antd/lib/list/Item";
import { RenderIf } from "../../../hooks/renderIF";
import MenuGerente from "../../Gerente/Utilidades/MenuGerente";
import MenuVendedor from "../../Vendedor/Utilidades/MenuVendedor";

AWS.config.update({
  region: "us-east-1",
  secretAccessKey: "3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi",
  accessKeyId: "AKIARVGY33F62XNKJJK3",
});

//AWS.config.update({ region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' });
const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: "2022-01-01" });
export default function Compartir() {
  const form = useRef();
  let { id, proyecto, nombreCliente } = useParams();
  const [cliente, setCliente] = useState();
  const queryClient = useQueryClient();
  const [datos, setDatos] = useState([]);
  const [datos_c, setDatos_c] = useState([]);
  const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI);
  const [urlCassandra, setUrlCassandra] = useState(
    process.env.REACT_APP_URL_CASSANDRA,
  );
  const [tokenCasandra, setTokenCassandra] = useState(
    process.env.REACT_APP_TOKEN_CASSANDRA,
  );
  const [urlNotificaciones, setUrlNotificaciones] = useState(
    process.env.REACT_APP_URL_NOTIFICACIONES,
  );
  const [templateID, setTemplateID] = useState(
    process.env.REACT_APP_TEMPLATEID,
  );
  //const [urlCliente, setUrlCliente] = useState(process.env.REACT_APP_URL_CLIENTE)
  const [urlCliente, setUrlCliente] = useState(
    "https://portalcl.nctech.com.mx",
  );
  const { token, setToken } = useToken();
  const [clientes, setClientes] = useState([]);
  const { user, setUser } = useUser();
  const [correos, setCorreos] = useState();
  const [asunto, setAsunto] = useState();
  const [comentarios, setComentarios] = useState();
  const [cargando, setCargando] = useState(false);
  const [para, setPara] = useState("");
  const [contactosAdicionales, setContactosAdicionales] = useState();
  const [pickerItems, setPickerItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [pickerItemsCC, setPickerItemsCC] = useState({});
  const [selectedItemsCC, setSelectedItemsCC] = useState([]);
  const [pickerItemsCCO, setPickerItemsCCO] = useState({});
  const [selectedItemsCCO, setSelectedItemsCCO] = useState([]);
  const [fuente] = useState(
    "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
  );
  const [value, setValue] = useState("");
  // async function fetchContactos() {
  //    // console.log(`usuario${user.id.toString()}${proyecto}`)
  //     try {
  //         const urlC = `${urlCassandra}collections/contactos${user.id.toString()}${proyecto}?page-size=20`
  //         const ventasData = await axios.get(urlC, {
  //             method: 'GET',
  //             headers: {
  //                 'accept': 'application/json',
  //                 'Content-Type': 'application/json',
  //                 'X-Cassandra-Token': tokenCasandra
  //             }
  //         })
  //         const contactos = await Object.keys(ventasData.data.data).map((item) => ({id: item, ...ventasData.data.data[item]}))

  //         const objeto = contactos.map((a) => {
  //             return  {
  //                 value: a.correo,
  //                 label: a.correo
  //             }
  //         })
  //         return objeto
  //     }   catch (error) {
  //         return []
  //     }
  // }
  async function fetchProyectos() {
    try {
      var params = {
        TableName: "Proyectos",
        FilterExpression: "#idProyecto = :idProyecto",
        ExpressionAttributeNames: {
          "#idProyecto": "id",
        },
        ExpressionAttributeValues: {
          ":idProyecto": id,
        },
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          console.log("Query succeeded--PROYECTOS.");
          setDatos(data.Items[0]);
        }
      });
    } catch (error) {
      console.log("error");
    }
  }
  async function fetchContactos() {
    try {
      var params = {
        TableName: "Contactos_Adicionales",
        FilterExpression:
          "#idProyecto = :idProyecto AND #whatsapp <> :whatsapp",
        ExpressionAttributeNames: {
          "#idProyecto": "idProyecto",
          "#whatsapp": "whatsapp",
        },
        ExpressionAttributeValues: {
          ":idProyecto": proyecto,
          ":whatsapp": null,
        },
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          console.log("Query succeeded---contactos.");
          const objeto = data.Items.map((a) => {
            return {
              value: a.whatsapp,
              label: a.nombre,
            };
          });
          setPickerItems(data.Items);

          //console.log("fetch-datos", objeto)
          //return objeto
        }
      });
    } catch (error) {
      return [];
    }
  }

  async function fetchClientes() {
    const clientes = fetch(urlStrapi + "/clientes/" + proyecto).then(
      (response) => response.json(),
    );
    return clientes;
  }

  const actualizaEstado = async (contact) => {
    // Aquí iría la lógica para actualizar el estado en la base de datos
    console.log("Actualizando estado para el contacto:", contact);

    try {
      const params = {
        TableName: "Proyectos",
        Key: {
          id: id,
        },
        UpdateExpression: "set #estatus = :valor, #comentarios = :valor2",
        ExpressionAttributeNames: {
          "#estatus": "estatus",
          "#comentarios": "comentarios",
        },
        ExpressionAttributeValues: {
          ":valor": "Compartido",
          ":valor2": value,
        },
      };

      docClient.update(params, function (err, data) {
        if (err) {
          console.log("Error", err);
        } else {
          envio(contact);
        }
      });
    } catch (error) {
      console.error("Error al actualizar el estado:", error);
    }
  };

  const envio = async (contact) => {
    // Aquí iría la lógica para enviar el mensaje de WhatsApp
    console.log("Mensaje de WhatsApp enviado al contacto:", contact);

    const urlPublic = `https://portalcl.nctech.com.mx/oneaccess/${proyecto}/${id}/${user.id}`;
    const telefono = contact;

    const mensaje = `{
      "messaging_product": "whatsapp",
      "to": "${telefono}",
      "type": "template",
          "template": {
              "name": "share_project_v2",
              "language": { "code": "es_MX" },
              "components": [
                  {
                      "type": "body",
                      "parameters": [
                          {
                              "type": "text",
                              "text": "${nombreCliente}"
                          },
                          {
                              "type": "text",
                              "text": "${user.username}"
                          },
                          {
                              "type": "text",
                              "text": "${datos.nombreProyecto}"
                          },
                          {
                              "type": "text",
                              "text": "${urlPublic}"
                          }
                      ]
                  }
              ]
          }
      }`;

    const headers = {
      Authorization:
        "Bearer EAAGIZAMLyTpUBAAZCOF3yYSb7aE45MIj3T6BpBNome5yS0yxC2BHA37N1anVpoltnHeDxlRCZBfPZA2ZBWzno22TAkgNqnoU2zqZCy0sTkO0TQ1ZBwuu5XOWgTGWBOANUo6xYgH6OruZAoZC9qUt5eBIhpamQv2etu32rSy4HqDHShfJ0ZAKnZCy83GZC4EGs7FU4lKKfZAsRhMt1jAZDZD",
      "Content-Type": "application/json",
    };

    fetch("https://graph.facebook.com/v13.0/103800795803097/messages", {
      method: "POST",
      body: mensaje,
      headers: headers,
    });
    // setTimeout(() => {
    //   setCargando(false);
    //   window.location = `/vendedor/proyectos/${proyecto}/${id}/${nombreCliente}`;
    // }, 3000);
  };

  const fetchData = () => {
    //console.log("fetchDayaID:", id)
    var params = {
      TableName: "Templates",
      //   FilterExpression: "#nom = :name",
      //  ExpressionAttributeNames: {
      //      "#nom": "proyecto",
      //  },
      //  ExpressionAttributeValues: {
      //      ":name": id
      //  }
    };
    docClient.scan(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        console.log("Query succeeded.");
        setDatos_c(data.Items);
      }
    });
  };

  useEffect(() => {
    setCliente(id);
    fetchProyectos();
    // console.log("Este es el ID:", proyecto)
  }, []);

  const mutation = useMutation(actualizaEstado, {
    onSuccess: () => {
      queryClient.invalidateQueries("CLIENTES");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  function shareClick(estado) {
    //setCargar(true)
    mutation.mutate(estado);
  }
  function onChangePara(e) {
    setPara(e.target.value);
    console.log(para + 1);
  }
  const { data_b } = useQuery("TEMPLATES", fetchData);

  useQuery("CONTACTOS", fetchContactos);

  // const { isLoading, isFetching, data, refetch } = useQuery('CLIENTES', fetchClientes, {
  //     onSuccess: (data) => {
  //         console.log("data:", data.nombre)
  //         setDatos(data)
  //     },
  //     cacheTime: Infinity
  // })
  // if (isLoading) {
  //     return <Center mt={'10%'}><Spinner color={'green.700'} /></Center>
  // }
  const config = {
    placeholderText: "Escribe tu texto aquí!",
    charCounterCount: true,
  };
  const asignaTemplate = (e) => {
    setValue(e);
  };
  const handleCreateItem = (item) => {
    setSelectedItems(item);
  };

  const cancel = () => {
    window.location = `/vendedor/proyectos/${proyecto}/${id}/${nombreCliente}`;
  };

  console.log("LISTO", user, datos);
  //console.log("para", selectedItems.map((a) => a.value).toLocaleString())
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    console.log("isUpdating:", isUpdating);

    let intervalId;

    if (isUpdating && selectedContacts.length > 0) {
      intervalId = setInterval(() => {
        if (selectedContacts.length > 0) {
          const contact = selectedContacts[0];
          setSelectedContacts(selectedContacts.slice(1)); // Remueve el primer contacto de la lista

          // Llama a la función actualizaEstado con el contacto
          actualizaEstado(contact);
          console.log("selectedContactsq:", selectedContacts);
        }
        if (selectedContacts.length === 1) {
          console.log("Entra");
          clearInterval(intervalId);
          setIsUpdating(false);
          setTimeout(() => {
            setCargando(false);
            //window.location = `/vendedor/proyectos/${proyecto}/${id}/${nombreCliente}`;
          }, 1000);
        }
      }, 3000);
    }

    return () => clearInterval(intervalId);
  }, [isUpdating, selectedContacts]);

  const handleToggleContact = (whatsapp) => {
    if (selectedContacts.includes(whatsapp)) {
      setSelectedContacts(
        selectedContacts.filter((contact) => contact !== whatsapp),
      );
    } else {
      setSelectedContacts([...selectedContacts, whatsapp]);
    }
  };

  return (
    <>
      <Flex direction={{ base: "column", lg: "row" }} h={"100vh"}>
        <RenderIf isTrue={user.type !== 3}>
          <MenuVendedor />
        </RenderIf>
        <RenderIf isTrue={user.type == 3}>
          <MenuGerente />
        </RenderIf>
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>
            <Box bg={"white"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
              <Center>
                <Flex direction={"row"} w={"100%"}>
                  <Flex
                    w={"100%"}
                    border={"1px"}
                    my={5}
                    mx={5}
                    rounded={"lg"}
                    h={"80vh"}
                    borderColor={"gray.100"}
                    direction={"column"}
                  >
                    <Box
                      mx={5}
                      my={5}
                      color={"gray.600"}
                      fontSize={"25px"}
                      fontWeight={"bold"}
                      fontFamily={fuente}
                    >
                      Comparte tu proyecto por WhatsApp
                    </Box>
                    <Flex direction={"column"}>
                      <form ref={form}>
                        <Flex h={"auto"}>
                          <Box
                            alignSelf={"center"}
                            mx={5}
                            fontSize={"sm"}
                            fontFamily={fuente}
                            w={"70px"}
                            fontWeight={"bold"}
                            rounded={5}
                            color={"gray.500"}
                            p={"2"}
                            bg={"gray.100"}
                          >
                            Para:
                          </Box>

                          <Box mx={5} w={"50%"}>
                            <Box>Selecciona un contacto</Box>
                            <Box py={2} fontSize={"11px"} color={"gray.500"}>
                              Solo se mostrarán los usuarios que tengan un
                              número de teléfono registrado
                            </Box>
                            {/* <Input type={'tel'} placeholder={'Ingresa el número teléfonico de WhatsApp'} value={para} onChange={(e) => setPara(e.target.value)}/> */}

                            {/* <Select
                              onChange={(e) => handleCreateItem(e.target.value)}
                            >
                              <option>Selecciona un contacto aquí...</option>
                              {pickerItems.map((o, i) => {
                                const label_contacto = `${o.nombre} (${o.whatsapp})`;
                                return (
                                  <option key={i} value={o.whatsapp}>
                                    {label_contacto}
                                  </option>
                                );
                              })}
                            </Select> */}

                            <div>
                              <Box fontSize={"16px"} fontWeight={"bold"} mb={3}>
                                Contactos:
                              </Box>
                              {pickerItems.map((o, i) => {
                                const label_contacto = `${o.nombre} (${o.whatsapp})`;
                                return (
                                  <div key={i}>
                                    <label>
                                      <input
                                        style={{ marginRight: "3px" }}
                                        type="checkbox"
                                        value={o.whatsapp}
                                        checked={selectedContacts.includes(
                                          o.whatsapp,
                                        )}
                                        onChange={() =>
                                          handleToggleContact(o.whatsapp)
                                        }
                                      />
                                      {label_contacto}
                                    </label>
                                  </div>
                                );
                              })}

                              <Box
                                fontSize={"16px"}
                                fontWeight={"bold"}
                                mb={3}
                                mt={3}
                              >
                                Contactos seleccionados:
                              </Box>
                              <ul>
                                {selectedContacts.map((contact, index) => (
                                  <Flex key={index}>
                                    <Spinner color={"green.500"} mr={2} />
                                    <Box>{contact}</Box>
                                  </Flex>
                                ))}
                              </ul>
                            </div>

                            {/* <CUIAutoComplete
                                            tagStyleProps={{
                                                rounded: 'full'
                                            }}
                                            multiSelect={false}
                                            placeholder="Selecciona un contacto"
                                            onCreateItem={handleCreateItem}
                                            items={pickerItems}
                                            selectedItems={selectedItems}
                                            
                                            onSelectedItemsChange={(changes) =>
                                            handleSelectedItemsChange(changes.selectedItems)
                                            }
                                        />  */}
                          </Box>
                        </Flex>
                        <Divider ml={5} my={5} w={"55%"} />
                        <Flex my={7} ml={5}>
                          <>
                            {(selectedContacts.length != 0) &
                            (selectedContacts !==
                              "Selecciona un contacto aquí...") ? (
                              <Button
                                isLoading={cargando}
                                mr={5}
                                bg={"orange.400"}
                                value="Compartir"
                                color={"white"}
                                fontFamily={fuente}
                                fontSize={"sm"}
                                //onClick={() => shareClick("Compartido")}
                                onClick={() => setIsUpdating(true)}
                                disabled={
                                  selectedContacts.length === 0 || isUpdating
                                }
                              >
                                <Box mr={2}>
                                  <IoMdSend />
                                </Box>{" "}
                                Enviar
                              </Button>
                            ) : (
                              <Button
                                isLoading={cargando}
                                mr={5}
                                bg={"gray.400"}
                                value="Compartir"
                                color={"white"}
                                fontFamily={fuente}
                                fontSize={"sm"}
                              >
                                <Box mr={2}>
                                  <IoMdSend />
                                </Box>{" "}
                                Enviar
                              </Button>
                            )}
                          </>
                          <Button
                            variant={"outline"}
                            fontFamily={fuente}
                            fontSize={"sm"}
                            onClick={() => cancel()}
                          >
                            Cancelar
                          </Button>
                        </Flex>
                      </form>
                    </Flex>
                  </Flex>
                </Flex>
              </Center>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
}

const countries = [
  { value: "krlos013@gmail.com", label: "krlos013@gmail.com" },
];
