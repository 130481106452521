import React, { useState } from "react";
import { uploadFile } from "react-s3";
import {
  Spinner,
  Input,
  VStack,
  Toast,
  SimpleGrid,
  LinkOverlay,
  HStack,
  Image,
  Stack,
  useToast,
  Button,
  Text,
  Heading,
  Container,
  Box,
  Center,
  Collapse,
  Fade,
  ScaleFade,
  Slide,
  SlideFade,
  useDisclosure,
  Flex,
  Spacer,
  Link,
  LinkBox,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Avatar,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Progress,
} from "@chakra-ui/react";
import { BiSlideshow } from "react-icons/bi";
import props from "prop-types";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useUser from "./context/useUser";
import axios from "axios";
import * as AWS from "aws-sdk";
AWS.config.update({
  region: "us-east-1",
  secretAccessKey: "3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi",
  accessKeyId: "AKIARVGY33F62XNKJJK3",
}); //PRODUCCION
//AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' }); //DESARROLLO
const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: "2022-01-01" });
const S3_BUCKET = "nctech-imagenes";
const REGION = "us-east-1";
const ACCESS_KEY = "AKIARVGY33F62XNKJJK3";
const SECRET_ACCESS_KEY = "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";

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

const UploadImageToS3WithReactS3 = (props) => {
  const [urlCassandra, setUrlCassandra] = useState(
    process.env.REACT_APP_URL_CASSANDRA,
  );
  const [tokenCasandra, setTokenCassandra] = useState(
    process.env.REACT_APP_TOKEN_CASSANDRA,
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const { user, setUser } = useUser();
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState(0);
  const [cargando, setCargando] = useState(false);

  //    const crearPresentacion = async (data) => {
  //     const urlC = `${urlCassandra}collections/presentaciones${user.id.toString()}`;
  //     const body = {
  //       nombrePresentacion: props.nombre,
  //       fechaCreacion: Date.now(),
  //       status: 'Activo',
  //       url: data.location,
  //       provider: 's3',
  //     };
  //     let result = null;

  //     try {
  //       const response = await fetch(urlC, {
  //         method: 'POST',
  //         headers: {
  //           accept: 'application/json',
  //           'Content-Type': 'application/json',
  //           'X-Cassandra-Token': tokenCasandra,
  //         },
  //         body: JSON.stringify(body),
  //       });

  //       if (response.status === 500) {
  //         console.log('Segundo Intento');
  //         const response = await fetch(urlC, {
  //           method: 'POST',
  //           headers: {
  //             accept: 'application/json',
  //             'Content-Type': 'application/json',
  //             'X-Cassandra-Token': tokenCasandra,
  //           },
  //           body: JSON.stringify(body),
  //         });
  //       }

  //       const responseData = await response.json();
  //     //   console.log("[RESPUESTA]", responseData)
  //     //    const { proyecto, presentacionId } = responseData;
  //     //    const id = `${presentacionId}_${proyecto}`;
  //         const { url } = body;
  //     //    result = { proyecto, presentacionId, id, url };
  //     //    console.log('[RESPONSE CASSANDRA]', responseData);
  //       props.query();

  //       if(props.tipo == "proyecto"){
  //           addPresentacion(responseData.documentId, "visible", props.nombre, url)
  //       }
  //     } catch (error) {
  //       console.error('[ERROR CASSANDRA]', error);
  //       throw error;
  //     } finally {
  //       setCargando(false);
  //       setFileName('');
  //     }

  //     //return result;
  // };

  const crearPresentacion = async (data) => {
    const urlC =
      "https://servicesemail-production.herokuapp.com/api/presentaciones";
    const body = {
      nombrePresentacion: props.nombre,
      fechaCreacion: Date.now(),
      status: "Activo",
      url: data.location,
      provider: "s3",
    };
    let result = null;

    try {
      const response = await fetch(urlC, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.status === 500) {
        console.log("Segundo Intento");
        const response = await fetch(urlC, {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
      }

      const responseData = await response.json();
      //   console.log("[RESPUESTA]", responseData)
      //    const { proyecto, presentacionId } = responseData;
      //    const id = `${presentacionId}_${proyecto}`;
      const { url } = body;
      //    result = { proyecto, presentacionId, id, url };
      //    console.log('[RESPONSE CASSANDRA]', responseData);
      props.query();

      if (props.tipo == "proyecto") {
        addPresentacion(responseData.documentId, "visible", props.nombre, url);
      }
    } catch (error) {
      console.error("[ERROR CASSANDRA]", error);
      throw error;
    } finally {
      setCargando(false);
      setFileName("");
    }

    //return result;
  };

  // const crearPresentacion = async(data) => {

  //   const urlC = `${urlCassandra}collections/presentaciones${user.id.toString()}`
  //     const response = await fetch(urlC, {
  //         method: 'POST',
  //         headers: {
  //             'accept': 'application/json',
  //             'Content-Type': 'application/json',
  //             'X-Cassandra-Token': tokenCasandra
  //         },
  //         body: JSON.stringify({
  //             nombrePresentacion: props.nombre,
  //             fechaCreacion: Date.now(),
  //             status: "Activo",
  //             url: data.location,
  //             provider: "s3",

  //         })
  //     })

  //     if(response.status == 500){
  //         console.log("Segundo Intento")
  //         const urlC = `${urlCassandra}collections/presentaciones${user.id.toString()}`
  //         const response = await fetch(urlC, {
  //         method: 'POST',
  //         headers: {
  //             'accept': 'application/json',
  //             'Content-Type': 'application/json',
  //             'X-Cassandra-Token': tokenCasandra
  //         },
  //         body: JSON.stringify({
  //             nombrePresentacion: props.nombre,
  //             fechaCreacion: Date.now(),
  //             status: "Activo",
  //             url: data.location,
  //             provider: "s3",

  //         })
  //     })

  //     // const data = {
  //     // nombre: props.nombre,
  //     // estatus: "visible",
  //     // cliente: "386",
  //     // novisible: [],
  //     // proyecto: "_zHRqSQejORKDPweZBAtc",
  //     // presentacionId: "e5348b06-ca9c-48fc-ac1d-8ee12ca053bf",
  //     // id: "e5348b06-ca9c-48fc-ac1d-8ee12ca053bf_zHRqSQejORKDPweZBAtc",
  //     // url: "https://nctech-imagenes.s3.amazonaws.com/ES Parsing_ Part One by Slidesgo.pptx",
  //     // fechaCreacion: 1682709970584
  //     //  }
  //     // addPresentacion(data)

  //     props.query()
  //     }
  //     setCargando(false)
  //     setFileName("")
  //     console.log("[RESPONSE CASSANDRA]", response)
  //     return response

  // }

  const putData_presentaciones = (tableName, data) => {
    var params = {
      TableName: tableName,
      Item: data,
    };

    docClient.put(params, function (err, data) {
      if (err) {
        console.log("Error", err);
      } else {
        props.refetchPresentaciones();
      }
    });
  };
  const addPresentacion = async (ids, estatus, nombre, url) => {
    //console.log("paso", paso, "id:", ids + id, "estado:", estatus)
    const presentacionData = {
      id: ids + props.id,
      presentacionId: ids,
      estatus: estatus,
      fechaCreacion: Date.now(),
      proyecto: props.id,
      nombre: nombre,
      url,
      cliente: props.proyecto,
      novisible: [],
    };
    console.log("[ESTO ES LO QUE INSERTARA]", presentacionData);
    await putData_presentaciones("Presentaciones", presentacionData);
  };

  const mutation = useMutation(crearPresentacion, {
    onSuccess: () => {
      queryClient.invalidateQueries("PRESENTACIONES");

      //console.log("Listo")
    },
    onSettled: () => {
      props.query();
      props.cerrar();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleUpload = async (file) => {
    setCargando(true);
    uploadFile(file, config)
      .then(
        (data) => mutation.mutate(data),

        //console.log(data)
      )
      .catch((err) => console.error(err));
  };

  return (
    <Box>
      <label onChange={handleFileInput} htmlFor="filePresentation">
        <input
          name=""
          type="file"
          id="filePresentation"
          hidden
          accept=".ppt, .pptx"
        />
        <Flex my={6} cursor={"pointer"}>
          <Box alignSelf={"center"}>
            <BiSlideshow size={"20px"} color={"gray.500"} />
          </Box>
          <Box
            alignContent={"center"}
            ml={2}
            fontSize={"sm"}
            fontWeight={"medium"}
            color={"gray.500"}
            fontFamily={"system-ui, sans-serif"}
          >
            Clic aquí para seleccionar tu archivo
          </Box>
          <Box
            alignContent={"center"}
            ml={2}
            fontSize={"sm"}
            fontWeight={"medium"}
            color={"gray.800"}
            fontFamily={"system-ui, sans-serif"}
          >
            {fileName}
          </Box>
        </Flex>
      </label>
      {fileName != null ? (
        <Button
          isLoading={cargando}
          fontSize={"sm"}
          fontWeight={"medium"}
          color={"gray.800"}
          fontFamily={"system-ui, sans-serif"}
          bg={"orange"}
          variant={"outline"}
          onClick={() => handleUpload(selectedFile)}
        >
          {" "}
          Subir presentación{" "}
        </Button>
      ) : (
        <Button
          fontSize={"sm"}
          fontWeight={"medium"}
          color={"gray.800"}
          fontFamily={"system-ui, sans-serif"}
          bg={"orange"}
          variant={"outline"}
          disabled
        >
          {" "}
          Subir presentación{" "}
        </Button>
      )}
    </Box>
  );
};

export default UploadImageToS3WithReactS3;
