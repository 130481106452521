import React, { createRef, useContext, useEffect, useState } from 'react';
import {
    Input, VStack, Toast, SimpleGrid, LinkOverlay, HStack, Image, Stack, useToast, Button,
    Text, Heading, Container, Box, Center, Collapse, Fade, ScaleFade, Slide, SlideFade,
    useDisclosure, Flex, Spacer, Link, LinkBox,  Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
} from "@chakra-ui/react"
import useUser from '../../context/useUser';
import { useQuery, useMutation, useQueryClient} from 'react-query'
import axios from 'axios';
const New = () => {
    const queryClient = useQueryClient();
    const { user, setUser } = useUser()
    const [razonSocial, setRazonSocial] = React.useState('')
    const [nombre, setNombre] = React.useState('')
    const [direccion, setDireccion] = React.useState('')
    const [nombreContacto, setNombreContacto] = React.useState('')
    const [correo, setCorreo] = React.useState('')
    const [telefono, setTelefono] = React.useState('')
    const [urlCassandra, setUrlCassandra] = useState(process.env.REACT_APP_URL_CASSANDRA)
    const [tokenCasandra, setTokenCassandra] = useState(process.env.REACT_APP_TOKEN_CASSANDRA)
    const crear = async() =>{
        const urlC = `${urlCassandra}collections/clientes`
            const response = await fetch(urlC, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Cassandra-Token': tokenCasandra
                },
                body: JSON.stringify({
                    razonSocial,
                    nombre,
                    direccion,
                    nombreContacto,
                    correo,
                    telefono
                })
            })
            return response
    }
    const mutation = useMutation(crear, {
        onSuccess: () => {
            //queryClient.invalidateQueries('settings')
            window.location.href = '/admin/clientes'
        },
        onError: (error) => {
            console.log(error)
        }
    })
    function handleonclick() {
        mutation.mutate()
    }
    return (
        <Box >
           
            <Box  w={'100%'}  bg={'white'} p={2}>
                <Breadcrumb ml={'10'} mt={5}>
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/admin">Home</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/admin/clientes">Clientes</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink href="#">Nuevo Cliente</BreadcrumbLink>
                    </BreadcrumbItem>
                    
                </Breadcrumb>

                    <Center>
                    <VStack bg={'white'}>
                        <Box>
                            <Text fontSize={{lg:'33px', base: '33px', '2xl': '33px'}} fontWeight={'bold'} color={'gray.700'} align={{base:'center'}}>Nuevo Cliente </Text>
                        </Box>
                        <Box>
                            <Text fontSize={{lg:'13px', base: '15px', '2xl': '13px'}} fontWeight={'normal'} color={'gray.700'}>Nombre de empresa</Text>
                            <Input type={'text'} placeholder={'Eje. ACME SA de CV'}  onChange={(e) => setRazonSocial(e.target.value)}></Input>
                        </Box>
                        <Box>
                            <Text fontSize={{lg:'13px', base: '15px', '2xl': '13px'}} fontWeight={'normal'} color={'gray.700'}>Nombre comercial</Text>
                            <Input type={'text'} placeholder={'Eje. ACME'} onChange={(e) => setNombre(e.target.value)}></Input>
                        </Box>
                        <Box>
                            <Text fontSize={{lg:'13px', base: '15px', '2xl': '13px'}} fontWeight={'normal'} color={'gray.700'}>Dirección completa</Text>
                            <Input type={'address'} placeholder={'Eje. Dirección completa'} onChange={(e) => setDireccion(e.target.value)}></Input>
                        </Box>
                        <Box>
                            <Text fontSize={{lg:'13px', base: '15px', '2xl': '13px'}} fontWeight={'normal'} color={'gray.700'}>Nombre del contacto</Text>
                            <Input type={'text'} placeholder={'Eje. José Rivas'} onChange={(e) => setNombreContacto(e.target.value)}></Input>
                        </Box>
                        <Box>
                            <Text fontSize={{lg:'13px', base: '15px', '2xl': '13px'}} fontWeight={'normal'} color={'gray.700'}>Correo del contacto</Text>
                            <Input type={'email'} placeholder={'Eje. example@email.com'} onChange={(e) => setCorreo(e.target.value)}></Input>
                        </Box>
                        <Box>
                            <Text fontSize={{lg:'13px', base: '15px', '2xl': '13px'}} fontWeight={'normal'} color={'gray.700'}>Teléfono del contacto</Text>
                            <Input type={'tel'} placeholder={'Eje. 55-5555-5555'} onChange={(e) => setTelefono(e.target.value)}></Input>
                        </Box>
                        <Box>
                            <Button variant={'outline'} colorScheme={'orange'} onClick={handleonclick}>Guardar</Button>
                        </Box>
                    </VStack>
                    </Center>
                   
                
            </Box>
            

        </Box>
    );
};

export default New;