import React, { useState } from "react";
import { uploadFile } from "react-s3";
import {
  Spinner,
  Input,
  VStack,
  Toast,
  SimpleGrid,
  LinkOverlay,
  HStack,
  Image,
  Stack,
  useToast,
  Button,
  Text,
  Heading,
  Container,
  Box,
  Center,
  Collapse,
  Fade,
  ScaleFade,
  Slide,
  SlideFade,
  useDisclosure,
  Flex,
  Spacer,
  Link,
  LinkBox,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Avatar,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { BiSlideshow } from "react-icons/bi";
import props from "prop-types";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useUser from "./context/useUser";

const S3_BUCKET = "nctech-videos";
const REGION = "us-east-1";
const ACCESS_KEY = "AKIARVGY33F62XNKJJK3";
const SECRET_ACCESS_KEY = "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";

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

const UploadVideosS3 = (props) => {
  const [urlCassandra, setUrlCassandra] = useState(
    process.env.REACT_APP_URL_CASSANDRA,
  );
  const [tokenCasandra, setTokenCassandra] = useState(
    process.env.REACT_APP_TOKEN_CASSANDRA,
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const { user, setUser } = useUser();
  const queryClient = useQueryClient();
  const crearVideo = async (data) => {
    //const urlC = `${urlCassandra}collections/videos${user.id.toString()}`
    const urlC = `https://servicesemail-production.herokuapp.com/api/videos/${user.id.toString()}`;
    const response = await fetch(urlC, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nombreVideo: props.nombre,
        fechaCreacion: Date.now(),
        status: "Activo",
        url: data.location,
      }),
    });

    if (response.status == 500) {
      console.log("Segundo Intento");
      const urlC = `https://servicesemail-production.herokuapp.com/api/videos/${user.id.toString()}`;
      const response = await fetch(urlC, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nombreVideo: props.nombre,
          fechaCreacion: Date.now(),
          status: "Activo",
          url: data.location,
        }),
      });
      props.query();
    }

    console.log(response);
    return response;
  };
  const mutation = useMutation(crearVideo, {
    onSuccess: () => {
      queryClient.invalidateQueries("videos");

      //console.log("Listo")
    },
    onSettled: () => {
      props.query();
      props.cerrar();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleUpload = async (file) => {
    uploadFile(file, config)
      .then(
        (data) => mutation.mutate(data),

        //console.log(data)
      )
      .catch((err) => console.error(err));
  };

  return (
    <Box>
      <label onChange={handleFileInput} htmlFor="fileVideos">
        <input name="" type="file" id="fileVideos" hidden />
        <Flex my={6} cursor={"pointer"}>
          <Box alignSelf={"center"}>
            <BiSlideshow size={"20px"} color={"gray.500"} />
          </Box>
          <Box
            alignContent={"center"}
            ml={2}
            fontSize={"sm"}
            fontWeight={"medium"}
            color={"gray.500"}
            fontFamily={"system-ui, sans-serif"}
          >
            Clic aquí para seleccionar tu archivo
          </Box>
          <Box
            alignContent={"center"}
            ml={2}
            fontSize={"sm"}
            fontWeight={"medium"}
            color={"gray.800"}
            fontFamily={"system-ui, sans-serif"}
          >
            {fileName}
          </Box>
        </Flex>
      </label>

      <Button
        fontSize={"sm"}
        fontWeight={"medium"}
        color={"gray.800"}
        fontFamily={"system-ui, sans-serif"}
        bg={"orange"}
        variant={"outline"}
        onClick={() => handleUpload(selectedFile)}
      >
        {" "}
        Subir video
      </Button>
    </Box>
  );
};

export default UploadVideosS3;
