import React, { useContext, useEffect, useState } from "react";
import {
  Spinner,
  Input,
  Button,
  Box,
  Center,
  Flex,
  Spacer,
  Link,
  Divider,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { HiPlus } from "react-icons/hi";
import Header from "../../Header";
import useUser from "../../context/useUser";
import useToken from "../../useToken";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import MenuAdmin from "../Utilidades/MenuAdmin";
import { Space, Table } from "antd";
import "antd/dist/antd.css";
import { GrFormDown } from "react-icons/gr";
import { connect } from "react-redux";
import { get_vendedor } from "../../../actions";

const _Vendedores = (props) => {
  const { user, setUser } = useUser();
  const queryClient = useQueryClient();
  const {
    isOpen: isProyectoOpen,
    onOpen: onOpenProyectoModal,
    onClose: onCloseProyectoModal,
  } = useDisclosure();

  const {
    isOpen: isProyectoOpenEdit,
    onOpen: onOpenProyectoModalEdit,
    onClose: onCloseProyectoModalEdit,
  } = useDisclosure();
  const { Column, ColumnGroup } = Table;
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [idVendedor, setIdVendedor] = useState();
  const [datos, setDatos] = useState([]);
  const { token, setToken } = useToken();
  const [urlNotificacionesRoot, setUrlNotificacionesRoot] = useState(
    process.env.REACT_APP_URL_NOTIFICACIONES_ROOT,
  );
  const [blurOn, setBlurOn] = useState(false);
  const fuente =
    "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji";
  const { usuario } = props || {};

  const addGerente = async () => {
    const urlC = `${urlNotificacionesRoot}/api/setVendedores`;
    const response = await fetch(urlC, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        nombre,
        correo,
        token: token,
      }),
    });
    handlerClickCloseModal();
    sendEmail(nombre, correo);
    return response;
  };

  const sendEmail = async (nombre, correo) => {
    const url = `https://servicesemail-production.herokuapp.com/api/newuser`;
    //const  url = `http://localhost:8080/api/newuser`
    const body = {
      templateId: "d-612962b334954e459cb7f1b11cf59f93", //Cambio de template a producción
      to: correo,
      subject: "asunto",
      usuario: nombre,
    };
    const response = await fetch(url, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
    if (response.status == 201) {
      console.log("[MENSAJE ENVIADO]");
    }
  };

  const deleteGerente = async (id) => {
    const urlC = `${urlNotificacionesRoot}/api/deleteVendedores`;
    const response = await fetch(urlC, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        id,
      }),
    });
    // console.log(response)
    // onCloseProyectoModal()
    handlerClickCloseModal();
    refetch();
    return response;
  };
  const EditarGerente = async () => {
    const urlC = `${urlNotificacionesRoot}/api/updateVendedores/${idVendedor}`;
    const response = await fetch(urlC, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        nombre: nombre,
        correo: correo,
      }),
    });
    // console.log(response)
    // onCloseProyectoModal()
    handlerClickCloseModalEdit();
    refetch();
    return response;
  };
  async function fetchVendedores() {
    //const urlC = `${urlStrapi}/clientes?nombre_contains=${b}&vendedor=${user.id.toString()}`
    const urlC = `${urlNotificacionesRoot}/api/getVendedores`;
    const ventasData = await axios.get(urlC, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return ventasData;
  }
  const mutation = useMutation(addGerente, {
    onSuccess: () => {
      queryClient.invalidateQueries("VENDEDORES");
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const mutationA = useMutation(deleteGerente, {
    onSuccess: () => {
      queryClient.invalidateQueries("VENDEDORES");
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutationEditar = useMutation(EditarGerente, {
    onSuccess: () => {
      queryClient.invalidateQueries("VENDEDORES");
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const handleNombre = (e) => {
    setNombre(e.target.value);
  };
  const handleCorreo = (e) => {
    setCorreo(e.target.value);
  };
  const handleAdd = () => {
    mutation.mutate();
  };
  const handleEliminar = (id) => {
    mutationA.mutate(id);
  };
  const handleEditar = () => {
    mutationEditar.mutate();
  };
  const BlurOn = (blurOn) => {
    setBlurOn(blurOn);
  };
  const { isLoading, refetch } = useQuery("VENDEDORES", fetchVendedores, {
    onSuccess: (data) => {
      //console.log("data:", data)
      setDatos(data.data);
    },
  });
  if (isLoading) {
    return (
      <Center mt={"10%"}>
        <Spinner color={"green.700"} />
      </Center>
    );
  }
  const handlerClickModal = () => {
    onOpenProyectoModal();

    // BlurOn(true)
  };
  const handlerClickModalEdit = async (vendedor, nombre, correo) => {
    setNombre(nombre);
    setCorreo(correo);
    setIdVendedor(vendedor);
    setTimeout(() => {
      onOpenProyectoModalEdit();
    }, 500);
  };
  const handlerClickCloseModal = () => {
    onCloseProyectoModal();
    refetch();
    //BlurOn(false)
  };
  const handlerClickCloseModalEdit = () => {
    onCloseProyectoModalEdit();
    refetch();
    //BlurOn(false)
  };

  return (
    <>
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isProyectoOpen}
        onClose={handlerClickCloseModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontFamily={fuente}
            fontSize={"15pt"}
            fontWeight={"medium"}
          >
            Nuevo Vendedor
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box
              color={"gray.500"}
              fontSize={"sm"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Nombre del Vendedor
            </Box>
            <Input
              value={nombre}
              onChange={(e) => handleNombre(e)}
              type={"text"}
              placeholder={"Escribe algo aquí..."}
              color={"gray.500"}
              fontSize={"sm"}
              fontWeight={"medium"}
              fontFamily={fuente}
            />
            <Box
              color={"gray.500"}
              fontSize={"sm"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Correo electrónico
            </Box>
            <Input
              value={correo}
              onChange={(e) => handleCorreo(e)}
              type={"email"}
              placeholder={"Eje. example@example.com..."}
              color={"gray.500"}
              fontSize={"sm"}
              fontWeight={"medium"}
              fontFamily={fuente}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="orange"
              variant={"outline"}
              fontFamily={fuente}
              mr={3}
              onClick={handleAdd}
            >
              Crear
            </Button>
            <Button onClick={handlerClickCloseModal} fontFamily={fuente}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isProyectoOpenEdit}
        onClose={handlerClickCloseModalEdit}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontFamily={fuente}
            fontSize={"15pt"}
            fontWeight={"medium"}
          >
            Editar Vendedor
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box
              color={"gray.500"}
              fontSize={"sm"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Nombre del Vendedor
            </Box>
            <Input
              value={nombre}
              onChange={(e) => handleNombre(e)}
              type={"text"}
              placeholder={"Escribe algo aquí..."}
              color={"black"}
              fontSize={"sm"}
              fontWeight={"medium"}
              fontFamily={fuente}
            />
            <Box
              color={"gray.500"}
              fontSize={"sm"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Correo electrónico
            </Box>
            <Input
              value={correo}
              onChange={(e) => handleCorreo(e)}
              type={"email"}
              placeholder={"Eje. example@example.com..."}
              color={"black"}
              fontSize={"sm"}
              fontWeight={"medium"}
              fontFamily={fuente}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="orange"
              variant={"outline"}
              fontFamily={fuente}
              mr={3}
              onClick={handleEditar}
            >
              Guardar
            </Button>
            <Button onClick={handlerClickCloseModalEdit} fontFamily={fuente}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex direction={{ base: "column", lg: "row" }} bg={"pink"} h={"100vh"}>
        <MenuAdmin />
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>

            <Box bg={"gray.200"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
              <Flex direction={"column"}>
                <Box bg={"white"} h={{ base: "25vh", lg: "25vh" }}>
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"pink.400"}
                      w={{ base: "100%", lg: "60%" }}
                      h={{ base: "25vh", lg: "25vh" }}
                    >
                      <Flex direction={"column"}>
                        <Box bg={"gray.300"} h={"12.5vh"}>
                          <Flex direction={"row"}>
                            <Box bg={"white"} w={"70%"} h={"12.5vh"}>
                              <Flex direction={"column"}>
                                <Box bg={"white"} w={"100%"} h={"auto"}>
                                  <Box
                                    alignSelf={"end"}
                                    fontSize={{
                                      lg: "17px",
                                      base: "23px",
                                      "2xl": "33px",
                                    }}
                                    fontWeight={"semibold"}
                                    color={"gray.700"}
                                    fontFamily={fuente}
                                    ml={{ lg: "0", base: "5" }}
                                    mt={{ lg: "8", base: "5" }}
                                  >
                                    ¡Hola! Administrador
                                  </Box>
                                </Box>
                                <Box bg={"white"} w={"100%"} h={"auto"}>
                                  <Box
                                    fontFamily={fuente}
                                    fontSize={{
                                      lg: "13px",
                                      base: "13px",
                                      "2xl": "15px",
                                    }}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    ml={{ lg: "0", base: "5" }}
                                    mb={{ lg: "10", base: "0" }}
                                  >
                                    <Link href="#">
                                      {" "}
                                      Listado de Vendedores{" "}
                                    </Link>
                                  </Box>
                                </Box>
                              </Flex>
                            </Box>
                            <Box bg={"white"} w={"30%"} h={"12.5vh"}>
                              <Flex direction={"column"} justifyContent={"end"}>
                                <Tooltip
                                  label="Crear nuevo vendedor"
                                  aria-label="A tooltip"
                                  placement="right"
                                  borderRadius={"sm"}
                                >
                                  <Box
                                    bg={"white"}
                                    w={"100%"}
                                    h={"4.16vh"}
                                    align={"end"}
                                    mt={8}
                                    pr={{ base: 10, lg: 0 }}
                                  >
                                    <Menu>
                                      <MenuButton
                                        px={4}
                                        py={2}
                                        transition="all 0.2s"
                                        borderRadius="md"
                                        borderWidth="1px"
                                        _hover={{
                                          bg: "gray.400",
                                          color: "white",
                                        }}
                                        _expanded={{
                                          bg: "gray.500",
                                          color: "white",
                                        }}
                                        _focus={{ boxShadow: "outline" }}
                                        as={Button}
                                        onClick={handlerClickModal}
                                      >
                                        <HiPlus />
                                      </MenuButton>
                                    </Menu>
                                  </Box>
                                </Tooltip>
                                <Box bg={"white"} w={"100%"} h={"4.16vh"}></Box>
                                <Box bg={"white"} w={"100%"} h={"4.16vh"}></Box>
                              </Flex>
                            </Box>
                          </Flex>
                        </Box>
                        <Box bg={"white"} h={"12.5vh"}>
                          <Center></Center>
                        </Box>
                        <Divider />
                      </Flex>
                    </Box>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
                <Box
                  bg={"white"}
                  h={{ base: "65vh", lg: "65vh" }}
                  pl={{ base: 0, lg: 0 }}
                  pr={{ base: 0, lg: 0 }}
                >
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"white"}
                      w={{ base: "0", lg: "90%" }}
                      h={{ base: "0", lg: "65vh" }}
                    >
                      <Box bg={"white"} w={"100%"} mt={5}>
                        <Center>
                          <Box
                            rounded={"lg"}
                            borderStyle={"solid"}
                            borderColor={"gray.100"}
                            borderWidth={"1px"}
                            overflow={"hidden"}
                          >
                            <Table
                              dataSource={datos}
                              bordered
                              fontFamily={fuente}
                              pagination={{
                                pageSize: 5,
                              }}
                              size="small"
                            >
                              <Column
                                title="Nombre"
                                dataIndex="username"
                                key="username"
                                fontFamily={fuente}
                              />
                              <Column
                                title="Email"
                                dataIndex="email"
                                key="email"
                                fontFamily={fuente}
                              />
                              <Column
                                title="Fecha de Creación"
                                dataIndex="created_at"
                                key="created_at"
                                fontFamily={fuente}
                              />
                              <Column
                                title="Acción"
                                fontFamily={fuente}
                                dataIndex="acciones"
                                key="acciones"
                                render={(text, record, index) => (
                                  <Space size="middle">
                                    <Menu>
                                      <MenuButton
                                        px={4}
                                        py={2}
                                        transition="all 0.2s"
                                        borderRadius="md"
                                        borderWidth="1px"
                                        _hover={{
                                          bg: "gray.400",
                                          color: "white",
                                        }}
                                        _expanded={{
                                          bg: "gray.500",
                                          color: "white",
                                        }}
                                        _focus={{ boxShadow: "outline" }}
                                        as={Button}
                                      >
                                        <GrFormDown />
                                      </MenuButton>
                                      <MenuList>
                                        <MenuItem
                                          onClick={() =>
                                            handlerClickModalEdit(
                                              record.id,
                                              record.username,
                                              record.email,
                                            )
                                          }
                                          fontFamily={fuente}
                                        >
                                          Editar
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem
                                          onClick={() =>
                                            sendEmail(
                                              record.username,
                                              record.email,
                                            )
                                          }
                                          fontFamily={fuente}
                                        >
                                          Reenviar Invitación
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem
                                          onClick={() =>
                                            handleEliminar(record.id)
                                          }
                                          fontFamily={fuente}
                                        >
                                          Eliminar
                                        </MenuItem>
                                      </MenuList>
                                    </Menu>
                                  </Space>
                                )}
                              />
                            </Table>
                          </Box>
                        </Center>
                      </Box>
                    </Box>
                    {/* Tabla para movil*/}
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

//REDUX
const mapStateProps = (state) => ({
  usuario: state.usuarioReducer.usuario,
});
const Vendedores = connect(mapStateProps, { get_vendedor })(_Vendedores);
export default Vendedores;
