import React, { useEffect, useState } from "react";
import { Box, Flex, Divider, Select } from "@chakra-ui/react";
import Header from "../Header";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import MenuVendedor from "./Utilidades/MenuGerente";
import { BsFilter } from "react-icons/bs";
import Moment from "react-moment";
import moment, * as moments from "moment";
import "moment-timezone";
import { Badge, Table } from "antd";
import * as AWS from "aws-sdk";
import { RenderIf } from "../../hooks/renderIF";
import { Line, Column, RingProgress } from "@ant-design/plots";
import { render } from "@testing-library/react";
AWS.config.update({
  region: "us-east-1",
  secretAccessKey: "3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi",
  accessKeyId: "AKIARVGY33F62XNKJJK3",
});
//AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' });
const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: "2022-01-01" });
const Graficas = () => {
  const [cliente] = useState();
  const [datos] = useState([]);
  const [urlStrapi] = useState(process.env.REACT_APP_URL_STRAPI);
  const [urlCassandra] = useState(process.env.REACT_APP_URL_CASSANDRA);
  const [tokenCasandra] = useState(process.env.REACT_APP_TOKEN_CASSANDRA);
  const [proyectos] = useState([]);
  const [proyectosCount, setProyectosCount] = useState(0);
  const [proyectosCountActivos, setProyectosCountActivos] = useState(0);
  const [proyectosPorcentajes, setProyectosPorcentajes] = useState(0);
  const [proyectosCountCompartido, setProyectosCountCompartido] = useState(0);
  const [proyectosPorcentajesCompartido, setProyectosPorcentajesCompartido] =
    useState(0);
  const [proyectosRendimiento, setProyectosRendimiento] = useState("");
  const [dias, setDias] = useState(0);
  const [setNotas] = useState("");
  const [setRowActual] = useState();
  const [setRowActualVendedores] = useState();
  const [datosProyectos, setDatosProyectos] = useState();
  const [datosProyectosVendedores, setDatosProyectosVendedores] = useState([]);
  const [datosProyectosLineas, setDatosProyectosLineas] = useState([]);
  const [setExpandedRowKeys] = useState([]);
  const [setExpandedRowKeysVendedores] = useState([]);
  const [bandera, setBandera] = useState();
  const [vendedores, setVendedores] = useState(false);
  const [contactos, setContactos] = useState(false);
  const [titulo, setTitulo] = useState();
  const [listaVendedores, setListaVendedores] = useState([]);
  const [datosContactos, setDatosContactos] = useState();
  const [fuente] = useState(
    "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
  );
  //const { Column, ColumnGroup } = Table;
  const filtro = (query) => {
    //console.log(query);
    if (query) {
      try {
        var params = {
          TableName: "Proyectos",
          FilterExpression: "#vendedor = :vendedor",
          ExpressionAttributeNames: {
            "#vendedor": "vendedor",
          },
          ExpressionAttributeValues: {
            ":vendedor": query,
          },
        };
        docClient.scan(params, function (err, data) {
          if (err) {
            console.error(
              "Unable to query. Error:",
              JSON.stringify(err, null, 2),
            );
          } else {
            setProyectosCount(data.Count);
            fetchProyectosCountActivosFiltroVendedor(data.Count, query);
            fetchProyectosCountCompartidosFiltroVendedor(data.Count, query);
            fetchProyectosRendimientoFiltros(query);
            ciclo(data.Items, data.Count);
            //fetchProyectosRendimiento()
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      refetch();
    }
  };
  const filtroLineas = (query) => {
    //console.log(query);
    if (query) {
      try {
        var params = {
          TableName: "Proyectos",
          FilterExpression: "#lineaNegocio = :lineaNegocio",
          ExpressionAttributeNames: {
            "#lineaNegocio": "lineaNegocio",
          },
          ExpressionAttributeValues: {
            ":lineaNegocio": query,
          },
        };
        docClient.scan(params, function (err, data) {
          if (err) {
            console.error(
              "Unable to query. Error:",
              JSON.stringify(err, null, 2),
            );
          } else {
            //console.log(data.Items,data.Count)
            setProyectosCount(data.Count);
            fetchProyectosCountActivosFiltro(data.Count, query);
            fetchProyectosCountCompartidosFiltro(data.Count, query);

            ciclo(data.Items, data.Count);
            fetchProyectosRendimiento();
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      refetch();
    }
  };
  async function fetchProyectosCountActivosFiltro(totalProyectos, query) {
    //console.log(cliente)
    try {
      var params = {
        TableName: "Proyectos",
        FilterExpression:
          "#estatus = :estatus and #lineaNegocio = :lineaNegocio",
        ExpressionAttributeNames: {
          "#estatus": "estatus",
          "#lineaNegocio": "lineaNegocio",
        },
        ExpressionAttributeValues: {
          ":estatus": "Activo",
          ":lineaNegocio": query,
        },
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          setProyectosCountActivos(data.Count);
          porcentaje(totalProyectos, data.Count);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchProyectosCountCompartidosFiltro(totalProyectos, query) {
    //console.log(cliente)
    try {
      var params = {
        TableName: "Proyectos",
        FilterExpression:
          "#estatus = :estatus and #lineaNegocio = :lineaNegocio",
        ExpressionAttributeNames: {
          "#estatus": "estatus",
          "#lineaNegocio": "lineaNegocio",
        },
        ExpressionAttributeValues: {
          ":estatus": "Compartido",
          ":lineaNegocio": query,
        },
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          setProyectosCountCompartido(data.Count);
          porcentajeCompartido(totalProyectos, data.Count);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchProyectosRendimientoFiltros(query) {
    try {
      var params = {
        TableName: "Proyectos",
        FilterExpression: "#vendedor = :vendedor",
        ExpressionAttributeNames: {
          "#vendedor": "vendedor",
        },
        ExpressionAttributeValues: {
          ":vendedor": query,
        },
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          let map = data.Items.reduce((prev, next) => {
            //console.log("resultado", next)
            if (next.estatus in prev) {
              prev[next.estatus].suma += next.suma;
            } else {
              prev[next.estatus] = next;
            }
            return prev;
          }, {});

          let result = Object.keys(map).map((estatus) => map[estatus]);

          // console.log("resultado", result)

          const rendimiento = result.map((o) => {
            return {
              estatus: o.estatus,
              total: o.suma,
              vendedor: o.vendedorName,
            };
          });

          setProyectosRendimiento(rendimiento);
          console.log("rendimiento", rendimiento);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchProyectosCountActivosFiltroVendedor(
    totalProyectos,
    query,
  ) {
    //console.log(cliente)
    try {
      var params = {
        TableName: "Proyectos",
        FilterExpression: "#estatus = :estatus and #vendedor = :vendedor",
        ExpressionAttributeNames: {
          "#estatus": "estatus",
          "#vendedor": "vendedor",
        },
        ExpressionAttributeValues: {
          ":estatus": "Activo",
          ":vendedor": query,
        },
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          setProyectosCountActivos(data.Count);
          porcentaje(totalProyectos, data.Count);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchProyectosCountCompartidosFiltroVendedor(
    totalProyectos,
    query,
  ) {
    //console.log(cliente)
    try {
      var params = {
        TableName: "Proyectos",
        FilterExpression: "#estatus = :estatus and #vendedor = :vendedor",
        ExpressionAttributeNames: {
          "#estatus": "estatus",
          "#vendedor": "vendedor",
        },
        ExpressionAttributeValues: {
          ":estatus": "Compartido",
          ":vendedor": query,
        },
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          setProyectosCountCompartido(data.Count);
          porcentajeCompartido(totalProyectos, data.Count);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchProyectosCount() {
    // console.log(cliente)
    try {
      var params = {
        TableName: "Proyectos",
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          setProyectosCount(data.Count);
          fetchProyectosCountActivos(data.Count);
          fetchProyectosCountCompartidos(data.Count);
          ciclo(data.Items, data.Count);
          fetchProyectosRendimiento();
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchProyectosRendimiento() {
    try {
      var params = {
        TableName: "Proyectos",
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          const result = [
            ...data.Items.reduce((r, o) => {
              const key = o.estatus + "-" + o.vendedor;

              const item =
                r.get(key) ||
                Object.assign({}, o, {
                  suma: 0,
                });

              item.suma += o.suma;
              return r.set(key, item);
            }, new Map()).values(),
          ];
          const rendimiento = result.map((o) => {
            return {
              estatus: o.estatus,
              total: o.suma,
              vendedor: o.vendedorName,
            };
          });

          setProyectosRendimiento(rendimiento);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchProyectosCountActivos(totalProyectos) {
    //console.log(cliente)
    try {
      var params = {
        TableName: "Proyectos",
        FilterExpression: "#estatus = :estatus",
        ExpressionAttributeNames: {
          "#estatus": "estatus",
        },
        ExpressionAttributeValues: {
          ":estatus": "Activo",
        },
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          setProyectosCountActivos(data.Count);
          porcentaje(totalProyectos, data.Count);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchProyectosCountCompartidos(totalProyectos) {
    //console.log(cliente)
    try {
      var params = {
        TableName: "Proyectos",
        FilterExpression: "#estatus = :estatus",
        ExpressionAttributeNames: {
          "#estatus": "estatus",
        },
        ExpressionAttributeValues: {
          ":estatus": "Compartido",
        },
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          setProyectosCountCompartido(data.Count);
          porcentajeCompartido(totalProyectos, data.Count);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  const porcentaje = (totalProyectos, proyectosActivos) => {
    setProyectosPorcentajes(proyectosActivos / totalProyectos);
  };
  const porcentajeCompartido = (totalProyectos, proyectosActivos) => {
    setProyectosPorcentajesCompartido(proyectosActivos / totalProyectos);
  };
  const ciclo = (data, totalProyectos) => {
    const dias = data.map((o) => {
      let sum = 0;
      let now = moment(new Date()); //todays date
      let end = moment(o.fechaCreacion); // another date
      let duration = moment.duration(now.diff(end));
      let days = duration.asDays();
      //console.log("Dias!" , days)
      return (sum += days);
    });
    let sum = 0;

    dias.forEach((value) => {
      sum += value;
    });
    setDias(sum / totalProyectos);
    //console.log();
  };
  const fechClientes = async () => {
    try {
      const proyecto = 1;
      const urlC = `${urlStrapi}/clientes`;
      const clientes = await axios.get(urlC, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      //console.log("datos", clientes.data)
      const result = clientes.data;
      const res = result.map((o) => {
        //console.log("datos", o)
        return {
          id: o.id,
          CARDID: o.CARDID,
          razonSocial: o.razonSocial,
          nombreContacto: o.nombreContacto,
          correo: o.correo,
          created_at: o.created_at,
        };
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  const fetchProyectosPorVendedores = () => {
    //console.log(vendedor)
    try {
      var params = {
        TableName: "Proyectos",
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          //console.log("Query succeeded--PROYECTOS.");
          //console.log(data.Items);
          let map = data.Items.reduce((prev, next) => {
            //console.log("resultado", next)
            if (next.vendedorName in prev) {
              prev[next.vendedorName].suma += next.suma;
            } else {
              prev[next.vendedorName] = next;
            }
            return prev;
          }, {});

          let result = Object.keys(map).map(
            (vendedorName) => map[vendedorName],
          );
          // console.log(result);

          const vendedores = result.map((o) => {
            return {
              vendedorId: o.vendedor,
              vendedorName: o.vendedorName,
            };
          });

          setDatosProyectosVendedores(vendedores);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const fetchProyectosPorLineas = () => {
    //console.log(vendedor)
    try {
      var params = {
        TableName: "Proyectos",
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          //console.log("Query succeeded--PROYECTOS.");
          //console.log(data.Items);
          let map = data.Items.reduce((prev, next) => {
            //console.log("resultado", next)
            if (next.lineaNegocioNombre in prev) {
              prev[next.lineaNegocioNombre].suma += next.suma;
            } else {
              prev[next.lineaNegocioNombre] = next;
            }
            return prev;
          }, {});

          let result = Object.keys(map).map(
            (lineaNegocioNombre) => map[lineaNegocioNombre],
          );
          // console.log(result)
          const Lineas = result.map((o) => {
            return {
              id: o.lineaNegocio,
              lineaNegocioNombre: o.lineaNegocioNombre,
            };
          });

          setDatosProyectosLineas(Lineas);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  //#region carga inicial
  useEffect(() => {
    fetchProyectosCount();
    fetchProyectosPorVendedores();
    fetchProyectosPorLineas();
  }, [proyectos]);
  //#endregion
  //#region busquedas iniciales
  useQuery("PROYECTOS", fechClientes);
  const { refetch } = useQuery("CONTEO", fetchProyectosCount);
  //#endregion
  const arr = proyectosRendimiento;
  const config = {
    data: arr,
    xField: "estatus",
    yField: "total",
    seriesField: "vendedor",
    legend: {
      position: "bottom",
    },
    legend: {
      position: "top",
    },
    smooth: true,
    // @TODO 后续会换一种动画方式
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };
  const configPercentage = {
    height: 100,
    width: 100,
    autoFit: false,
    percent: proyectosPorcentajes,
    color: ["#57cc99", "#E8EDF3"],
  };
  const configPercentage_A = {
    height: 100,
    width: 100,
    autoFit: false,
    percent: proyectosPorcentajesCompartido,
    color: ["#57cc99", "#E8EDF3"],
  };

  return (
    <>
      <Flex direction={{ base: "column", lg: "row" }} bg={"white"} h={"100vh"}>
        <MenuVendedor />
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"white"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>
            <Box bg={"white"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
              <Flex direction={"column"}>
                <Box bg={"white"}>
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "20%" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box bg={"white"} w={{ base: "100%", lg: "60%" }}>
                      <Flex direction={"column"}>
                        <Box bg={"white"}>
                          <Flex direction={"row"}>
                            <Box bg={"white"} w={"70%"}>
                              <Flex direction={"column"}>
                                <Box bg={"white"} w={"100%"}>
                                  <Box
                                    alignSelf={"end"}
                                    fontSize={{
                                      lg: "17px",
                                      base: "2.0rem",
                                      "2xl": "33px",
                                    }}
                                    fontWeight={"bold"}
                                    color={"gray.700"}
                                    fontFamily={fuente}
                                    ml={{ lg: "0", base: "5" }}
                                    mt={{ lg: "8", base: "5" }}
                                  >
                                    Gráfica de Análisis
                                  </Box>
                                </Box>
                                <Box bg={"white"} w={"100%"}>
                                  <Box
                                    fontSize={{
                                      lg: "sm",
                                      base: "sm",
                                      "2xl": "sm",
                                    }}
                                    fontFamily={fuente}
                                    fontWeight={"medium"}
                                    color={"blue.500"}
                                    ml={{ lg: "0", base: "5" }}
                                    mb={{ lg: "10", base: "0" }}
                                  ></Box>
                                </Box>
                              </Flex>
                            </Box>
                            <Box bg={"white"} w={"30%"}>
                              <Flex direction={"column"} justifyContent={"end"}>
                                <Box
                                  bg={"white"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                  align={"end"}
                                  mt={8}
                                  pr={{ base: 10, lg: 0 }}
                                ></Box>
                                <Box bg={"white"} w={"100%"} h={"4.16vh"}></Box>
                                <Box bg={"white"} w={"100%"} h={"4.16vh"}></Box>
                              </Flex>
                            </Box>
                          </Flex>
                        </Box>
                        <Divider />
                      </Flex>
                    </Box>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "20%" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
                <Box
                  bg={"white"}
                  h={{ base: "65vh", lg: "65vh" }}
                  pl={{ base: 0, lg: 0 }}
                  pr={{ base: 0, lg: 0 }}
                >
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"white"}
                      w={{ base: "0", lg: "100%" }}
                      h={{ base: "0", lg: "65vh" }}
                    >
                      <Flex direction={"row"} alignSelf={"center"}>
                        <Flex direction={"row"} w={"40%"}>
                          <Box
                            fontSize={"15px"}
                            fontWeight={"normal"}
                            color={"gray.600"}
                            my={5}
                            fontFamily={fuente}
                            alignSelf={"center"}
                          >
                            Filtros
                          </Box>
                          <Select
                            placeholder="Selecciona un vendedor"
                            m={1}
                            onChange={(e) => filtro(e.target.value)}
                            w={"40%"}
                            alignSelf={"center"}
                          >
                            {datosProyectosVendedores.map((o, i) => {
                              return (
                                <option value={o.vendedorId} key={i}>
                                  {o.vendedorName}
                                </option>
                              );
                            })}
                          </Select>
                          <Select
                            placeholder="Selecciona una linea de negocio"
                            m={1}
                            onChange={(e) => filtroLineas(e.target.value)}
                            w={"40%"}
                            alignSelf={"center"}
                          >
                            {datosProyectosLineas.map((o, i) => {
                              return (
                                <option value={o.id} key={i}>
                                  {o.lineaNegocioNombre}
                                </option>
                              );
                            })}
                          </Select>
                        </Flex>
                      </Flex>
                      <Box borderWidth={1} borderRadius={"12px"} py={3} mr={10}>
                        <Box>
                          <Flex direction={"row"} justify={"center"}>
                            <Box
                              mx={2}
                              w={"300px"}
                              h={"200px"}
                              shadow={"md"}
                              rounded={"15"}
                            >
                              <Box
                                fontFamily={fuente}
                                fontWeight={"semibold"}
                                mt={5}
                                textAlign={"center"}
                                fontSize={"18px"}
                                color={"gray.500"}
                              >
                                Proyectos Activos
                              </Box>
                              <Flex
                                direction={"row"}
                                justify={"center"}
                                mt={"5"}
                              >
                                <RingProgress {...configPercentage} />
                                <Box
                                  ml={2}
                                  alignSelf={"center"}
                                  fontFamily={fuente}
                                  fontWeight={"bold"}
                                  textAlign={"center"}
                                  fontSize={"40px"}
                                  color={"gray.700"}
                                  mt={6}
                                >
                                  {proyectosCount}/
                                </Box>
                                <Box
                                  fontFamily={fuente}
                                  fontWeight={"bold"}
                                  textAlign={"center"}
                                  fontSize={"40px"}
                                  color={"gray.700"}
                                  alignSelf={"center"}
                                  mt={6}
                                >
                                  {proyectosCountActivos}
                                </Box>
                              </Flex>
                            </Box>
                            <Box
                              mx={2}
                              w={"300px"}
                              h={"200px"}
                              shadow={"md"}
                              rounded={"15"}
                            >
                              <Box
                                fontFamily={fuente}
                                fontWeight={"semibold"}
                                mt={5}
                                textAlign={"center"}
                                fontSize={"18px"}
                                color={"gray.500"}
                              >
                                Proyectos Compartidos
                              </Box>
                              <Flex
                                direction={"row"}
                                justify={"center"}
                                mt={"5"}
                              >
                                <RingProgress {...configPercentage_A} />
                                <Box
                                  ml={2}
                                  alignSelf={"center"}
                                  fontFamily={fuente}
                                  fontWeight={"bold"}
                                  textAlign={"center"}
                                  fontSize={"40px"}
                                  color={"gray.700"}
                                  mt={6}
                                >
                                  {proyectosCountCompartido}
                                </Box>
                              </Flex>
                            </Box>
                            <Box
                              mx={2}
                              w={"300px"}
                              h={"200px"}
                              shadow={"md"}
                              rounded={"15"}
                              cursor={"pointer"}
                            >
                              <Flex direction={"column"} justify={"center"}>
                                <Box
                                  fontFamily={fuente}
                                  fontWeight={"semibold"}
                                  mt={5}
                                  textAlign={"center"}
                                  fontSize={"18px"}
                                  color={"gray.500"}
                                  alignSelf={"center"}
                                >
                                  Ciclo promedio del proyecto
                                </Box>
                                <Box
                                  fontFamily={fuente}
                                  fontWeight={"bold"}
                                  textAlign={"center"}
                                  fontSize={"40px"}
                                  color={"gray.700"}
                                  alignSelf={"center"}
                                  mt={6}
                                >
                                  {dias.toFixed(0)}
                                </Box>
                                <Box
                                  fontFamily={fuente}
                                  fontWeight={"normal"}
                                  textAlign={"center"}
                                  fontSize={"15px"}
                                  color={"gray.400"}
                                  alignSelf={"center"}
                                >
                                  Días
                                </Box>
                              </Flex>
                            </Box>
                            <Box
                              mx={2}
                              w={"300px"}
                              h={"200px"}
                              shadow={"md"}
                              rounded={"15"}
                              cursor={"pointer"}
                            >
                              <Flex direction={"column"}>
                                <Box
                                  fontFamily={fuente}
                                  fontWeight={"semibold"}
                                  mt={5}
                                  textAlign={"center"}
                                  fontSize={"18px"}
                                  color={"gray.500"}
                                >
                                  Tiempo promedio revisión propuesta
                                </Box>
                                <Box
                                  fontFamily={fuente}
                                  fontWeight={"bold"}
                                  textAlign={"center"}
                                  fontSize={"40px"}
                                  color={"gray.700"}
                                >
                                  2.6
                                </Box>
                                <Box
                                  fontFamily={fuente}
                                  fontWeight={"normal"}
                                  textAlign={"center"}
                                  fontSize={"15px"}
                                  color={"gray.400"}
                                >
                                  Min - Proyecto
                                </Box>
                              </Flex>
                            </Box>
                          </Flex>
                          <Box p={3}>
                            <Box
                              my={5}
                              fontFamily={fuente}
                              fontWeight={"bold"}
                              textAlign={"center"}
                              fontSize={"40px"}
                            >
                              Desempeño
                            </Box>
                            <Line {...config} />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* Tabla para movil*/}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Graficas;
