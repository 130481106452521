import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  Input,
  VStack,
  Toast,
  SimpleGrid,
  LinkOverlay,
  HStack,
  Image,
  Stack,
  useToast,
  Button,
  Text,
  Heading,
  Container,
  Box,
  Center,
  Collapse,
  Fade,
  ScaleFade,
  Slide,
  SlideFade,
  useDisclosure,
  Flex,
  Spacer,
  Link,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuIcon,
  MenuCommand,
  MenuDivider,
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/react";
import { ChevronDownIcon, BellIcon } from "@chakra-ui/icons";
import { BsFillBellFill } from "react-icons/bs";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Encabezado from "./Vendedor/Utilidades/Encabezado";
import Logo from "../assets/logo-nctech-1-1.svg";
import useUser from "./context/useUser";
import * as AWS from "aws-sdk";
import UploadImageToS3WithReactS3 from "./UploadPictureProfile";
import { connect } from "react-redux";
import { update_logo } from "../actions/index";
AWS.config.update({
  region: "us-east-1",
  secretAccessKey: "3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi",
  accessKeyId: "AKIARVGY33F62XNKJJK3",
});
//AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' });

const salir = () => {
  localStorage.removeItem("token");
  window.location = "/";
};
const _Header = (props) => {
  const docClient = new AWS.DynamoDB.DocumentClient({
    apiVersion: "2022-01-01",
  });
  const [datos_f, setDatos_f] = useState([]);
  const { avatarImage, error } = props || {};

  const { user, setUser } = useUser();
  console.log(avatarImage);
  return (
    <HStack w={"95%"} h={"10vh"} borderWidth={"1px"} bg={"white"} zIndex={-1}>
      <Image src={Logo} w={"132px"} mt={"0"} ml={"10"} />
      <Spacer></Spacer>

      <Center height="50px" pr={5}>
        <Divider orientation="vertical" />
      </Center>
      <Menu pr={10} bg={"white"}>
        <Popover>
          <PopoverTrigger>
            {avatarImage === undefined ? (
              <Avatar src={user.Image} />
            ) : (
              <Avatar src={avatarImage} />
            )}
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Sube tu foto de perfil</PopoverHeader>
            <PopoverBody>
              <UploadImageToS3WithReactS3 />
            </PopoverBody>
          </PopoverContent>
        </Popover>

        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bg={"white"}>
          {user.username}
        </MenuButton>
        <MenuList>
          <MenuItem>
            <Link
              color={"gray.700"}
              fontWeight={"medium"}
              fontSize={"15px"}
              href="#"
              onClick={salir}
              className="font"
              w={"100%"}
            >
              Salir
            </Link>
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  );
};

//REDUX
const mapStateProps = (state) => ({
  avatarImage: state.vendedorReducer.avatarImage,
  error: state.vendedorReducer.error,
});

const Header = connect(mapStateProps, { update_logo })(_Header);

export default Header;
