import React, { useState } from 'react';
import axios from 'axios';
import Logo from '../../assets/LOGOS_NCTECH-01.svg'
import LogoA from '../../assets/logo-nctech-1-1.svg'
import Cover from '../../assets/cover.png'
import {Input, VStack,Toast, SimpleGrid,LinkOverlay, HStack, Image, Stack, useToast, Button, 
        Text, Heading, Container, Box, Center, Collapse, Fade, ScaleFade, Slide, SlideFade, 
        useDisclosure, Flex, Spacer, Link } from "@chakra-ui/react"
import { left } from '@popperjs/core';
import { FaLinkedin, FaLinkedinIn, FaWhatsappSquare } from 'react-icons/fa';
import { SiGmail } from 'react-icons/si';

export default function ForgotConfirmate() {
const regresar = () => {
    window.location.href = "/";
}

  return(
  

<Stack direction={{base:'column', lg:'row'}} m={'0px'} spacing={0}>
<Box mt={0} visibility={{base:'hidden', lg:'visible'}} w={{base:'0px',lg:'100%'}} 
     h={{base:'0px',lg:"100vh"}} className={'bg-login-color'} borderWidth={{base:'0px' ,lg:'1px'}}>
 <Link href="/">
   <Image src={Logo} w={'132px'} mt={'5'} ml={'10'} position={'absolute'} />
 </Link>
  {/* Logo */}
 
 <Center>
   <Box justifyContent={'center'}>
   <Image src={Cover} w={{lg:'100%'}} h={'100vh'} objectFit={'cover'} />
   </Box>
 </Center>
  {/* Ilustración */}
 <HStack spacing={'10'}>
   <Box bottom={10} position={'absolute'} left={10} visibility={{ base: 'hidden', lg: 'visible' }}>
     <FaWhatsappSquare fontSize={'20pt'} color={'white'} />
   </Box>
   <Spacer />
   <Box bottom={10} position={'absolute'} left={10} visibility={{ base: 'hidden', lg: 'visible' }}>
     <SiGmail fontSize={'20pt'} color={'white'} />
   </Box>
   <Box bottom={10} position={'absolute'} left={20} visibility={{ base: 'hidden', lg: 'visible' }}>
     <FaLinkedin fontSize={'20pt'} color={'white'} />
   </Box>
 </HStack>

  {/* Redes Sociales */}
</Box>
{/* Fin primera sección */}
<Box w={{base: '100%', lg:'60%'}} h="100vh" bg="gray.50" m={0}>
 {/* Inicio-Logo */}
 <Center>
   <Box mt={10}>
     <Image visibility={{base:'visible', lg:'hidden'}} src={LogoA} w={'132px'} mt={'5'} />
   </Box>
  </Center>
  {/* Fin-Logo */}
  {/* Inicio-titulos */}
 <Center mt={{base:24, lg:28, "2xl":24}}>
   <VStack spacing={1}>
     <Text fontFamily={'Roboto'} fontWeight={'bold'} fontSize={'43px'} color={'gray.700'}>Gracias</Text>
     <Text fontFamily={'Roboto'} fontWeight={'normal'} fontSize={'17px'} color={'gray.700'}>Te hemos mandado las instrucciones a tu email</Text>
   </VStack>
 </Center>
  {/* Fin-titulos */}
 {/* Inicio-formulario */}
 <Stack mt={{base:20, lg:10}}>
   <form >
     <Center mt={10}>
         <Button onClick={()=>regresar()} h={'60px'} variant={'outline'} w="300px" colorScheme={'red'} fontWeight={'medium'}
           className="font">
           REGRESAR AL INICIO</Button> 
     </Center>
   </form>
 </Stack>
 {/* fin-formulario */}
 {/* Inicio Redes Sociales Mobile */}
 <Center>
 <SimpleGrid bottom={14} position={'absolute'} columns={3} spacing={5}>
     <Box visibility={{ base: 'visible', lg: 'hidden' }}>
     <FaWhatsappSquare fontSize={'20pt'} color={'gray'} />
     </Box>
     <Box visibility={{ base: 'visible', lg: 'hidden' }}>
     <SiGmail fontSize={'20pt'} color={'gray'} />
     </Box>
     <Box visibility={{ base: 'visible', lg: 'hidden' }}>
     <FaLinkedin fontSize={'20pt'} color={'gray'} />
     </Box>

   </SimpleGrid>
   
 </Center>
  {/* Fin Redes Sociales */}
</Box> 
</Stack>

  )
}
