import React, { useState } from "react";
import { uploadFile } from "react-s3";
import { Button, Box, Flex } from "@chakra-ui/react";
import { BiSlideshow } from "react-icons/bi";
import {
  useQuery,
  useMutation,
  useQueryClient,
  useDisclosure,
} from "react-query";
import useUser from "./context/useUser";
import useToken from "./useToken";
import { connect } from "react-redux";
import { update_logo } from "../actions/index";
//PRODUCCION//

const S3_BUCKET = "nctech-imagenes";
const REGION = "us-east-1";
const ACCESS_KEY = "AKIARVGY33F62XNKJJK3";
const SECRET_ACCESS_KEY = "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";

//DESARROLLO//
// const S3_BUCKET ='mostrilio-profile';
// const REGION ='us-east-1';
// const ACCESS_KEY ='AKIAWLUS3YRU4LTKLQHG';
// const SECRET_ACCESS_KEY ='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';

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

const _UploadPictureProfile = (props) => {
  const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const { user, setUser } = useUser();
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState(0);
  const { token, setToken } = useToken();
  const { avatarImage, error } = props || {};
  const subirImage = async (datos) => {
    props.update_logo(datos, user.id.toString(), urlStrapi, token);
  };
  const mutation = useMutation(subirImage, {
    onSuccess: () => {
      queryClient.invalidateQueries("USERS");
      //console.log("Listo")
    },
    onSettled: () => {
      console.log("Ready");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleUpload = async (file) => {
    //console.log(file)
    uploadFile(file, config)
      .then(
        (data) => mutation.mutate(data),
        //console.log(data)
      )
      .catch((err) => console.error(err));
  };

  return (
    <Box>
      <label onChange={handleFileInput} htmlFor="fileProfile">
        <input name="" type="file" id="fileProfile" hidden />
        <Flex my={6} cursor={"pointer"}>
          <Box alignSelf={"center"}>
            <BiSlideshow size={"20px"} color={"gray.500"} />
          </Box>
          <Box
            alignContent={"center"}
            ml={2}
            fontSize={"sm"}
            fontWeight={"medium"}
            color={"gray.500"}
            fontFamily={"system-ui, sans-serif"}
          >
            Clic aquí para seleccionar tu archivo
          </Box>
          <Box
            alignContent={"center"}
            ml={2}
            fontSize={"sm"}
            fontWeight={"medium"}
            color={"gray.800"}
            fontFamily={"system-ui, sans-serif"}
          >
            {fileName}
          </Box>
        </Flex>
      </label>
      {fileName ? (
        <Button
          fontSize={"sm"}
          fontWeight={"medium"}
          color={"gray.800"}
          fontFamily={"system-ui, sans-serif"}
          bg={"orange"}
          variant={"outline"}
          onClick={() => handleUpload(selectedFile)}
        >
          {" "}
          Subir foto{" "}
        </Button>
      ) : (
        <Button
          fontSize={"sm"}
          fontWeight={"medium"}
          color={"gray.800"}
          fontFamily={"system-ui, sans-serif"}
          bg={"orange"}
          variant={"outline"}
          disabled
        >
          Subir foto
        </Button>
      )}
    </Box>
  );
};

//REDUX
const mapStateProps = (state) => ({
  avatarImage: state.vendedorReducer.avatarImage,
  error: state.vendedorReducer.error,
});

const UploadPictureProfile = connect(mapStateProps, { update_logo })(
  _UploadPictureProfile,
);

export default UploadPictureProfile;
