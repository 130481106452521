import React from 'react';

const Vendedores = () => {
    return (
        <div>
            Listado de vendedores
        </div>
    );
};

export default Vendedores;