import React from "react";
import HomeCliente from "../components/Cliente/Home";
const Home = () => {
  return (
    <>
      <HomeCliente />
    </>
  );
};

export default Home;
