import React from "react";
import { HStack, Box, Center, Flex, Avatar } from "@chakra-ui/react";

const Encabezado = (props) => {
  return (
    <Center>
      <Box
        h={"150px"}
        bg={"gray.50"}
        w={"300px"}
        overflow="hidden"
        borderRadius={"lg"}
        backdropBlur
      >
        <Flex direction={"column"} justifyContent={"center"} h={"100%"}>
          <HStack ml={5} mt={0} spacing={2}>
            <Avatar name={props.nombre} size={"sm"} />
            <Flex direction={"column"} spacing={1}>
              <Box
                w={"100%"}
                fontSize={"15px"}
                fontWeight={"normal"}
                color={"gray.800"}
              >
                {props.nombre}
              </Box>
              <Box
                w={"100%"}
                fontSize={"13px"}
                fontWeight={"normal"}
                color={"gray.700"}
              >
                {props.subtitulo}
              </Box>
            </Flex>
          </HStack>
        </Flex>
      </Box>
    </Center>
  );
};

export default Encabezado;
