import React, { useContext, useState, useEffect } from "react";
import {
  Spinner,
  Input,
  Stack,
  useToast,
  Button,
  Box,
  Center,
  useDisclosure,
  Flex,
  Spacer,
  Link,
  //Table,
  Avatar,
  Divider,
  FormControl,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Tag,
  TagLabel,
  Select,
} from "@chakra-ui/react";
import Header from "../../Header";
import { PhoneIcon, AddIcon, WarningIcon } from "@chakra-ui/icons";
import useUser from "../../context/useUser";
import useToken from "../../useToken";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import Encabezado from "../Utilidades/Encabezado";
import MenuVendedor from "../Utilidades/MenuVendedor";
import { HiDocumentText, HiDotsHorizontal } from "react-icons/hi";
import { FaSearch } from "react-icons/fa";
import { nanoid } from "nanoid";
import { Space, Table, Empty } from "antd";
import "antd/dist/antd.css";
import { RenderIf } from "../../../hooks/renderIF";
import * as XLSX from "xlsx";
import MenuGerente from "../../Gerente/Utilidades/MenuGerente";
import Blur from "react-css-blur";
import { GrFormDown } from "react-icons/gr";
import UploadLogo from "../../UploadLogo";
import { connect } from "react-redux";
import { get_crm, get_customers, get_customer } from "../../../actions";
const _Clientes = (props) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { isOpen: isOpen, onOpen: onOpen, onClose: onClose } = useDisclosure();
  const {
    isOpen: isOpenLogo,
    onOpen: onOpenLogo,
    onClose: onCloseLogo,
  } = useDisclosure();
  const [datos, setDatos] = useState([]);
  const [razonSocial, setRazonSocial] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [direccion, setDireccion] = React.useState("");
  const [direccionEstado, setDireccionEstado] = React.useState("");
  const [direccionPais, setDireccionPais] = React.useState("");
  const [nombreContacto, setNombreContacto] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [telefono, setTelefono] = React.useState("");
  const [cardId, setCardId] = React.useState("");
  const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI);
  const { token, setToken } = useToken();
  const [clienteId, setClienteId] = useState();
  const { user, setUser } = useUser();
  const [cargando, setCargando] = useState(false);
  const { Column, ColumnGroup } = Table;
  const [sortedInfo, setSortedInfo] = useState({});
  const [bandera, setBandera] = useState();
  const [fuente] = useState("Montserrat");
  const [blurOn, setBlurOn] = useState(false);
  const [tipo, setTipo] = useState("Cliente");

  //Nuevos campos
  const [ciudad, setCiudad] = useState();
  const [calle, setCalle] = useState();
  const [numero, setNumero] = useState();
  const [colonia, setColonia] = useState();
  const [codigoPostal, setCodigoPostal] = useState();
  const [tipoCliente, setTipoCliente] = useState();

  const [urlCassandra] = useState(process.env.REACT_APP_URL_CASSANDRA);
  const [tokenCasandra] = useState(process.env.REACT_APP_TOKEN_CASSANDRA);
  const tokenCRM = "ry8nwwaw4y9gn9bwwa9yhdm2wfse3akvwbne5shv";
  const { crm, customers } = props || {};

  useEffect(() => {
    props.get_crm(urlCassandra, tokenCasandra);
  }, []);

  // async function fetchClientes() {
  //    //const clientes = fetch(urlStrapi + '/clientes?vendedor=' + user.id.toString())

  //        if(crm.local == false) {
  //         const url = `https://crm.nctech.com.mx/api/accounts/?q=uvm`
  //         const result = await axios(url, {
  //             method: 'GET',
  //             headers: {
  //                 'accept': 'application/json',
  //                 'Content-Type': 'application/json',
  //                 'Authorization': `Bearer ${tokenCRM}`
  //             }
  //         })
  //         return result
  //        }else {

  //            const clientes = fetch(urlStrapi + '/clientes')
  //             .then(response => response.json())
  //             return clientes
  //        }

  // }

  async function fetchCliente(query) {
    //     //const urlC = `${urlStrapi}/clientes?nombre_contains=${b}&vendedor=${user.id.toString()}`
    //     const urlC = `${urlStrapi}/clientes?nombre_contains=${b}`
    //     //const urlC = `${urlStrapi}/clientes`
    //     const ventasData = await axios.get(urlC, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         }
    //     })
    //     const dat = ventasData
    //     setDatos(dat.data)
    //    // console.log(dat)
    //     if(b === ""){
    //         //refetch()
    //     }
    //     return dat

    props.get_customer(crm.local, tokenCRM, urlStrapi, query);
  }
  const eliminar = async (proyectId) => {
    const urlC = urlStrapi + "/clientes/" + proyectId;
    const response = await fetch(urlC, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);

    return response;
  };
  const crear = async () => {
    try {
      setCargando(true);
      const a = "@";
      const str = nombre.toLowerCase();
      const r = str.replace(/\s/g, "");
      const username = a + r;

      const urlC = urlStrapi + "/clientes";
      const response = await fetch(urlC, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          // razonSocial: razonSocial.toLowerCase(),
          // nombre: nombre.toLowerCase(),
          // direccion: direccion,
          // estado: direccionEstado,
          // pais: direccionPais,
          // //nombreContacto: nombreContacto,
          // //correo: correo,
          // //telefono:telefono,
          // vendedor: user.id.toString(),
          // token: nanoid(),
          // username,
          // estatus: "Nuevo",
          // fechaCreacion: new Date(),
          // CARDID: cardId
          CARDID: cardId,
          razonSocial: razonSocial.toLowerCase(),
          nombre: nombre.toLowerCase(),
          ciudad: ciudad,
          estado: direccionEstado,
          pais: direccionPais,
          calle: calle,
          numero: numero,
          colonia: colonia,
          codigoPostal: codigoPostal,
          tipo: tipo,
          //nombreContacto: nombreContacto,
          //correo: correo,
          //telefono:telefono,
          vendedor: user.id.toString(),
          token: nanoid(),
          username,
          estatus: "Nuevo",
          fechaCreacion: new Date(),
        }),
      });

      if (!response.ok) {
        const responseText = await response.text();
        if (response.status === 500) {
          // Mostrar mensaje de error personalizado si hay un error de entrada duplicada
          toas(`Ya se encuentra un cliente con este CARDID`);
        }
        //toas(`Error ${response}`)
      }
      return response;
    } catch (error) {}
  };
  const mutation_a = useMutation(eliminar, {
    onSuccess: () => {
      console.log("Eliminado");
      queryClient.invalidateQueries("CLIENTES");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation = useMutation(crear, {
    onSuccess: () => {
      queryClient.invalidateQueries("CLIENTES");
      setCargando(false);
      onClose();
      setRazonSocial("");
      setNombre("");
      setDireccion("");
      setDireccionEstado("");
      setDireccionPais("");
      setNombreContacto("");
      setCorreo("");
      setTelefono("");

      //window.location.href = '/vendedor/clientes'
      //console.log('success')
    },
    onError: (error) => {
      console.log(error);
    },
  });

  function handleonclick() {
    if (
      !cardId ||
      !nombre ||
      !razonSocial ||
      !ciudad ||
      !direccionEstado ||
      !direccionPais ||
      !calle ||
      !numero ||
      !colonia ||
      !codigoPostal
    ) {
      toas("Verifica que todos los campos estén completos.");
      return;
    }
    mutation.mutate();
    BlurOn(false);
  }
  function handleonclickDelete(id) {
    mutation_a.mutate(id);
  }
  // const { isLoading, isFetching, data, refetch  } = useQuery('CLIENTES', fetchClientes, {
  //     onSuccess: (data) => {
  //         //console.log("data:", data)
  //         setDatos(data)
  //     },
  //     cacheTime: Infinity
  // })
  // if (isLoading) {
  //     return <Center mt={'10%'}><Spinner color={'green.700'} /></Center>
  // }
  const setAgeSort = () => {
    setSortedInfo({
      order: "descend",
      columnKey: "id",
    });
  };

  const addCliente = () => {
    window.location.href = "/vendedor/clientes/new";
  };

  function buscar(e) {
    fetchCliente(e);
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      fetchCliente(event.target.value);
    }
  };

  const toas = (mensaje) => {
    toast({
      title: "Error.",
      description: `Error, ${mensaje}`,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };
  const onChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      console.log(data);
    };
    reader.readAsBinaryString(file);
  };
  const BlurOn = (blurOn) => {
    setBlurOn(blurOn);
  };
  const handlerClickModal = () => {
    setCardId();
    onOpen();
    BlurOn(true);
  };
  const handlerClickCloseModal = () => {
    onClose();
    BlurOn(false);
  };
  const handlerClickModalLogo = (id) => {
    setClienteId(id);
    onOpenLogo();
    BlurOn(true);
  };
  const handlerClickCloseModalLogo = () => {
    onCloseLogo();
    BlurOn(false);
  };
  const tipoData = [
    {
      id: 1,
      label: "Cliente",
    },
    {
      id: 2,
      label: "Prospecto",
    },
  ];

  const changeClient = (e) => {
    const now = new Date().getTime(); // Obtiene la hora actual en milisegundos
    const formattedDate = `Prospecto-${now}`; // Construye la cadena con el valor deseado
    setTipo(e);
    // setCardId(formattedDate)
  };
  useEffect(() => {
    setTimeout(() => {
      props.get_customers(crm.local, tokenCRM, urlStrapi);
    }, 1000);
  }, [crm.local]);

  return (
    <>
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={handlerClickCloseModal}
        isCentered
        zIndex={9999}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontFamily={fuente}
            fontSize={"22px"}
            fontWeight={"medium"}
          >
            Cliente o prospecto
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack spacing={4}>
              <Flex direction={"column"}>
                <Box>Selecciona un tipo</Box>
                <Select
                  onChange={(e) => changeClient(e.target.value)}
                  fontFamily={fuente}
                >
                  {tipoData.map((forma) => (
                    <option key={forma.id} value={forma.label}>
                      {forma.label}
                    </option>
                  ))}
                </Select>
              </Flex>
              <RenderIf isTrue={tipo == "Cliente"}>
                <InputGroup size="md">
                  <InputLeftAddon
                    children="CardID"
                    fontFamily={fuente}
                    width={"30%"}
                    fontSize={12}
                    fontWeight={"semibold"}
                  />
                  <Input
                    onChange={(e) => setCardId(e.target.value)}
                    required={true}
                    placeholder="Ingresa el número de cliente"
                    fontFamily={fuente}
                    fontSize={"11pt"}
                    fontWeight={"normal"}
                  />
                  <InputRightAddon children="*" bg={"red.50"} />
                </InputGroup>
              </RenderIf>
              <RenderIf isTrue={tipo == "Prospecto"}>
                <InputGroup size="md">
                  <InputLeftAddon
                    children="CardID"
                    fontFamily={fuente}
                    width={"30%"}
                    fontSize={12}
                    fontWeight={"semibold"}
                  />
                  {/* <Input
                                value={cardId}
                                required={true}
                                placeholder="Ingresa el número de cliente"
                                fontFamily={fuente}
                                fontSize={'11pt'}
                                fontWeight={'normal'}
                                readOnly
                                /> */}
                  <Input
                    onChange={(e) => setCardId(e.target.value)}
                    required={true}
                    placeholder="Ingresa el número de cardID"
                    fontFamily={fuente}
                    fontSize={"11pt"}
                    fontWeight={"normal"}
                  />
                  <InputRightAddon children="*" bg={"red.50"} />
                </InputGroup>
              </RenderIf>
              <InputGroup size="md">
                <InputLeftAddon
                  children="Nombre"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={12}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setNombre(e.target.value)}
                  required={true}
                  placeholder="Ingresa el nombre del cliente"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              <InputGroup size="md">
                <InputLeftAddon
                  children="Nombre Comercial"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={12}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setRazonSocial(e.target.value)}
                  type={"tel"}
                  required={true}
                  placeholder="Ingresa el nombre comercial"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              <Divider />
              <InputGroup size="md">
                <InputLeftAddon
                  children="Ciudad"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={12}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setCiudad(e.target.value)}
                  required={true}
                  placeholder="Escribe la Ciudad"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              <InputGroup size="md">
                <InputLeftAddon
                  children="Estado"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={12}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setDireccionEstado(e.target.value)}
                  required={true}
                  placeholder="Escribe un estado"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              <InputGroup size="md">
                <InputLeftAddon
                  children="País"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={12}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setDireccionPais(e.target.value)}
                  required={true}
                  placeholder="Escribe un país"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              <InputGroup size="md">
                <InputLeftAddon
                  children="Calle"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={12}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setCalle(e.target.value)}
                  required={true}
                  placeholder="Escribe la calle"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              <InputGroup size="md">
                <InputLeftAddon
                  children="Número"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={12}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setNumero(e.target.value)}
                  required={true}
                  placeholder="Escribe el número"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              <InputGroup size="md">
                <InputLeftAddon
                  children="Colonia"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={12}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setColonia(e.target.value)}
                  required={true}
                  placeholder="Escribe la colonia"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              <InputGroup size="md">
                <InputLeftAddon
                  children="Código Postal"
                  fontFamily={fuente}
                  width={"30%"}
                  fontSize={12}
                  fontWeight={"semibold"}
                />
                <Input
                  onChange={(e) => setCodigoPostal(e.target.value)}
                  required={true}
                  placeholder="Escribe el código postal"
                  fontFamily={fuente}
                  fontSize={"11pt"}
                  fontWeight={"normal"}
                />
                <InputRightAddon children="*" bg={"red.50"} />
              </InputGroup>
              {/* <InputGroup size='md'>
                            <InputLeftAddon children='Contacto'  fontFamily={fuente} width={'30%'} fontSize={15} fontWeight={'semibold'} />
                            <Input onChange={(e) => setNombreContacto(e.target.value)}  required={true} placeholder="ingresa el nombre del contacto de la empresa" fontFamily={fuente} fontSize={'11pt'} fontWeight={'normal'} />
                            <InputRightAddon children='*' bg={'red.50'} />
                        </InputGroup>
                        <InputGroup size='md'>
                            <InputLeftAddon children='Teléfono'  fontFamily={fuente} width={'30%'} fontSize={15} fontWeight={'semibold'} />
                            <Input onChange={(e) => setTelefono(e.target.value)} required={true} placeholder="Ingresa el teléfono del contacto" fontFamily={fuente} fontSize={'11pt'} fontWeight={'normal'} />
                            <InputRightAddon children='*' bg={'red.50'} />
                        </InputGroup>
                        <InputGroup size='md'>
                            <InputLeftAddon children='Correo'  fontFamily={fuente} width={'30%'} fontSize={15} fontWeight={'semibold'} />
                            <Input onChange={(e) => setCorreo(e.target.value)}  required={true} placeholder="Ingresa el correo electrónico del contacto" fontFamily={fuente} fontSize={'11pt'} fontWeight={'normal'} />
                            <InputRightAddon children='*' bg={'red.50'} />
                        </InputGroup> */}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={cargando}
              onClick={handleonclick}
              bg="orange.500"
              color={"white"}
              fontFamily={fuente}
              mr={3}
            >
              Crear
            </Button>

            <Button onClick={handlerClickCloseModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isOpenLogo}
        onClose={handlerClickCloseModalLogo}
        isCentered
        zIndex={9999}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontFamily={fuente}
            fontSize={"22px"}
            fontWeight={"medium"}
          >
            Agregar logo al cliente
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack spacing={4}>
              <UploadLogo clienteId={clienteId} />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handlerClickCloseModalLogo}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex direction={{ base: "column", lg: "row" }} bg={"pink"} h={"100vh"}>
        <RenderIf isTrue={user.type !== 3}>
          <MenuVendedor />
        </RenderIf>
        <RenderIf isTrue={user.type == 3}>
          <MenuGerente />
        </RenderIf>
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={1}
            >
              <Header />
            </Box>
            <Blur radius={blurOn ? "1px" : "0"} transition="400ms">
              <Box bg={"white"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
                <Flex direction={"column"}>
                  <Box bg={"white"} h={{ base: "25vh", lg: "25vh" }} mb={5}>
                    <Flex direction={{ base: "column", lg: "row" }}>
                      <Box
                        display={{ base: "none", lg: "flex" }}
                        bg={"white"}
                        w={{ base: "0", lg: "20%" }}
                        h={{ base: "0", lg: "25vh" }}
                      ></Box>{" "}
                      {/* Box 1 */}
                      <Box
                        bg={"pink.400"}
                        w={{ base: "100%", lg: "60%" }}
                        h={{ base: "25vh", lg: "25vh" }}
                      >
                        <Flex direction={"column"}>
                          <Box bg={"gray.300"} h={"12.5vh"}>
                            <Flex direction={"row"}>
                              <Box bg={"gray.500"} w={"70%"} h={"12.5vh"}>
                                <Flex direction={"column"}>
                                  <Box bg={"white"} w={"100%"} h={"12.50vh"}>
                                    <Flex direction={"row"} justify={"start"}>
                                      <Box
                                        alignSelf={"center"}
                                        mt={5}
                                        mr={3}
                                        fontSize={{
                                          lg: "21px",
                                          base: "23px",
                                          "2xl": "33px",
                                        }}
                                        fontFamily={fuente}
                                        fontWeight={"bold"}
                                        color={"gray.700"}
                                      >
                                        ¡Busca a tu cliente!
                                      </Box>
                                      <Box
                                        alignSelf={"end"}
                                        fontSize={{
                                          lg: "21px",
                                          base: "23px",
                                          "2xl": "33px",
                                        }}
                                        fontWeight={"bold"}
                                        color={"gray.500"}
                                        fontFamily={fuente}
                                        ml={{ lg: "0", base: "5" }}
                                        mt={{ lg: "6", base: "5" }}
                                      ></Box>
                                    </Flex>
                                  </Box>
                                </Flex>
                              </Box>
                              <Box bg={"white"} w={"30%"} h={"12.5vh"}>
                                <Flex
                                  direction={"column"}
                                  justifyContent={"end"}
                                >
                                  <Box
                                    bg={"white"}
                                    w={"100%"}
                                    h={"4.16vh"}
                                    align={"end"}
                                    mt={5}
                                    pr={{ base: 10, lg: 0 }}
                                  >
                                    <RenderIf isTrue={crm.local == false}>
                                      <Tooltip
                                        label="Agrega nuevo cliente"
                                        aria-label="A tooltip"
                                        placement="right"
                                        borderRadius={"sm"}
                                      >
                                        <Button
                                          colorScheme={"orange"}
                                          onClick={handlerClickModal}
                                        >
                                          <AddIcon />
                                        </Button>
                                      </Tooltip>
                                    </RenderIf>
                                  </Box>
                                  <Box
                                    bg={"white"}
                                    w={"100%"}
                                    h={"4.16vh"}
                                  ></Box>
                                  <Box
                                    bg={"white"}
                                    w={"100%"}
                                    h={"4.16vh"}
                                  ></Box>
                                </Flex>
                              </Box>
                            </Flex>
                          </Box>
                          <Box bg={"white"} h={"12.5vh"}>
                            <Center>
                              <Box
                                w={"100%"}
                                mt={{ base: 2, lg: 0 }}
                                bg={"gray.50"}
                                pb={5}
                                pl={{ base: 5, lg: 0 }}
                                pr={{ base: 5, lg: 0 }}
                                py={5}
                                rounded={"15"}
                                shadow={"sm"}
                              >
                                <FormControl id="razon">
                                  <Flex direction={"column"}>
                                    <InputGroup size="lg" px={5}>
                                      <Input
                                        type="text"
                                        autoFocus
                                        onKeyPress={handleKeyPress}
                                        bg={"white"}
                                        fontFamily={fuente}
                                        placeholder={
                                          "Escribe aquí para buscar un cliente..."
                                        }
                                      />
                                    </InputGroup>
                                    <Box
                                      color={"gray.500"}
                                      py={2}
                                      px={5}
                                      fontSize={"13px"}
                                      fontFamily={fuente}
                                    >
                                      Presiona la tecla <strong>Enter</strong>{" "}
                                      para inicar la búsqueda
                                    </Box>
                                  </Flex>
                                </FormControl>
                              </Box>
                            </Center>
                          </Box>
                        </Flex>
                      </Box>
                      <Box
                        display={{ base: "none", lg: "flex" }}
                        bg={"white"}
                        w={{ base: "0", lg: "20%" }}
                        h={{ base: "0", lg: "25vh" }}
                      ></Box>{" "}
                      {/* Box 3 */}
                    </Flex>
                  </Box>
                  <Box
                    bg={"white"}
                    h={{ base: "65vh", lg: "65vh" }}
                    pl={{ base: 0, lg: 0 }}
                    pr={{ base: 0, lg: 0 }}
                  >
                    <Flex direction={{ base: "column", lg: "row" }}>
                      <Box
                        display={{ base: "none", lg: "flex" }}
                        bg={"white"}
                        w={{ base: "0", lg: "5%" }}
                        h={{ base: "0", lg: "65vh" }}
                      ></Box>{" "}
                      {/* Box 1 */}
                      <Box
                        bg={"white"}
                        w={{ base: "0", lg: "90%" }}
                        h={{ base: "0", lg: "65vh" }}
                      >
                        <RenderIf isTrue={customers}>
                          <Box
                            borderWidth={1}
                            borderRadius={"12px"}
                            py={3}
                            bg={"white"}
                            w={"100%"}
                            mt={5}
                          >
                            {/* <input type="file" onChange={onChange} /> */}
                            <RenderIf isTrue={crm.local == true}>
                              <Center>
                                <Flex direction={"column"}>
                                  <Box my={2}>
                                    Estos datos son obtenidos directos del CRM
                                  </Box>
                                  <Table
                                    dataSource={customers}
                                    bordered
                                    fontFamily={fuente}
                                    pagination={{
                                      pageSize: 5,
                                    }}
                                  >
                                    <Column
                                      title="CardID"
                                      dataIndex="id"
                                      key="id"
                                      width={"100px"}
                                      height={"30px"}
                                      render={(id) => (
                                        <Box
                                          key={id}
                                          color={"black"}
                                          fontFamily={fuente}
                                          fontSize={"14px"}
                                          fontWeight={"medium"}
                                        >
                                          {id}
                                        </Box>
                                      )}
                                      fontFamily={fuente}
                                    />
                                    <Column
                                      title="Tipo"
                                      dataIndex="type"
                                      key="type"
                                      fontFamily={fuente}
                                      width={"120px"}
                                      render={(text, record, index) => (
                                        <Box key={index}>
                                          {record.type == "leads" ? (
                                            <Box>Prospecto</Box>
                                          ) : (
                                            <Box>Cliente</Box>
                                          )}
                                        </Box>
                                      )}
                                    />
                                    <Column
                                      title="Datos de Empresa"
                                      dataIndex="nombre"
                                      key="nombre"
                                      width={"280px"}
                                      height={"30px"}
                                      render={(text, record, index) => (
                                        <Flex key={index}>
                                          {record.Logo === undefined ? (
                                            <Avatar
                                              name={record.name}
                                              size="sm"
                                              mr={"2"}
                                            />
                                          ) : (
                                            <Avatar
                                              src={record.Logo}
                                              size="sm"
                                              mr={"2"}
                                            />
                                          )}

                                          <Flex direction={"column"}>
                                            <Link
                                              href={`/vendedor/clientes/detalles/${record.id}/${encodeURIComponent(record.name)}`}
                                              fontFamily={fuente}
                                              fontSize={"13px"}
                                              fontWeight={"bold"}
                                              textTransform={"uppercase"}
                                            >
                                              {record.name}
                                            </Link>
                                          </Flex>
                                        </Flex>
                                      )}
                                      fontFamily={fuente}
                                    />
                                    <Column
                                      title="Canal"
                                      dataIndex="channel"
                                      key="channel"
                                      fontFamily={fuente}
                                      width={"150px"}
                                    />
                                  </Table>
                                </Flex>
                              </Center>
                            </RenderIf>
                            <RenderIf isTrue={crm.local == false}>
                              <Table
                                dataSource={customers}
                                bordered
                                fontFamily={fuente}
                                pagination={{
                                  pageSize: 5,
                                }}
                              >
                                <Column
                                  title="CardID"
                                  dataIndex="CARDID"
                                  key="CARDID"
                                  width={"100px"}
                                  height={"30px"}
                                  render={(CARDID) => (
                                    <Box
                                      color={"black"}
                                      fontFamily={fuente}
                                      fontSize={"14px"}
                                      fontWeight={"medium"}
                                    >
                                      {CARDID}
                                    </Box>
                                  )}
                                  fontFamily={fuente}
                                />
                                <Column
                                  title="Tipo"
                                  dataIndex="tipo"
                                  key="tipo"
                                  fontFamily={fuente}
                                  width={"120px"}
                                />
                                <Column
                                  title="Datos de Empresa"
                                  dataIndex="nombre"
                                  key="nombre"
                                  width={"280px"}
                                  height={"30px"}
                                  render={(text, record, index) => (
                                    <Flex>
                                      {record.Logo === undefined ? (
                                        <Avatar
                                          name={record.nombre}
                                          size="sm"
                                          mr={"2"}
                                        />
                                      ) : (
                                        <Avatar
                                          src={record.Logo}
                                          size="sm"
                                          mr={"2"}
                                        />
                                      )}

                                      <Flex direction={"column"}>
                                        <Link
                                          href={`/vendedor/clientes/detalles/${record.id}/${encodeURIComponent(record.nombre)}`}
                                          fontFamily={fuente}
                                          fontSize={"13px"}
                                          fontWeight={"bold"}
                                          textTransform={"uppercase"}
                                        >
                                          {record.nombre}
                                        </Link>
                                        <Box
                                          fontSize={"11px"}
                                          fontWeight={"medium"}
                                          color={"gray.500"}
                                          fontFamily={fuente}
                                          textTransform={"uppercase"}
                                        >
                                          {record.razonSocial}
                                        </Box>
                                      </Flex>
                                    </Flex>
                                  )}
                                  fontFamily={fuente}
                                />
                                <Column
                                  title="Calle"
                                  dataIndex="calle"
                                  key="calle"
                                  fontFamily={fuente}
                                  width={"150px"}
                                />
                                <Column
                                  title="Número"
                                  dataIndex="numero"
                                  key="numero"
                                  fontFamily={fuente}
                                  width={"90px"}
                                />
                                <Column
                                  title="Colonia"
                                  dataIndex="colonia"
                                  key="colonia"
                                  fontFamily={fuente}
                                  width={"150px"}
                                />
                                <Column
                                  title="Ciudad"
                                  dataIndex="ciudad"
                                  key="ciudad"
                                  fontFamily={fuente}
                                  width={"150px"}
                                />
                                <Column
                                  title="Estado"
                                  dataIndex="estado"
                                  key="estado"
                                  fontFamily={fuente}
                                  width={"150px"}
                                />
                                <Column
                                  title="País"
                                  dataIndex="pais"
                                  key="pais"
                                  fontFamily={fuente}
                                  width={"150px"}
                                />

                                <Column
                                  title="Código Postal"
                                  dataIndex="codigoPostal"
                                  key="codigoPostal"
                                  fontFamily={fuente}
                                  width={"150px"}
                                />
                                {/* <Column title="Contacto" dataIndex="nombreContacto" key="nombreContacto" fontFamily={fuente}/>
                                                            <Column title="Correo del contacto" dataIndex="correo" key="correo" fontFamily={fuente}/>
                                                            <Column title="Teléfono" dataIndex="telefono" key="telefono" fontFamily={fuente}/> */}

                                <Column
                                  title="Acción"
                                  fontFamily={fuente}
                                  dataIndex="acciones"
                                  key="acciones"
                                  render={(text, record, index) => (
                                    <Space size="middle">
                                      <Menu>
                                        <MenuButton
                                          px={4}
                                          py={2}
                                          transition="all 0.2s"
                                          borderRadius="md"
                                          borderWidth="1px"
                                          _hover={{
                                            bg: "gray.400",
                                            color: "white",
                                          }}
                                          _expanded={{
                                            bg: "gray.500",
                                            color: "white",
                                          }}
                                          _focus={{ boxShadow: "outline" }}
                                          as={Button}
                                        >
                                          <GrFormDown />
                                        </MenuButton>
                                        <MenuList>
                                          <MenuItem
                                            onClick={() =>
                                              handlerClickModalLogo(record.id)
                                            }
                                            fontFamily={fuente}
                                          >
                                            Subir Logo
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() =>
                                              handleonclickDelete(record.id)
                                            }
                                            fontFamily={fuente}
                                          >
                                            Eliminar
                                          </MenuItem>
                                        </MenuList>
                                      </Menu>
                                    </Space>
                                  )}
                                />
                              </Table>
                            </RenderIf>
                          </Box>
                        </RenderIf>
                        <RenderIf isTrue={!customers}>
                          <Box mt={20}>
                            <Empty
                              description={
                                <span>
                                  Descubre tus resultados de búsqueda aquí.
                                </span>
                              }
                            />
                          </Box>
                        </RenderIf>
                      </Box>
                      {/* Tabla para movil*/}
                      <Box
                        display={{ base: "none", lg: "flex" }}
                        bg={"white"}
                        w={{ base: "0", lg: "5%" }}
                        h={{ base: "0", lg: "65vh" }}
                      ></Box>{" "}
                      {/* Box 3 */}
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Blur>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

//REDUX
const mapStateProps = (state) => ({
  crm: state.crmReducer.local,
  customers: state.customerReducer.customers,
});

const Clientes = connect(mapStateProps, {
  get_crm,
  get_customers,
  get_customer,
})(_Clientes);
export default Clientes;
