import React, { useState } from "react";
import {
  Input,
  VStack,
  Button,
  Text,
  Box,
  Center,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import useUser from "../../context/useUser";
import useToken from "../../useToken";
import { useMutation } from "react-query";
const New = () => {
  const { user } = useUser();
  const [razonSocial, setRazonSocial] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [direccion, setDireccion] = React.useState("");
  const [nombreContacto, setNombreContacto] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [telefono, setTelefono] = React.useState("");

  const [urlStrapi] = useState(process.env.REACT_APP_URL_STRAPI);
  const { token } = useToken();
  const crear = async () => {
    if (user) {
      const urlC = urlStrapi + "/clientes";
      const response = await fetch(urlC, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          razonSocial: razonSocial.toLowerCase(),
          nombre: nombre.toLowerCase(),
          direccion: direccion.toLowerCase(),
          nombreContacto: nombreContacto.toLowerCase(),
          correo: correo.toLowerCase(),
          telefono: telefono.toLowerCase(),
        }),
      });
      return response;
    }
  };

  const mutation = useMutation(crear, {
    onSuccess: () => {
      //queryClient.invalidateQueries('settings')
      window.location.href = "/vendedor/clientes";
      //console.log('success')
    },
    onError: (error) => {
      console.log(error);
    },
  });
  function handleonclick() {
    mutation.mutate();
  }
  return (
    <Box w={"100%"}>
      <Box w={"100%"} p={2}>
        <Breadcrumb ml={"10"} mt={5}>
          <BreadcrumbItem>
            <BreadcrumbLink href="/vendedor">Inicio</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href="/vendedor/clientes/new">
              nuevo-cliente
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Center w={"100%"}>
          <VStack
            w={"60%"}
            borderRadius={"lg"}
            borderWidth={"1px"}
            mt={"5"}
            bg={"white"}
            p={10}
          >
            <Box p={5}>
              <Text
                fontSize={{ lg: "33px", base: "33px", "2xl": "33px" }}
                fontWeight={"bold"}
                color={"gray.700"}
                align={{ base: "center" }}
              >
                Nuevo Cliente{" "}
              </Text>
            </Box>
            <FormControl id="razon">
              <FormLabel>Razón Social</FormLabel>
              <Input
                type="text"
                onChange={(e) => setRazonSocial(e.target.value)}
                placeholder={"Eje. Acme SA de CV"}
              />
            </FormControl>
            <FormControl id="nombre">
              <FormLabel>Nombre comercial</FormLabel>
              <Input
                type="text"
                onChange={(e) => setNombre(e.target.value)}
                placeholder={"Eje. Acme"}
              />
            </FormControl>
            <FormControl id="direccion">
              <FormLabel>Dirección completa</FormLabel>
              <Input
                type="text"
                onChange={(e) => setDireccion(e.target.value)}
                placeholder={"Eje. Querétaro, Querétaro"}
              />
            </FormControl>
            <FormControl id="contacto">
              <FormLabel>Nombre del Contacto</FormLabel>
              <Input
                type="text"
                onChange={(e) => setNombreContacto(e.target.value)}
                placeholder={"Eje. Juan Pérez"}
              />
            </FormControl>
            <FormControl id="correo">
              <FormLabel>Correo del Contacto</FormLabel>
              <Input
                type="email"
                onChange={(e) => setCorreo(e.target.value)}
                placeholder={"Eje. ejemplo@ejemplo.com"}
              />
            </FormControl>
            <FormControl id="telefono">
              <FormLabel>Teléfono del Contacto</FormLabel>
              <Input
                type="phone"
                onChange={(e) => setTelefono(e.target.value)}
                placeholder={"Eje. 55-5555-5555"}
              />
            </FormControl>

            <Box pt={8}>
              <Button
                variant={"outline"}
                colorScheme={"orange"}
                onClick={handleonclick}
              >
                Crear cliente
              </Button>
            </Box>
          </VStack>
        </Center>
      </Box>
    </Box>
  );
};

export default New;
