import React from "react";
import HomeVendedor from "../components/Admin/Clientes/Clientes";
const Home = () => {
  return (
    <>
      <HomeVendedor />
    </>
  );
};

export default Home;
