import { Box } from "@chakra-ui/layout";
import React from "react";
import ProyectosComponent from "../../components/Vendedor/Clientes/Proyectos";
const Proyectos = () => {
  return (
    <Box bg={"gray.50"} h={"100vh"}>
      <ProyectosComponent />
    </Box>
  );
};

export default Proyectos;
