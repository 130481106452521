import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Logo from '../../assets/LOGOS_NCTECH-01.svg'
import LogoA from '../../assets/logo-nctech-1-1.svg'
import Cover from '../../assets/cover.png'
import {Input, VStack,Toast, SimpleGrid,LinkOverlay, HStack, Image, Stack, useToast, Button, 
        Text, Heading, Container, Box, Center, Collapse, Fade, ScaleFade, Slide, SlideFade, 
        useDisclosure, Flex, Spacer, Link } from "@chakra-ui/react"
import { left } from '@popperjs/core';
import { FaLinkedin, FaLinkedinIn, FaWhatsappSquare } from 'react-icons/fa';
import { SiGmail } from 'react-icons/si';
export default function NewPassword() {
    const toast = useToast()
  const [password, setPassword] = useState("")
  const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI)
  const [passwordconfirmacion, setPasswordconfirmacion] = useState("")

  function getUrlParameter(sParam) {
          let sPageURL = decodeURIComponent(window.location.search.substring(1)),
              sURLVariables = sPageURL.split('&'),
              sParameterName,
              i;

          for (i = 0; i < sURLVariables.length; i++) {
              sParameterName = sURLVariables[i].split('=');

              if (sParameterName[0] === sParam) {
                  return sParameterName[1] === undefined ? true : sParameterName[1];
              }
          }
  }

  const forgot = () =>{
    let str = getUrlParameter('code');
    console.log(str)
    axios
      .post(urlStrapi +'/auth/reset-password', {
        code: str, // code contained in the reset link of step 3.
        password: password,
        passwordConfirmation: passwordconfirmacion,
      })
      .then(response => {
        window.location = '/resetpasswordconfirmate'
      })
      .catch(error => {
        console.log('An error occurred:', error.response);
      });

  }
  const toas = () => {
    toast({
    title: "Error.",
    description: "Los campos no pueden estar vacios, verifica y vuelve a intentar.",
    status: "error",
    duration: 5000,
    isClosable: true,
    })
  }
  return(
    <Stack direction={{base:'column', lg:'row'}} m={'0px'} spacing={0}>
    <Box mt={0} visibility={{base:'hidden', lg:'visible'}} w={{base:'0px',lg:'100%'}} 
         h={{base:'0px',lg:"100vh"}} className={'bg-login-color'} borderWidth={{base:'0px' ,lg:'1px'}}>
     <Link href="/">
       <Image src={Logo} w={'132px'} mt={'5'} ml={'10'} position={'absolute'} />
     </Link>
      {/* Logo */}
     
     <Center>
       <Box justifyContent={'center'}>
       <Image src={Cover} w={{lg:'100%'}} h={'100vh'} objectFit={'cover'} />
       </Box>
     </Center>
      {/* Ilustración */}
     <HStack spacing={'10'}>
       <Box bottom={10} position={'absolute'} left={10} visibility={{ base: 'hidden', lg: 'visible' }}>
         <FaWhatsappSquare fontSize={'20pt'} color={'white'} />
       </Box>
       <Spacer />
       <Box bottom={10} position={'absolute'} left={10} visibility={{ base: 'hidden', lg: 'visible' }}>
         <SiGmail fontSize={'20pt'} color={'white'} />
       </Box>
       <Box bottom={10} position={'absolute'} left={20} visibility={{ base: 'hidden', lg: 'visible' }}>
         <FaLinkedin fontSize={'20pt'} color={'white'} />
       </Box>
     </HStack>

      {/* Redes Sociales */}
   </Box>
   {/* Fin primera sección */}
   <Box w={{base: '100%', lg:'60%'}} h="100vh" bg="gray.50" m={0}>
     {/* Inicio-Logo */}
     <Center>
       <Box mt={10}>
         <Image visibility={{base:'visible', lg:'hidden'}} src={LogoA} w={'132px'} mt={'5'} />
       </Box>
      </Center>
      {/* Fin-Logo */}
      {/* Inicio-titulos */}
     <Center mt={{base:24, lg:0, "2xl":24}}>
       <VStack spacing={1}>
         <Text fontFamily={'Roboto'} fontWeight={'bold'} fontSize={{lg:'33px', base: '33px', '2xl': '43px'}} color={'gray.700'}>Nueva de contraseña</Text>
       </VStack>
     </Center>
      {/* Fin-titulos */}
     {/* Inicio-formulario */}
     <Stack mt={{base:10, lg:10}}>
       <form >

         <VStack w={'100%'}  p={3}>
           <Text align={'left'} w={'70%'} color={'gray.600'}>Favor de ingresar una nueva contraseña</Text>
           <Center w={'100%'}>
           <Input h={14} onChange={e => setPassword(e.target.value)} w={'70%'} type="password" 
             placeholder="Password" />
           </Center>
         </VStack>
         <VStack w={'100%'}  p={3}>
           <Text align={'left'} w={'70%'} color={'gray.600'}>Confirma tu nueva contraseña</Text>
           <Center w={'100%'}>
             <Input h={14} onChange={e => setPasswordconfirmacion(e.target.value)} w={'70%'} type="password" 
             placeholder="confirma tú password" />
           </Center>
  
         </VStack>
         <Center mt={10}>
         {password == "" || passwordconfirmacion == "" ? 
             <Button onClick={()=>toas()} h={'60px'} variant={'outline'} w="300px" colorScheme={'red'} fontWeight={'medium'}
               className="font">
               CAMBIAR</Button> :
             <Button onClick={()=>forgot()} h={'60px'}  variant={'outline'} w="300px" bg={'yellow.400'} fontWeight={'medium'}
               className="font">
               CAMBIAR
             </Button>}
         </Center>
       </form>
     </Stack>
     {/* fin-formulario */}
     {/* Inicio Redes Sociales Mobile */}
     <Center>
     <SimpleGrid bottom={14} position={'absolute'} columns={3} spacing={5}>
         <Box visibility={{ base: 'visible', lg: 'hidden' }}>
         <FaWhatsappSquare fontSize={'20pt'} color={'gray'} />
         </Box>
         <Box visibility={{ base: 'visible', lg: 'hidden' }}>
         <SiGmail fontSize={'20pt'} color={'gray'} />
         </Box>
         <Box visibility={{ base: 'visible', lg: 'hidden' }}>
         <FaLinkedin fontSize={'20pt'} color={'gray'} />
         </Box>

       </SimpleGrid>
       
     </Center>
      {/* Fin Redes Sociales */}
   </Box> 
 </Stack>



  )
}
