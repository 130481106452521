import React, { useContext, useState } from "react";
import {
  Spinner,
  Input,
  VStack,
  Toast,
  SimpleGrid,
  LinkOverlay,
  HStack,
  Image,
  Stack,
  useToast,
  Button,
  Text,
  Heading,
  Container,
  Box,
  Center,
  Flex,
  Spacer,
  Link,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Avatar,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  LinkBox,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Hide,
  Tag,
  TagLabel,
} from "@chakra-ui/react";

import Header from "../../Header";
import {
  ChevronDownIcon,
  PhoneIcon,
  AddIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import useUser from "../../context/useUser";
import useToken from "../../useToken";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import MenuAdmin from "../Utilidades/MenuAdmin";
import userEvent from "@testing-library/user-event";
import { useParams } from "react-router-dom";
import {
  BsCalendar2DateFill,
  BsPinMapFill,
  BsPeopleFill,
  BsDot,
  BsTrash,
} from "react-icons/bs";
import { MdEmail, MdAttachFile } from "react-icons/md";
import { HiDocumentText, HiDotsHorizontal, HiPlus } from "react-icons/hi";
import { GoPrimitiveDot } from "react-icons/go";
import Blur from "react-css-blur";
import "antd/dist/antd.css";
import { GrFormDown } from "react-icons/gr";
const Lista = () => {
  const { user, setUser } = useUser();
  const queryClient = useQueryClient();
  const {
    isOpen: isProyectoOpen,
    onOpen: onOpenProyectoModal,
    onClose: onCloseProyectoModal,
  } = useDisclosure();
  const {
    isOpen: isProyectoOpenEdit,
    onOpen: onOpenProyectoModalEdit,
    onClose: onCloseProyectoModalEdit,
  } = useDisclosure();
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [datos, setDatos] = useState([]);
  const [idGerente, setIdGerente] = useState();
  const { token, setToken } = useToken();
  const [blurOn, setBlurOn] = useState(false);
  const [urlNotificacionesRoot, setUrlNotificacionesRoot] = useState(
    process.env.REACT_APP_URL_NOTIFICACIONES_ROOT,
  );
  const fuente =
    "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji";
  const addGerente = async () => {
    //const  url = `http://192.168.0.108:8080/api/setGerentes`
    const url = `${urlNotificacionesRoot}/api/setGerentes`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        nombre,
        correo,
        token: token,
      }),
    });

    //onCloseProyectoModal()
    handlerClickCloseModal();
    sendEmail(nombre, correo);
    return response;
  };

  const sendEmail = async (nombre, correo) => {
    const url = `https://servicesemail-production.herokuapp.com/api/newuser`;
    //const  url = `http://localhost:8080/api/newuser`
    const body = {
      templateId: "d-612962b334954e459cb7f1b11cf59f93", //Cambio de template a producción
      to: correo,
      subject: "asunto",
      usuario: nombre,
    };
    const response = await fetch(url, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    });
    if (response.status == 201) {
      console.log("[MENSAJE ENVIADO]");
    }
  };
  const deleteGerente = async (id) => {
    const urlC = `${urlNotificacionesRoot}/api/deleteGerentes`;
    const response = await fetch(urlC, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        id,
      }),
    });
    // console.log(response)
    onCloseProyectoModal();
    return response;
  };
  const EditarGerente = async () => {
    const urlC = `${urlNotificacionesRoot}/api/updateGerente/${idGerente}`;
    const response = await fetch(urlC, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        nombre: nombre,
        correo: correo,
      }),
    });
    // console.log(response)
    // onCloseProyectoModal()
    handlerClickCloseModalEdit();
    refetch();
    return response;
  };

  function fetchGerentes() {
    const gerentes = fetch(`${urlNotificacionesRoot}/api/getGerentes`).then(
      (response) => response.json(),
    );
    return gerentes;
  }
  const mutation = useMutation(addGerente, {
    onSuccess: () => {
      queryClient.invalidateQueries("GERENTES");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const mutationA = useMutation(deleteGerente, {
    onSuccess: () => {
      queryClient.invalidateQueries("GERENTES");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutationEditar = useMutation(EditarGerente, {
    onSuccess: () => {
      queryClient.invalidateQueries("GERENTES");
      refetch();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleNombre = (e) => {
    setNombre(e.target.value);
  };
  const handleCorreo = (e) => {
    setCorreo(e.target.value);
  };

  const handleAdd = () => {
    mutation.mutate();
  };
  const handleEliminar = (id) => {
    mutationA.mutate(id);
  };
  const handleEditar = () => {
    mutationEditar.mutate();
  };
  const { isLoading, isFetching, data, refetch } = useQuery(
    "GERENTES",
    fetchGerentes,
    {
      onSuccess: (data) => {
        //console.log("data:", data)
        setDatos(data);
      },
    },
  );
  if (isLoading) {
    return (
      <Center mt={"10%"}>
        <Spinner color={"green.700"} />
      </Center>
    );
  }
  const BlurOn = (blurOn) => {
    setBlurOn(blurOn);
  };
  const handlerClickModal = () => {
    onOpenProyectoModal();
    BlurOn(true);
  };
  const handlerClickCloseModal = () => {
    onCloseProyectoModal();
    BlurOn(false);
  };
  const handlerClickModalEdit = async (gerente, nombre, correo) => {
    setNombre(nombre);
    setCorreo(correo);
    setIdGerente(gerente);
    setTimeout(() => {
      onOpenProyectoModalEdit();
    }, 500);
  };
  const handlerClickCloseModalEdit = () => {
    onCloseProyectoModalEdit();
    refetch();
    //BlurOn(false)
  };

  return (
    <>
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isProyectoOpen}
        onClose={handlerClickCloseModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontFamily={fuente}
            fontSize={"15pt"}
            fontWeight={"medium"}
          >
            Nuevo Gerente
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box
              color={"gray.500"}
              fontSize={"sm"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Nombre del Gerente
            </Box>
            <Input
              value={nombre}
              onChange={(e) => handleNombre(e)}
              type={"text"}
              placeholder={"Escribe algo aquí..."}
              color={"gray.500"}
              fontSize={"sm"}
              fontWeight={"medium"}
              fontFamily={fuente}
            />
            <Box
              color={"gray.500"}
              fontSize={"sm"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Correo electrónico
            </Box>
            <Input
              value={correo}
              onChange={(e) => handleCorreo(e)}
              type={"email"}
              placeholder={"Eje. example@example.com..."}
              color={"gray.500"}
              fontSize={"sm"}
              fontWeight={"medium"}
              fontFamily={fuente}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="orange"
              variant={"outline"}
              fontFamily={fuente}
              mr={3}
              onClick={handleAdd}
            >
              Crear
            </Button>
            <Button fontFamily={fuente} onClick={handlerClickCloseModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isProyectoOpenEdit}
        onClose={handlerClickCloseModalEdit}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontFamily={fuente}
            fontSize={"15pt"}
            fontWeight={"medium"}
          >
            Editar Gerente
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box
              color={"gray.500"}
              fontSize={"sm"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Nombre del Gerente
            </Box>
            <Input
              value={nombre}
              onChange={(e) => handleNombre(e)}
              type={"text"}
              placeholder={"Escribe algo aquí..."}
              color={"black"}
              fontSize={"sm"}
              fontWeight={"medium"}
              fontFamily={fuente}
            />
            <Box
              color={"gray.500"}
              fontSize={"sm"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Correo electrónico
            </Box>
            <Input
              value={correo}
              onChange={(e) => handleCorreo(e)}
              type={"email"}
              placeholder={"Eje. example@example.com..."}
              color={"black"}
              fontSize={"sm"}
              fontWeight={"medium"}
              fontFamily={fuente}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="orange"
              variant={"outline"}
              fontFamily={fuente}
              mr={3}
              onClick={handleEditar}
            >
              Guardar
            </Button>
            <Button onClick={handlerClickCloseModalEdit} fontFamily={fuente}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex direction={{ base: "column", lg: "row" }} bg={"pink"} h={"100vh"}>
        <MenuAdmin />
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>
            <Blur radius={blurOn ? "1px" : "0"} transition="400ms">
              <Box bg={"gray.200"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
                <Flex direction={"column"}>
                  <Box bg={"white"} h={{ base: "25vh", lg: "25vh" }}>
                    <Flex direction={{ base: "column", lg: "row" }}>
                      <Box
                        display={{ base: "none", lg: "flex" }}
                        bg={"white"}
                        w={{ base: "0", lg: "20%" }}
                        h={{ base: "0", lg: "25vh" }}
                      ></Box>{" "}
                      {/* Box 1 */}
                      <Box
                        bg={"pink.400"}
                        w={{ base: "100%", lg: "60%" }}
                        h={{ base: "25vh", lg: "25vh" }}
                      >
                        <Flex direction={"column"}>
                          <Box bg={"gray.300"} h={"12.5vh"}>
                            <Flex direction={"row"}>
                              <Box bg={"gray.500"} w={"70%"} h={"12.5vh"}>
                                <Flex direction={"column"}>
                                  <Box bg={"white"} w={"100%"} h={"auto"}>
                                    <Box
                                      alignSelf={"end"}
                                      fontSize={{
                                        lg: "17px",
                                        base: "23px",
                                        "2xl": "33px",
                                      }}
                                      fontWeight={"semibold"}
                                      color={"gray.700"}
                                      fontFamily={fuente}
                                      ml={{ lg: "0", base: "5" }}
                                      mt={{ lg: "8", base: "5" }}
                                    >
                                      ¡Hola! Administrador
                                    </Box>
                                  </Box>
                                  <Box bg={"white"} w={"100%"} h={"auto"}>
                                    <Box
                                      fontFamily={fuente}
                                      fontSize={{
                                        lg: "13px",
                                        base: "15px",
                                        "2xl": "15px",
                                      }}
                                      fontWeight={"medium"}
                                      color={"gray.500"}
                                      ml={{ lg: "0", base: "5" }}
                                      mb={{ lg: "10", base: "0" }}
                                    >
                                      <Link href="#">
                                        {" "}
                                        Listado de Gerentes{" "}
                                      </Link>
                                    </Box>
                                  </Box>
                                </Flex>
                              </Box>
                              <Box bg={"white"} w={"30%"} h={"12.5vh"}>
                                <Flex
                                  direction={"column"}
                                  justifyContent={"end"}
                                >
                                  <Tooltip
                                    fontFamily={fuente}
                                    label="Crear nuevo gerente"
                                    aria-label="A tooltip"
                                    placement="right"
                                    borderRadius={"sm"}
                                  >
                                    <Box
                                      bg={"white"}
                                      w={"100%"}
                                      h={"4.16vh"}
                                      align={"end"}
                                      mt={8}
                                      pr={{ base: 10, lg: 0 }}
                                    >
                                      <Menu>
                                        <MenuButton
                                          px={4}
                                          py={2}
                                          transition="all 0.2s"
                                          borderRadius="md"
                                          borderWidth="1px"
                                          _hover={{
                                            bg: "gray.400",
                                            color: "white",
                                          }}
                                          _expanded={{
                                            bg: "gray.500",
                                            color: "white",
                                          }}
                                          _focus={{ boxShadow: "outline" }}
                                          as={Button}
                                          onClick={handlerClickModal}
                                          fontFamily={fuente}
                                        >
                                          <HiPlus />
                                        </MenuButton>
                                      </Menu>
                                    </Box>
                                  </Tooltip>
                                  <Box
                                    bg={"white"}
                                    w={"100%"}
                                    h={"4.16vh"}
                                  ></Box>
                                  <Box
                                    bg={"white"}
                                    w={"100%"}
                                    h={"4.16vh"}
                                  ></Box>
                                </Flex>
                              </Box>
                            </Flex>
                          </Box>
                          <Box bg={"white"} h={"12.5vh"}>
                            <Center></Center>
                          </Box>
                          <Divider />
                        </Flex>
                      </Box>
                      <Box
                        display={{ base: "none", lg: "flex" }}
                        bg={"white"}
                        w={{ base: "0", lg: "20%" }}
                        h={{ base: "0", lg: "25vh" }}
                      ></Box>{" "}
                      {/* Box 3 */}
                    </Flex>
                  </Box>
                  <Box
                    bg={"white"}
                    h={{ base: "65vh", lg: "65vh" }}
                    pl={{ base: 0, lg: 0 }}
                    pr={{ base: 0, lg: 0 }}
                  >
                    <Flex direction={{ base: "column", lg: "row" }}>
                      <Box
                        display={{ base: "none", lg: "flex" }}
                        bg={"white"}
                        w={{ base: "0", lg: "5%" }}
                        h={{ base: "0", lg: "65vh" }}
                      ></Box>{" "}
                      {/* Box 1 */}
                      <Box
                        bg={"white"}
                        w={{ base: "0", lg: "90%" }}
                        h={{ base: "0", lg: "65vh" }}
                      >
                        <Box bg={"white"} w={"100%"} mt={5}>
                          <Center>
                            <Box
                              rounded={"lg"}
                              borderStyle={"solid"}
                              borderColor={"gray.100"}
                              borderWidth={"1px"}
                              overflow={"hidden"}
                            >
                              <Table
                                variant="simple"
                                colorScheme={"orange"}
                                size={"md"}
                                fontFamily={fuente}
                                p={10}
                              >
                                <Thead>
                                  <Tr>
                                    <Th fontSize={"13px"} fontFamily={fuente}>
                                      Nombre
                                    </Th>
                                    <Th fontSize={"13px"} fontFamily={fuente}>
                                      Email
                                    </Th>
                                    <Th fontSize={"13px"} fontFamily={fuente}>
                                      Estatus
                                    </Th>
                                    <Th fontSize={"13px"} fontFamily={fuente}>
                                      Fecha de Creación
                                    </Th>
                                  </Tr>
                                </Thead>
                                {datos.length > 0 ? (
                                  <Tbody size={"sm"}>
                                    {datos.map((o) => {
                                      return (
                                        <Tr>
                                          <Td
                                            fontSize={"15px"}
                                            color={"gray.600"}
                                            fontWeight={"medium"}
                                            fontFamily={fuente}
                                          >
                                            {o.username}{" "}
                                          </Td>
                                          <Td
                                            fontSize={"15px"}
                                            color={"gray.600"}
                                            fontWeight={"normal"}
                                            fontFamily={fuente}
                                          >
                                            {o.email}
                                          </Td>
                                          <Td
                                            fontSize={"13px"}
                                            color={"gray.600"}
                                            fontWeight={"normal"}
                                            fontFamily={fuente}
                                          >
                                            {o.confirmed.toString() ==
                                            "true" ? (
                                              <Tag colorScheme={"green"}>
                                                Activo
                                              </Tag>
                                            ) : (
                                              <Tag colorScheme={"red"}>
                                                Inactivo
                                              </Tag>
                                            )}
                                          </Td>
                                          <Td
                                            fontSize={"13px"}
                                            color={"gray.600"}
                                            fontWeight={"normal"}
                                            fontFamily={fuente}
                                          >
                                            {o.created_at}{" "}
                                          </Td>
                                          <Td
                                            fontSize={"11px"}
                                            color={"gray.600"}
                                            fontWeight={"normal"}
                                            fontFamily={fuente}
                                          >
                                            <Menu>
                                              <MenuButton
                                                px={4}
                                                py={2}
                                                transition="all 0.2s"
                                                borderRadius="md"
                                                borderWidth="1px"
                                                _hover={{
                                                  bg: "gray.400",
                                                  color: "white",
                                                }}
                                                _expanded={{
                                                  bg: "gray.500",
                                                  color: "white",
                                                }}
                                                _focus={{
                                                  boxShadow: "outline",
                                                }}
                                                as={Button}
                                              >
                                                <GrFormDown />
                                              </MenuButton>
                                              <MenuList>
                                                <MenuItem
                                                  onClick={() =>
                                                    handlerClickModalEdit(
                                                      o.id,
                                                      o.username,
                                                      o.email,
                                                    )
                                                  }
                                                  fontFamily={fuente}
                                                  fontSize={"11pt"}
                                                >
                                                  Editar
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem
                                                  onClick={() =>
                                                    sendEmail(
                                                      o.username,
                                                      o.email,
                                                    )
                                                  }
                                                  fontFamily={fuente}
                                                >
                                                  Reenviar Invitación
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem
                                                  onClick={() =>
                                                    handleEliminar(o.id)
                                                  }
                                                  fontFamily={fuente}
                                                  fontSize={"11pt"}
                                                >
                                                  Eliminar
                                                </MenuItem>
                                              </MenuList>
                                            </Menu>
                                          </Td>
                                        </Tr>
                                      );
                                    })}
                                  </Tbody>
                                ) : (
                                  <Box fontFamily={fuente}>
                                    No hay registro que mostrar
                                  </Box>
                                )}
                              </Table>
                            </Box>
                          </Center>
                        </Box>
                      </Box>
                      {/* Tabla para movil*/}
                      <Box
                        display={{ base: "none", lg: "flex" }}
                        bg={"white"}
                        w={{ base: "0", lg: "5%" }}
                        h={{ base: "0", lg: "65vh" }}
                      ></Box>{" "}
                      {/* Box 3 */}
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Blur>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Lista;
