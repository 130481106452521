import React from 'react';
import {
    Input, VStack, Toast, SimpleGrid, LinkOverlay, HStack, Image, Stack, useToast, Button,
    Text, Heading, Container, Box, Center, Collapse, Fade, ScaleFade, Slide, SlideFade,
    useDisclosure, Flex, Spacer, Link, LinkBox,  Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
} from "@chakra-ui/react"
import ReactGoogleSlides from "react-google-slides";
const Presentaciones = () => {
    return (
        <Box ml={'10'} mt={5}>
            <Breadcrumb>
                <BreadcrumbItem>
                    <BreadcrumbLink href="/">Home</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink href="/presentaciones">Presentaciones</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>

            <Box>
                <ReactGoogleSlides
                width={640}
                height={480}
                slidesLink="https://docs.google.com/presentation/d/1_l6RJoFHC6RRfNVrozUBYoQXmXixHw4feZcb559600A"
                position={1}
                />
            </Box>

        </Box>
    );
};

export default Presentaciones;