import React from 'react';
import {Box, Flex, LinkBox, LinkOverlay, Spacer, Center, Tooltip, Divider} from "@chakra-ui/react"
import { HamburgerIcon, SettingsIcon, CopyIcon, DragHandleIcon, StarIcon } from '@chakra-ui/icons'
import { FaLinkedin, FaLinkedinIn, FaWhatsappSquare } from 'react-icons/fa';
import { BiHomeAlt } from 'react-icons/bi';
import {AiOutlineFundProjectionScreen} from 'react-icons/ai'
import {HiDocumentText} from 'react-icons/hi'
import {BsFillCameraVideoFill} from 'react-icons/bs'
import {FaFileSignature} from 'react-icons/fa'
const MenuCliente = () => {
    return (
        <Box borderRightWidth={1} bg={'gray.50'} w={{base:'100%', lg: '5%'}} h={{base: '10vh', lg:'100vh'}} position={'fixed'} zIndex={'9999'}> 
            <Flex direction={'column'} h={'100vh'} >
               
                        <Box as='h3' fontFamily={'roboto'} fontSize={'25px'} textAlign={'center'} h={'10vh'}>
                            
                        </Box>
                
                <Tooltip label="Está opción regresa al inicio" aria-label='A tooltip' placement='right' borderRadius={'sm'}>
                <LinkBox bg={'gray.50'} w={'100%'} pt={{lg:4, '2xl': 8}} pb={8} _hover={{'bg':'gray.200'}} h={'10vh'}>
                    <LinkOverlay href='/cliente'>
                            <Center>
                                <Box as='h3' fontFamily={'roboto'} fontSize={'25px'} align={'center'}>
                                    <BiHomeAlt />
                                </Box>
                            </Center>
                    </LinkOverlay>
                </LinkBox>
                </Tooltip>
               
                <Spacer h={'40vh'} />
                <Divider />
                <Tooltip label="Configuración" aria-label='A tooltip' placement='right' borderRadius={'sm'}>
                    <LinkBox bg={'gray.50'} w={'100%'} h={'10vh'} pt={{lg:4, '2xl': 8}} pb={8} _hover={{ 'bg': 'gray.100', 'color':'gray.700' }}>
                        <LinkOverlay href='/perfil'>
                            <Center>
                                <Box as='h3' fontFamily={'roboto'} fontSize={'25px'} align={'center'}>
                                    <SettingsIcon />
                                </Box>
                            </Center>
                        </LinkOverlay>
                    </LinkBox>
                </Tooltip>
            </Flex>
        </Box>
    );
};

export default MenuCliente;