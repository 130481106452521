import React, { useContext, useState } from "react";
import {
  Spinner,
  Input,
  VStack,
  Toast,
  SimpleGrid,
  LinkOverlay,
  HStack,
  Image,
  Stack,
  useToast,
  Button,
  Text,
  Heading,
  Container,
  Box,
  Center,
  Collapse,
  Fade,
  ScaleFade,
  Slide,
  SlideFade,
  useDisclosure,
  Flex,
  Spacer,
  Link,
  LinkBox,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Avatar,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Tooltip,
} from "@chakra-ui/react";
import Header from "../../components/Header";
import { PhoneIcon, AddIcon, WarningIcon } from "@chakra-ui/icons";
import Pattern1 from "../../assets/proyecto.avif";
import Pattern2 from "../../assets/patterns2.jpeg";
import Documents from "../../assets/Documents.avif";
import Presentations from "../../assets/presentations.avif";
import Public from "../../assets/Public.jpeg";
import Pattern3 from "../../assets/patterns3.svg";
import useUser from "../context/useUser";
import useToken from "../useToken";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import MenuGerente from "./Utilidades/MenuGerente";
import { Hidden } from "@material-ui/core";
import CoverCrear from "../../assets/proyecto.jpeg";
const Home = () => {
  const queryClient = useQueryClient();
  const [datos, setDatos] = useState([]);
  const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI);
  const { token, setToken } = useToken();
  const [clientes, setClientes] = useState([]);
  const { user, setUser } = useUser();
  const fuente =
    "Montserrat";
  async function fetchClientes() {
    const clientes = fetch(urlStrapi + "/users?type=2").then((response) =>
      response.json(),
    );
    return clientes;
  }

  async function fetchCliente(b) {
    const urlC = `${urlStrapi}/users?username_contains=${b}`;
    const ventasData = await axios.get(urlC, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const dat = ventasData;
    setDatos(dat.data);
    // console.log(dat)
    if (b === "") {
      refetch();
    }
    return dat;
  }
  const { isLoading, isFetching, data, refetch } = useQuery(
    "CLIENTES",
    fetchClientes,
    {
      onSuccess: (data) => {
        console.log("data:", data);
        setDatos(data);
      },
      cacheTime: Infinity,
    },
  );
  if (isLoading) {
    return (
      <Center mt={"10%"}>
        <Spinner color={"green.700"} />
      </Center>
    );
  }
  const addCliente = () => {
    window.location.href = "/vendedor/clientes/new";
  };
  function buscar(e) {
    fetchCliente(e);
  }
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      fetchCliente(event.target.value);
    }
  };

  return (
    <>
      <Flex
        direction={{ base: "column", lg: "row" }}
        bg={"gray.50"}
        h={"100vh"}
      >
        <MenuGerente />
        <Box
          bg={"gray.50"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>

            <Center mt={{ base: "15", "2xl": 28, lg: 16 }}>
              <Flex direction={"column"}>
                <Box
                  my={"10"}
                  fontSize={"2.0rem"}
                  fontFamily={fuente}
                  fontWeight={"bold"}
                  color={"gray.600"}
                >
                  ¡Hola! {user.username}
                </Box>
                <VStack>
                  <Center>
                    <SimpleGrid
                      columns={{ base: 1, lg: 3, "2xl": 3 }}
                      spacing={4}
                      w={"100%"}
                    >
                      <LinkBox
                        bg={"white"}
                        borderWidth={"1px"}
                        w={"100%"}
                        h={"250px"}
                        borderRadius={"lg"}
                        _hover={{ bg: "gray.100" }}
                        overflow={"hidden"}
                      >
                        <LinkOverlay href="/gerente/crear_proyecto">
                          <Image
                            src={CoverCrear}
                            alt="Chakra Logo"
                            objectFit={"cover"}
                            h={"50%"}
                            w={"100%"}
                          />
                          <Text
                            ml={3}
                            mt={6}
                            fontWeight={"bold"}
                            fontSize={"1.3rem"}
                            fontFamily={fuente}
                            color={"gray.700"}
                          >
                            Crear un Proyecto
                          </Text>
                          <Text
                            ml={3}
                            mt={0}
                            fontWeight={"regular"}
                            fontSize={".9rem"}
                            fontFamily={fuente}
                            color={"gray.500"}
                          >
                            Crear un proyecto{" "}
                          </Text>
                        </LinkOverlay>
                      </LinkBox>
                      <LinkBox
                        bg={"white"}
                        borderWidth={"1px"}
                        w={"100%"}
                        h={"250px"}
                        borderRadius={"lg"}
                        _hover={{ bg: "gray.100" }}
                        overflow={"hidden"}
                      >
                        <LinkOverlay href="/gerente/proyectos">
                          <Image
                            src={Pattern1}
                            alt="Chakra Logo"
                            objectFit={"cover"}
                            h={"50%"}
                            w={"100%"}
                          />
                          <Text
                            ml={3}
                            mt={6}
                            fontWeight={"bold"}
                            fontSize={"1.3rem"}
                            fontFamily={fuente}
                            color={"gray.700"}
                          >
                            Resumen de Proyectos
                          </Text>
                          <Text
                            ml={3}
                            mt={0}
                            fontWeight={"regular"}
                            fontSize={".9rem"}
                            fontFamily={fuente}
                            color={"gray.500"}
                          >
                            Resumen de proyectos
                          </Text>
                        </LinkOverlay>
                      </LinkBox>
                      <LinkBox
                        bg={"white"}
                        borderWidth={"1px"}
                        w={"100%"}
                        h={"250px"}
                        borderRadius={"lg"}
                        _hover={{ bg: "gray.100" }}
                        overflow={"hidden"}
                      >
                        <LinkOverlay href="/gerente/graficas">
                          <Image
                            src={Pattern2}
                            alt="Chakra Logo"
                            objectFit={"cover"}
                            h={"50%"}
                            w={"100%"}
                          />
                          <Text
                            ml={3}
                            mt={6}
                            fontWeight={"bold"}
                            color={"gray.600"}
                            fontSize={"1.3rem"}
                            fontFamily={fuente}
                          >
                            Graficas de Análisis
                          </Text>
                          <Text
                            ml={3}
                            mt={0}
                            fontWeight={"regular"}
                            fontSize={".9rem"}
                            fontFamily={fuente}
                            color={"gray.500"}
                          >
                            Graficas de Análisis
                          </Text>
                        </LinkOverlay>
                      </LinkBox>

                      <LinkBox
                        bg={"white"}
                        borderWidth={"1px"}
                        w={"100%"}
                        h={"250px"}
                        borderRadius={"lg"}
                        _hover={{ bg: "gray.100" }}
                        overflow={"hidden"}
                      >
                        <LinkOverlay href="/gerente/presentaciones">
                          <Image
                            src={Presentations}
                            alt="Chakra Logo"
                            objectFit={"cover"}
                            h={"50%"}
                            w={"100%"}
                          />
                          <Text
                            ml={3}
                            mt={6}
                            fontWeight={"bold"}
                            fontSize={"1.3rem"}
                            fontFamily={fuente}
                          >
                            Biblioteca de Presentaciones
                          </Text>
                          <Text
                            ml={3}
                            mt={1}
                            fontWeight={"regular"}
                            fontSize={".9rem"}
                            fontFamily={fuente}
                            color={"gray.500"}
                          >
                            Crea y comparte nuevas presentaciones de manera
                            rápida y sencilla
                          </Text>
                        </LinkOverlay>
                      </LinkBox>

                      <LinkBox
                        bg={"white"}
                        borderWidth={"1px"}
                        w={"100%"}
                        h={"250px"}
                        borderRadius={"lg"}
                        _hover={{ bg: "gray.100" }}
                        overflow={"hidden"}
                      >
                        <LinkOverlay href="/gerente/propuestas">
                          <Image
                            src={Documents}
                            alt="Chakra Logo"
                            objectFit={"cover"}
                            h={"50%"}
                            w={"100%"}
                          />
                          <Text
                            ml={3}
                            mt={6}
                            fontWeight={"bold"}
                            fontSize={"1.3rem"}
                            fontFamily={fuente}
                          >
                            Biblioteca de Propuestas
                          </Text>
                          <Text
                            ml={3}
                            mt={1}
                            fontWeight={"regular"}
                            fontSize={".9rem"}
                            fontFamily={fuente}
                            color={"gray.500"}
                          >
                            Crea y comparte nuevas propuestas de manera rápida y
                            sencilla
                          </Text>
                        </LinkOverlay>
                      </LinkBox>

                      <LinkBox
                        bg={"white"}
                        borderWidth={"1px"}
                        w={"100%"}
                        h={"250px"}
                        borderRadius={"lg"}
                        _hover={{ bg: "gray.100" }}
                        overflow={"hidden"}
                      >
                        <LinkOverlay href="/gerente/attachments">
                          <Image
                            src={Public}
                            alt="Chakra Logo"
                            objectFit={"cover"}
                            h={"50%"}
                            w={"100%"}
                          />
                          <Text
                            ml={3}
                            mt={6}
                            fontWeight={"bold"}
                            fontSize={"1.3rem"}
                            fontFamily={fuente}
                          >
                            Biblioteca de Archivos Públicos
                          </Text>
                          <Text
                            ml={3}
                            mt={1}
                            fontWeight={"regular"}
                            fontSize={".9rem"}
                            fontFamily={fuente}
                            color={"gray.500"}
                          >
                            Crea y comparte archivos de manera rápida y sencilla
                          </Text>
                        </LinkOverlay>
                      </LinkBox>
                    </SimpleGrid>
                  </Center>
                </VStack>
              </Flex>
            </Center>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Home;
