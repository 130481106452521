import React from 'react';
import Forgot from '../components/Login/Forgot'

const ForgotPassword = () => {
    return (
        <div>
            <Forgot/>
        </div>
    );
};

export default ForgotPassword;