// import React , {useState} from 'react';
// import { uploadFile } from 'react-s3';
// import { Button,
//      Box,
//      Flex
// } from "@chakra-ui/react"
// import {BiSlideshow} from 'react-icons/bi'
// import { useMutation, useQueryClient} from 'react-query'
// import useUser from './context/useUser';

// const S3_BUCKET ='nctech-imagenes';
// const REGION ='us-east-1';
// const ACCESS_KEY ='AKIARVGY33F62XNKJJK3';
// const SECRET_ACCESS_KEY ='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';

// const config = {
//     bucketName: S3_BUCKET,
//     region: REGION,
//     accessKeyId: ACCESS_KEY,
//     secretAccessKey: SECRET_ACCESS_KEY,
// }

// const UploadContratotoS3 = (props) => {
//     const [urlCassandra, setUrlCassandra] = useState(process.env.REACT_APP_URL_CASSANDRA)
//     const [tokenCasandra, setTokenCassandra] = useState(process.env.REACT_APP_TOKEN_CASSANDRA)
//     const [selectedFile, setSelectedFile] = useState(null);
//     const [fileName, setFileName] = useState(null);
//     const { user, setUser } = useUser()
//     const queryClient = useQueryClient();
//     const [progress , setProgress] = useState(0);
//     const [cargando, setCargando] = useState(false)
//     const crearContratos = async(data) => {

//       const url= `${urlCassandra}collections/contratos${user.id.toString()}`
//         const response = await fetch(url, {
//             method: 'POST',
//             headers: {
//                 'accept': 'application/json',
//                 'Content-Type': 'application/json',
//                 'X-Cassandra-Token': tokenCasandra
//             },
//             body: JSON.stringify({
//                 nombre: props.nombre,
//                 fechaCreacion: Date.now(),
//                 status: "Activo",
//                 url: data.location,
//                 provider: "s3"
//             })
//         })

//         if(response.status == 500){
//             console.log("Segundo Intento")
//             const url = `${urlCassandra}collections/contratos${user.id.toString()}`
//             const response = await fetch(url, {
//             method: 'POST',
//             headers: {
//                 'accept': 'application/json',
//                 'Content-Type': 'application/json',
//                 'X-Cassandra-Token': tokenCasandra
//             },
//             body: JSON.stringify({
//                 nombre: props.nombre,
//                 fechaCreacion: Date.now(),
//                 status: "Activo",
//                 url: data.location,
//                 provider: "s3"
//             })
//         })
//         //

//         }
//         props.query(data.location)
//         //console.log(data.location)
//         return response

//     }
//     const mutation = useMutation(crearContratos, {
//         onSuccess: () => {
//             queryClient.invalidateQueries('CONTRATOS')
//         },
//         onSettled:() => {
//            // props.query()
//             //props.cerrar()
//         },
//         onError: (error) => {
//             console.log(error)
//         }
//     })

//     const handleFileInput = (e) => {
//         setSelectedFile(e.target.files[0]);
//         setFileName(e.target.files[0].name);
//         handleUpload(e.target.files[0])
//     }

//     const handleUpload = async (file) => {
//         uploadFile(file, config)
//             .then(data =>
//                 mutation.mutate(data),
//                 setCargando(true)
//                //console.log(data)
//             )
//             .catch(err => console.error(err))
//     }

//     return (
//         <Box>
//             <label onChange={handleFileInput} htmlFor='fileContratos' >
//                 <input name="" type="file"  id='fileContratos' hidden accept=".pdf"/>
//                 <Flex my={6} cursor={'pointer'} justify={'end'}>
//                 <Box alignSelf={'center'} >
//                 {fileName ?
//                 <svg width={'24px'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20 10.9696L11.9628 18.5497C10.9782 19.4783 9.64274 20 8.25028 20C6.85782 20 5.52239 19.4783 4.53777 18.5497C3.55315 17.6211 3 16.3616 3 15.0483C3 13.7351 3.55315 12.4756 4.53777 11.547L12.575 3.96687C13.2314 3.34779 14.1217 3 15.05 3C15.9783 3 16.8686 3.34779 17.525 3.96687C18.1814 4.58595 18.5502 5.4256 18.5502 6.30111C18.5502 7.17662 18.1814 8.01628 17.525 8.63535L9.47904 16.2154C9.15083 16.525 8.70569 16.6989 8.24154 16.6989C7.77738 16.6989 7.33224 16.525 7.00403 16.2154C6.67583 15.9059 6.49144 15.4861 6.49144 15.0483C6.49144 14.6106 6.67583 14.1907 7.00403 13.8812L14.429 6.88674" stroke="#06d6a0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
//                 :
//                 <svg width={'24px'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20 10.9696L11.9628 18.5497C10.9782 19.4783 9.64274 20 8.25028 20C6.85782 20 5.52239 19.4783 4.53777 18.5497C3.55315 17.6211 3 16.3616 3 15.0483C3 13.7351 3.55315 12.4756 4.53777 11.547L12.575 3.96687C13.2314 3.34779 14.1217 3 15.05 3C15.9783 3 16.8686 3.34779 17.525 3.96687C18.1814 4.58595 18.5502 5.4256 18.5502 6.30111C18.5502 7.17662 18.1814 8.01628 17.525 8.63535L9.47904 16.2154C9.15083 16.525 8.70569 16.6989 8.24154 16.6989C7.77738 16.6989 7.33224 16.525 7.00403 16.2154C6.67583 15.9059 6.49144 15.4861 6.49144 15.0483C6.49144 14.6106 6.67583 14.1907 7.00403 13.8812L14.429 6.88674" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
//                 }
//                 </Box>
//                   {fileName ?
//                 <Box alignContent={'center'} ml={2} fontSize={'sm'} fontWeight={'medium'} color={'gray.800'} fontFamily={'system-ui, sans-serif'}>
//                     {fileName}
//                 </Box>
//                 :
//                 <Box alignContent={'center'} ml={2} fontSize={'sm'} fontWeight={'medium'} color={'gray.500'} fontFamily={'system-ui, sans-serif'}>Contrato</Box>
//                  }
//                 </Flex>
//             </label>
//             {/* {fileName ?
//                 <Button isLoading={cargando} fontSize={'sm'} fontWeight={'medium'} color={'gray.800'} fontFamily={'system-ui, sans-serif'} bg={'orange'} variant={'outline'} onClick={() => handleUpload(selectedFile)}> Subir propuesta </Button>
//             :
//                 <Button fontSize={'sm'} fontWeight={'medium'} color={'gray.800'} fontFamily={'system-ui, sans-serif'} bg={'orange'} variant={'outline'} disabled> Subir propuesta </Button>
//             } */}
//         </Box>
//     )
// }

// export default UploadContratotoS3;

import React, { useState } from "react";
import { uploadFile } from "react-s3";
import {
  Spinner,
  Input,
  VStack,
  Toast,
  SimpleGrid,
  LinkOverlay,
  HStack,
  Image,
  Stack,
  useToast,
  Button,
  Text,
  Heading,
  Container,
  Box,
  Center,
  Collapse,
  Fade,
  ScaleFade,
  Slide,
  SlideFade,
  useDisclosure,
  Flex,
  Spacer,
  Link,
  LinkBox,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Avatar,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Progress,
} from "@chakra-ui/react";
import { BiSlideshow } from "react-icons/bi";
import props from "prop-types";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useUser from "./context/useUser";
import axios from "axios";
import * as AWS from "aws-sdk";
AWS.config.update({
  region: "us-east-1",
  secretAccessKey: "3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi",
  accessKeyId: "AKIARVGY33F62XNKJJK3",
}); //PRODUCCION
//AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' }); //DESARROLLO
const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: "2022-01-01" });
const S3_BUCKET = "nctech-imagenes";
const REGION = "us-east-1";
const ACCESS_KEY = "AKIARVGY33F62XNKJJK3";
const SECRET_ACCESS_KEY = "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";

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

const UploadImageToS3WithReactS3 = (props) => {
  const [urlCassandra, setUrlCassandra] = useState(
    process.env.REACT_APP_URL_CASSANDRA,
  );
  const [tokenCasandra, setTokenCassandra] = useState(
    process.env.REACT_APP_TOKEN_CASSANDRA,
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const { user, setUser } = useUser();
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState(0);
  const [cargando, setCargando] = useState(false);

  const crearContratos = async (data) => {
    //const urlC = `${urlCassandra}collections/contratos${user.id.toString()}`;
    const urlC = "https://servicesemail-production.herokuapp.com/api/contratos";
    const body = {
      nombre: props.nombre,
      tipo: props.tipoContrato,
      fechaEmision: props.fechaEmision,
      fechaCreacion: Date.now(),
      status: "Activo",
      url: data.location,
      provider: "s3",
    };
    let result = null;

    try {
      const response = await fetch(urlC, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.status === 500) {
        console.log("Segundo Intento");
        const response = await fetch(urlC, {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
      }

      const responseData = await response.json();
      //   console.log("[RESPUESTA]", responseData)
      //    const { proyecto, presentacionId } = responseData;
      //    const id = `${presentacionId}_${proyecto}`;
      const { url } = body;
      //    result = { proyecto, presentacionId, id, url };
      //    console.log('[RESPONSE CASSANDRA]', responseData);
      props.query();

      if (props.tipo == "proyecto") {
        addContratos(responseData.documentId, "visible", props.nombre, url);
      }
    } catch (error) {
      console.error("[ERROR CASSANDRA]", error);
      throw error;
    } finally {
      setCargando(false);
      setFileName("");
    }

    //return result;
  };

  const putData_contratos = (tableName, data) => {
    var params = {
      TableName: tableName,
      Item: data,
    };

    docClient.put(params, function (err, data) {
      if (err) {
        console.log("Error", err);
      } else {
        props.refetchContratos();
      }
    });
  };
  const addContratos = async (ids, estatus, nombre, url) => {
    //console.log("paso", paso, "id:", ids + id, "estado:", estatus)
    const contratosData = {
      id: ids + props.id,
      contratoId: ids,
      estatus: estatus,
      fechaCreacion: Date.now(),
      proyecto: props.id,
      nombre: nombre,
      url,
      cliente: props.proyecto,
      novisible: [],
    };
    console.log("[ESTO ES LO QUE INSERTARA]", contratosData);
    await putData_contratos("Contratos", contratosData);
  };

  const mutation = useMutation(crearContratos, {
    onSuccess: () => {
      queryClient.invalidateQueries("CONTRATOS");

      //console.log("Listo")
    },
    onSettled: () => {
      props.query();
      props.cerrar();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleUpload = async (file) => {
    setCargando(true);
    uploadFile(file, config)
      .then(
        (data) => mutation.mutate(data),

        //console.log(data)
      )
      .catch((err) => console.error(err));
  };

  return (
    <Box>
      <label onChange={handleFileInput} htmlFor="fileContrato">
        <input name="" type="file" id="fileContrato" hidden accept=".pdf" />
        <Flex my={6} cursor={"pointer"}>
          <Box alignSelf={"center"}>
            <BiSlideshow size={"20px"} color={"gray.500"} />
          </Box>
          <Box
            alignContent={"center"}
            ml={2}
            fontSize={"sm"}
            fontWeight={"medium"}
            color={"gray.500"}
            fontFamily={"system-ui, sans-serif"}
          >
            Clic aquí para seleccionar tu archivo
          </Box>
          <Box
            alignContent={"center"}
            ml={2}
            fontSize={"sm"}
            fontWeight={"medium"}
            color={"gray.800"}
            fontFamily={"system-ui, sans-serif"}
          >
            {fileName}
          </Box>
        </Flex>
      </label>
      {fileName != null ? (
        <Button
          isLoading={cargando}
          fontSize={"sm"}
          fontWeight={"medium"}
          color={"gray.800"}
          fontFamily={"system-ui, sans-serif"}
          bg={"orange"}
          variant={"outline"}
          onClick={() => handleUpload(selectedFile)}
        >
          {" "}
          Subir Contrato{" "}
        </Button>
      ) : (
        <Button
          fontSize={"sm"}
          fontWeight={"medium"}
          color={"gray.800"}
          fontFamily={"system-ui, sans-serif"}
          bg={"orange"}
          variant={"outline"}
          disabled
        >
          {" "}
          Subir Contrato{" "}
        </Button>
      )}
    </Box>
  );
};

export default UploadImageToS3WithReactS3;
