import React from "react";
import {
  Box,
  Flex,
  LinkBox,
  LinkOverlay,
  Spacer,
  Center,
  Tooltip,
  Divider,
} from "@chakra-ui/react";
import {Home,Presentation, File, FileKey, ScrollText , Video, Youtube} from 'lucide-react'
const MenuVendedor = () => {
  return (
    <Box
      borderRightWidth={1}
      bg={"gray.50"}
      w={{ base: "100%", lg: "5%" }}
      h={{ base: "10vh", lg: "100vh" }}
      position={"fixed"}
      zIndex={"9999"}
    >
      <Flex direction={"column"} h={"100vh"}>
        <Box
          as="h3"
          fontFamily={"roboto"}
          fontSize={"25px"}
          textAlign={"center"}
          h={"10vh"}
        ></Box>

        <Tooltip
          label="Está opción regresa al inicio"
          aria-label="A tooltip"
          placement="right"
          borderRadius={"sm"}
        >
          <LinkBox
            bg={"gray.50"}
            w={"100%"}
            pt={{ lg: 4, "2xl": 8 }}
            pb={8}
            _hover={{ bg: "gray.200" }}
            h={"10vh"}
          >
            <LinkOverlay href="/inicio">
              <Center>
                <Box
                  as="h3"
                  fontFamily={"roboto"}
                  fontSize={"25px"}
                  align={"center"}
                >
                  <Home />
                </Box>
              </Center>
            </LinkOverlay>
          </LinkBox>
        </Tooltip>
        <Tooltip
          label="Biblioteca de Presentaciones"
          aria-label="A tooltip"
          placement="right"
        >
          <LinkBox
            bg={"gray.50"}
            w={"100%"}
            pt={{ lg: 4, "2xl": 8 }}
            pb={8}
            _hover={{ bg: "gray.200" }}
            h={"10vh"}
          >
            <LinkOverlay href="/vendedor/presentaciones">
              <Center>
                <Box
                  as="h3"
                  fontFamily={"roboto"}
                  fontSize={"25px"}
                  align={"center"}
                >
                  <Presentation />
                </Box>
              </Center>
            </LinkOverlay>
          </LinkBox>
        </Tooltip>
        <Tooltip
          label="Biblioteca de Propuestas"
          aria-label="A tooltip"
          placement="right"
          borderRadius={"sm"}
        >
          <LinkBox
            bg={"gray.50"}
            w={"100%"}
            pt={{ lg: 4, "2xl": 8 }}
            pb={8}
            _hover={{ bg: "gray.200" }}
            h={"10vh"}
          >
            <LinkOverlay href="/vendedor/propuestas">
              <Center>
                <Box
                  as="h3"
                  fontFamily={"roboto"}
                  fontSize={"25px"}
                  align={"center"}
                >
                  <File />
                </Box>
              </Center>
            </LinkOverlay>
          </LinkBox>
        </Tooltip>
        <Tooltip
          label="Biblioteca de Archivos Públicos"
          aria-label="A tooltip"
          placement="right"
          borderRadius={"sm"}
        >
          <LinkBox
            bg={"gray.50"}
            w={"100%"}
            pt={{ lg: 4, "2xl": 8 }}
            pb={8}
            _hover={{ bg: "gray.200" }}
            h={"10vh"}
          >
            <LinkOverlay href="/vendedor/attachments">
              <Center>
                <Box
                  as="h3"
                  fontFamily={"roboto"}
                  fontSize={"25px"}
                  align={"center"}
                >
                  <FileKey />
                </Box>
              </Center>
            </LinkOverlay>
          </LinkBox>
        </Tooltip>
        <Tooltip
          label="Biblioteca de Contratos"
          aria-label="A tooltip"
          placement="right"
          borderRadius={"sm"}
        >
          <LinkBox
            bg={"gray.50"}
            w={"100%"}
            pt={{ lg: 4, "2xl": 8 }}
            pb={8}
            _hover={{ bg: "gray.200" }}
            h={"10vh"}
          >
            <LinkOverlay href="/vendedor/contratos">
              <Center>
                <Box
                  as="h3"
                  fontFamily={"roboto"}
                  fontSize={"25px"}
                  align={"center"}
                >
                  <ScrollText />
                </Box>
              </Center>
            </LinkOverlay>
          </LinkBox>
        </Tooltip>
        <Tooltip
          label="Videos de Bienvenida"
          aria-label="A tooltip"
          placement="right"
          borderRadius={"sm"}
        >
          <LinkBox
            bg={"gray.50"}
            w={"100%"}
            pt={{ lg: 4, "2xl": 8 }}
            pb={8}
            _hover={{ bg: "gray.200" }}
            h={"10vh"}
          >
            <LinkOverlay href="/vendedor/media">
              <Center>
                <Box
                  as="h3"
                  fontFamily={"roboto"}
                  fontSize={"25px"}
                  align={"center"}
                >
                  <Video />
                </Box>
              </Center>
            </LinkOverlay>
          </LinkBox>
        </Tooltip>
        <Tooltip
          label="Biblioteca de Videos"
          aria-label="A tooltip"
          placement="right"
          borderRadius={"sm"}
        >
          <LinkBox
            bg={"gray.50"}
            w={"100%"}
            pt={{ lg: 4, "2xl": 8 }}
            pb={8}
            _hover={{ bg: "gray.200" }}
            h={"10vh"}
          >
            <LinkOverlay href="/vendedor/videos">
              <Center>
                <Box
                  as="h3"
                  fontFamily={"roboto"}
                  fontSize={"25px"}
                  align={"center"}
                >
                 <Youtube />
                </Box>
              </Center>
            </LinkOverlay>
          </LinkBox>
        </Tooltip>
        <Spacer h={"40vh"} />
        <Divider />
        {/* <Tooltip label="Configuración" aria-label='A tooltip' placement='right' borderRadius={'sm'}>
                    <LinkBox bg={'gray.50'} w={'100%'} h={'10vh'} pt={{lg:4, '2xl': 8}} pb={8} _hover={{ 'bg': 'gray.100', 'color':'gray.700' }}>
                        <LinkOverlay href='/vendedor/perfil'>
                            <Center>
                                <Box as='h3' fontFamily={'roboto'} fontSize={'25px'} align={'center'}>
                                    <SettingsIcon />
                                </Box>
                            </Center>
                        </LinkOverlay>
                    </LinkBox>
                </Tooltip> */}
      </Flex>
    </Box>
  );
};

export default MenuVendedor;
