import React from "react";
import HomeAdmin from "../components/Admin/Home";
const Home = () => {
  return (
    <>
      <HomeAdmin />
    </>
  );
};

export default Home;
