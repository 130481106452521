import React from "react";
import Proyectos from "../../components/Vendedor/Clientes/HomeProyectos";

const Detalles = () => {
  return (
    <div>
      <Proyectos />
    </div>
  );
};

export default Detalles;
