import React, { useState } from 'react';
import {Spinner, 
    Input,  Button,
    Text,  Box, Center,
    useDisclosure, Flex, Link,
    Divider,
    Tooltip,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    
} from "@chakra-ui/react"
import axios from 'axios';
import MenuAdmin from './MenuAdmin';
import Header from '../../Header';
import {useParams} from "react-router-dom";
import {HiDocumentText, HiDotsHorizontal} from 'react-icons/hi'
import useUser from '../../context/useUser';
import useToken from '../../useToken';
import { useQuery, useMutation, useQueryClient} from 'react-query'
import { PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import Moment from 'react-moment';
import 'moment-timezone';
import {MdDeleteForever} from 'react-icons/md'

const Configuracion = () => {
    const lineasIDRef = React.useRef()
    let { id } = useParams();
    const [cliente, setCliente] = useState();
    const queryClient = useQueryClient();
    const {
        isOpen: isLineaModalOpen,
        onOpen: onOpenContactoModal,
        onClose: onCloseLineaModal,
    } = useDisclosure();
    const {
        isOpen: isCategoriaModalOpen,
        onOpen: onOpenCategoriaModal,
        onClose: onCloseCategoriaModal,
    } = useDisclosure();
    const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI)
    const [urlCassandra, setUrlCassandra] = useState(process.env.REACT_APP_URL_CASSANDRA)
    const [tokenCasandra, setTokenCassandra] = useState(process.env.REACT_APP_TOKEN_CASSANDRA)
    const { user, setUser } = useUser()
    const [categorias, setCategorias] = useState('')
    const [lineaId, setLineaId] = useState('')
    const [nombreBucket, setNombreBucket] = useState()
    const [nombreRegion, setNombreRegion] = useState()
    const [secretname, setSecretname] = useState()
    const [accessname, setAccessname] = useState()
    const [datos, setDatos] = useState([])
   
    async function fetchConfiguracion() {
        const urlC = `${urlCassandra}collections/configAmazon?page-size=1`
        const ventasData = await axios.get(urlC, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Cassandra-Token': tokenCasandra
            }
        })
        const lineas =  await Object.keys(ventasData.data.data).map((item) => ({id: item, ...ventasData.data.data[item]}))
        
        return lineas
    }

    const updateConfiguracionAmazon = async(id) =>{
        console.log("SINID:" , lineaId)
        const urlC = `${urlCassandra}collections/configAmazon/${id}`
        const response = await fetch(urlC, {
            method: 'PUT',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Cassandra-Token': tokenCasandra
            },
            body: JSON.stringify({
                nombreBucket,
                nombreRegion,
                secretname,
                accessname,
                fechaCreacion: Date.now(),          
            })
            
        })
      
        return response
    }



    const crearConfiguracionAmazon = async() =>{
        console.log("SINID:" , lineaId)
        const urlC = `${urlCassandra}collections/configAmazon`
        const response = await fetch(urlC, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Cassandra-Token': tokenCasandra
            },
            body: JSON.stringify({
                nombreBucket,
                nombreRegion,
                secretname,
                accessname,
                fechaCreacion: Date.now(),          
            })
            
        })
        if(response.status === 500) {
            console.log('segundo intento')
            const urlC = `${urlCassandra}collections/configAmazon`
            const response = await fetch(urlC, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Cassandra-Token': tokenCasandra
                },
                body: JSON.stringify({
                    nombreBucket,
                    nombreRegion,
                    secretname,
                    accessname,
                    fechaCreacion: Date.now(),          
                })
                
            })   
        }
        return response
    }
    const mutation= useMutation(crearConfiguracionAmazon, {
        onSuccess: () => {
            queryClient.invalidateQueries('CONFIGURACION')
        },
        onError: (error) => {
            console.log(error)
        }
    })
    const mutation_b= useMutation(updateConfiguracionAmazon, {
        onSuccess: () => {
            queryClient.invalidateQueries('CONFIGURACION')
        },
        onError: (error) => {
            console.log(error)
        }
    })
    function guardarCambio(){
        mutation.mutate()
    }
    function actualizarCambio(id){
        mutation_b.mutate(id)
    }

    const {isLoading, isFetching, data, refetch  } = useQuery('CONFIGURACION', fetchConfiguracion, {
        onSuccess: (data) => {
            console.log("data:", data[0].nombreBucket)
            setDatos(data[0])
        },
       // refetchInterval: 60000,
        //refetchIntervalInBackground: true
    })
    if (isLoading) {
        return "Cargando"
    }


    
    function handlerNombreBucket(e){
       setNombreBucket(e)
    }
    function handlerRegion(e){
        setNombreRegion(e)
     }
     function handlerSecret(e){
        setSecretname(e)
     }
     function handlerAccess(e){
        setAccessname(e)
     }
    return (
        <>
            {/* ModalLinea */}
        <Flex direction={{base:'column', lg:'row'}} bg={'pink'} h={'100vh'}>
            <MenuAdmin />
            <Box bg={'yellow.500'} w={{base:'100%', lg: '95%'}} h={{base: '100vh', lg:'100vh'}} ml={{lg:'5%', base:0}}>
                <Flex direction={'column'}>
                    <Box display={{base:'none', lg:'flex'}} bg={'gray.900'} h={{base:'0', lg:'10vh'}} w={{base:0, lg:'100%'}} position={'fixed'} zIndex={'9999'}><Header/></Box>
                    
                    <Box bg={'gray.200'} h={{lg:'90vh', base:'90vh'}} mt={'10vh'}>
                        <Flex direction={'column'}>
                            <Box bg={'white'} h={{base: '25vh', lg:'25vh'}} >
                                <Flex direction={{base:'column', lg:'row'}} >
                                    <Box display={{base:'none', lg:'flex'}} bg={'white'} w={{base:'0', lg:'20%'}} h={{base: '0', lg:'25vh'}}></Box> {/* Box 1 */}
                                    <Box bg={'pink.400'} w={{base:'100%', lg:'60%'}} h={{base: '25vh', lg:'25vh'}} >
                                            <Flex direction={'column'}>
                                                <Box bg={'gray.300'} h={'12.5vh'}>
                                                <Flex direction={'row'}>
                                                        <Box bg={'gray.500'} w={'70%'} h={'12.5vh'}>
                                                            <Flex direction={'column'}>
                                                                <Box bg={'white'} w={'100%'} h={'8.25vh'}>
                                                                
                                                                <Box alignSelf={'end'} fontSize={{lg:'17px', base:'23px', '2xl': '33px'}} fontWeight={'semibold'} color={'gray.700'} fontFamily={'Roboto'} ml={{lg:'0', base:'5'}} mt={{lg:'8', base:'5'}}>Hola! {user.username} </Box>
                                                                
                                                                </Box>
                                                                <Box bg={'white'} w={'100%'} h={'4.25vh'}>
                                                                    <Box fontSize={{lg:'13px', base:'15px', '2xl': '15px'}} fontWeight={'medium'} color={'gray.500'} ml={{lg:'0', base:'5'}} mb={{lg:'10', base:'0'}}>
                                                                    <Link href='/vendedor/clientes/detalles/1' > Configuración </Link>
                                                                    </Box>
                                                                </Box>
                                                            </Flex>
                                                        </Box>
                                                        <Box bg={'white'} w={'30%'} h={'12.5vh'}>
                                                            <Flex direction={'column'} justifyContent={'end'}>
                                                                <Box bg={'white'} w={'100%'} h={'4.16vh'} align={'end'} mt={8} pr={{base:10,lg:0}}>
                                                                <Tooltip label="Crear nuevo proyecto" aria-label='A tooltip' placement='right' borderRadius={'sm'}>
                                                                    
                                                                    <Menu>
                                                                                <MenuButton
                                                                                    px={4}
                                                                                    py={2}
                                                                                    transition='all 0.2s'
                                                                                    borderRadius='md'
                                                                                    borderWidth='1px'
                                                                                    _hover={{ bg: 'gray.400', color: 'white' }}
                                                                                    _expanded={{ bg: 'gray.500', color: 'white' }}
                                                                                    
                                                                                    _focus={{ boxShadow: 'outline' }}
                                                                                    as={Button}

                                                                                >
                                                                                    <HiDotsHorizontal />
                                                                                </MenuButton>
                                                                                <MenuList>
                                                                                    <MenuItem>Nuevo</MenuItem>
                                                                                    <MenuDivider />
                                                                                    <MenuItem>Eliminar</MenuItem>
                                                                                </MenuList>
                                                                            </Menu>
                                                                </Tooltip>
                                                                </Box>
                                                                <Box bg={'white'} w={'100%'} h={'4.16vh'}></Box>
                                                                <Box bg={'white'} w={'100%'} h={'4.16vh'}></Box>
                                                            </Flex>
                                                        </Box>
                                                </Flex>
                                                </Box>
                                                <Box bg={'white'} h={'12.5vh'}>
                                                    <Center>
                                                        
                                                    </Center>
                                                </Box>
                                                <Divider />
                                            </Flex>
                                    </Box>
                                    <Box display={{base:'none', lg:'flex'}} bg={'white'} w={{base:'0', lg:'20%'}} h={{base: '0', lg:'25vh'}}></Box> {/* Box 3 */}
                                </Flex>
                            </Box>
                            <Box bg={'white'} h={{base: '65vh', lg:'65vh'}} pl={{base:0, lg:0}} pr={{base:0, lg:0}}>
                                <Flex direction={{base:'column', lg:'row'}} >
                                    <Box display={{base:'none', lg:'flex'}} bg={'white'} w={{ base: '0', lg: '5%' }} h={{ base: '0', lg: '65vh' }}></Box> {/* Box 1 */}
                                    <Box bg={'white'}   w={{ base: '0', lg: '90%' }} h={{ base: '0', lg: '65vh' }}>
                                    <Flex justify={'space-between'} >
                                                        <Flex direction={'column'} bg={'gray.50'} my={3} py={5} px={5} w={'100%'} mx={5} rounded={'lg'}>
                                                            <Box>
                                                                <Flex justify={'space-between'}>
                                                                    <Text alignSelf={'center'} fontFamily={'Roboto'} fontWeight={'medium'} fontSize={'15pt'}>Configuración de Amazón WS</Text>
                                                                    <Box justifyContent={'center'} ml={3}>
                                                                    
                                                                    </Box> 
                                                                </Flex>  
                                                                    <Box fontFamily={'Roboto'} fontSize={'14px'} fontWeight={'normal'} color={'gray.500'}>Esta configuración, nos permitirá subir los archivos a Amazon  </Box>
                                                            </Box>
                                                            <>
                                                            <Box>
                                                                <Box fontSize={'11pt'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'} my={3}>
                                                                    Nombre del Bucket
                                                                </Box>
                                                                <Input fontSize={'11pt'} fontWeight={'normal'} fontFamily={'system-ui, sans-serif'} type={'text'} placeholder={datos.nombreBucket} bg={'white'}  onChange={(e) => handlerNombreBucket(e.target.value)}></Input>
                                                                </Box>
                                                            <Box>
                                                                <Box fontSize={'11pt'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'} my={3}>
                                                                    Nombre de Región
                                                                </Box>
                                                                <Input fontSize={'11pt'} fontWeight={'normal'} fontFamily={'system-ui, sans-serif'} type={'text'} placeholder={datos.nombreRegion} bg={'white'} value={nombreRegion} onChange={(e)=> handlerRegion(e.target.value)}></Input>
                                                            </Box>
                                                            <Box>
                                                                <Box fontSize={'11pt'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'} my={3}>
                                                                    Secret_name
                                                                </Box>
                                                                <Input fontSize={'11pt'} fontWeight={'normal'} fontFamily={'system-ui, sans-serif'} type={'text'} placeholder={datos.secretname} bg={'white'} value={secretname} onChange={(e)=>handlerSecret(e.target.value)}></Input>
                                                            </Box>
                                                            <Box>
                                                                <Box fontSize={'11pt'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'} my={3}>
                                                                    Access_name
                                                                </Box>
                                                                <Input fontSize={'11pt'} fontWeight={'normal'} fontFamily={'system-ui, sans-serif'} type={'text'} placeholder={datos.accessname} bg={'white'} value={accessname} onChange={(e)=>handlerAccess(e.target.value)}></Input>
                                                            </Box>
                                                            {datos? 
                                                            <Button my={2} colorScheme={'blue'} onClick={()=> actualizarCambio(datos.id)}>
                                                                            Guardar cambios
                                                            </Button> :
                                                                <Button my={2} colorScheme={'orange'} onClick={()=> guardarCambio()}>
                                                                    Guardar cambios
                                                                </Button>
                                                            
                                                            }
                                                            
                                                            </>
                                                        </Flex>
                                                        <Flex direction={'column'} bg={'white'} my={3} py={5} px={5} w={'100%'} mx={5} rounded={'lg'}>
                                                           
                                                        </Flex>
                                                        
                                                    </Flex>
                                    </Box>
                                    {/* Tabla para movil*/}
                                    <Box display={{base:'none', lg:'flex'}} bg={'white'} w={{ base: '0', lg: '5%' }} h={{ base: '0', lg: '65vh' }}></Box> {/* Box 3 */}
                                </Flex>   
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
        </Flex>
        </>
    );
};

export default Configuracion;