// import React, {useState, useEffect, useRef} from 'react';
// import * as AWS from 'aws-sdk'
// import Iframe from 'react-iframe'
// import {
//     Spinner,
//     Input, VStack, Toast, SimpleGrid, LinkOverlay, HStack, Image, Stack, useToast, Button,
//     Text, Heading, Container, Box, Center, Flex, Spacer, Link,
//     Table,
//     Thead,
//     Tbody,
//     Tfoot,
//     Tr,
//     Th,
//     Td,
//     TableCaption,
//     Avatar,
//     Divider,
//     FormControl,
//     FormLabel,
//     FormErrorMessage,
//     FormHelperText,
//     Tooltip,
//     Modal,
//     ModalOverlay,
//     ModalContent,
//     ModalHeader,
//     ModalFooter,
//     ModalBody,
//     ModalCloseButton,
//     Menu,
//     MenuButton,
//     MenuList,
//     MenuItem,
//     MenuDivider,
//     useDisclosure,
//     Icon,
//     Textarea,

// } from "@chakra-ui/react"
// import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';

// AWS.config.update({region: 'us-east-1', secretAccessKey: '3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi', accessKeyId:'AKIARVGY33F62XNKJJK3'});

// const ShareProject = () => {
//     const [datos, setDatos] = useState([])
//     const docClient = new AWS.DynamoDB.DocumentClient({apiVersion: '2022-01-01'})
//     const listElement = useRef('ChromelessStatusBar');
//   //console.log(docClient)

//   const fetchData = (tableName) => {
//       var params = {
//           TableName: tableName,
//         //   FilterExpression: "#nom = :name",
//         //   ExpressionAttributeNames: {
//         //       "#nom": "Nombre",
//         //   },
//         //   ExpressionAttributeValues: {
//         //       ":name": "Jacinto"
//         //   }
//       }

//      docClient.scan(params, function (err, data) {
//           if (!err) {
//               //return data
//               setDatos(data.Items)
//              console.log(data.Items)

//           }
//       })

//   }

//  const putData = (tableName , data) => {
//       var params = {
//           TableName: tableName,
//           Item: data
//       }

//       docClient.put(params, function (err, data) {
//           if (err) {
//               console.log('Error', err)
//           } else {
//               console.log('Success', data)
//           }
//       })
//   }
//     const fetchDataFormDynamoDb = () => {
//        const res =  fetchData('Clientes')
//         console.log("Resultado:", res)
//       // setDatos(res.Items)
//        //return res

//       }
//       const addDataToDynamoDB = async () => {
//         const userData = {
//           Nombre:"Juan Carlos Santiago",
//           Prueba:"Desde React",
//           FechaCreacion: "Hoa"
//         }

//         await putData('Clientes' , userData)
//       }
//     const regresar = () => {
//         window.location.href = '/vendedor/presentaciones'
//     }

//     useEffect(()=>{
//         fetchDataFormDynamoDb()

//     },[])
//     return (
//         <Box h={'90vh'} position={'relative'}>
//             {/* <button onClick={() => fetchDataFormDynamoDb()}> Fetch </button>
//             <button onClick={() => addDataToDynamoDB()}> Put </button>
//             {
//                 datos.map((dato, index)=>{
//                     return(
//                         <div>
//                             {dato.Nombre}
//                         </div>
//                     )
//                 })
//             } */}
//             <iframe
//                 src={`https://view.officeapps.live.com/op/embed.aspx?src=https://nctech-imagenes.s3.amazonaws.com/Presentacio%CC%81n1.pptx?readOnly=true`}
//                 width="100%"
//                 height="100%"
//                 controls={true}
//                 ref={listElement}
//                 id={'presentaciones'}

//             />
//             <Box bg={'gray.100'} position={'relative'} bottom={'33px'} h={'15vh'}>
//                 <Flex justify={'space-between'}>
//                     <Box mt={8} mx={'5'}>
//                     <Button variant={'outline'} colorScheme={'orange'} onClick={regresar}><IoMdArrowBack size={'25px'} />Regresar</Button>
//                     </Box>
//                   <Box mt={8}>
//                     <Box fontSize={'sm'} fontWeight={'normal'} fontFamily={'system-ui, sans-serif'}>Para moverte dentro de la presentación, usa las flechas del teclado<Flex justify={'center'}><IoMdArrowBack size={'25px'} /><IoMdArrowForward size={'25px'} /></Flex> </Box>
//                   </Box>
//                   <Box>
//                     <Box></Box>
//                   </Box>
//                 </Flex>

//             </Box>
//         </Box>
//     );
// };

// export default ShareProject;

import React, { useState, useEffect, useRef } from "react";
import * as AWS from "aws-sdk";
import { Button, Box } from "@chakra-ui/react";
import { IoMdArrowBack } from "react-icons/io";

AWS.config.update({
  region: "us-east-1",
  secretAccessKey: "3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi",
  accessKeyId: "AKIARVGY33F62XNKJJK3",
});

function getQueryParams(qs) {
  qs = qs.split("+").join(" ");

  var params = {},
    tokens,
    re = /[?&]?([^=]+)=([^&]*)/g;

  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
}

const ShareProject = () => {
  const [setDatos] = useState([]);
  const docClient = new AWS.DynamoDB.DocumentClient({
    apiVersion: "2022-01-01",
  });
  const listElement = useRef("ChromelessStatusBar");

  const [setTo] = useState();
  const [src, setSrc] = useState();

  //console.log(docClient)

  const fetchData = (tableName) => {
    var params = {
      TableName: tableName,
      //   FilterExpression: "#nom = :name",
      //   ExpressionAttributeNames: {
      //       "#nom": "Nombre",
      //   },
      //   ExpressionAttributeValues: {
      //       ":name": "Jacinto"
      //   }
    };

    docClient.scan(params, function (err, data) {
      if (!err) {
        //return data
        setDatos(data.Items);
        console.log(data.Items);
      }
    });
  };

  const putData = (tableName, data) => {
    var params = {
      TableName: tableName,
      Item: data,
    };

    docClient.put(params, function (err, data) {
      if (err) {
        console.log("Error", err);
      } else {
        console.log("Success", data);
      }
    });
  };
  const fetchDataFormDynamoDb = () => {
    const res = fetchData("Clientes");
    console.log("Resultado:", res);
    // setDatos(res.Items)
    //return res
  };
  const addDataToDynamoDB = async () => {
    const userData = {
      Nombre: "Juan Carlos Santiago",
      Prueba: "Desde React",
      FechaCreacion: "Hoa",
    };

    await putData("Clientes", userData);
  };
  const regresar = () => {
    window.location.href = "/vendedor/presentaciones";
  };

  useEffect(() => {
    const query = getQueryParams(document.location.search);
    setTo(query.token);
    setSrc(query.url);
    fetchDataFormDynamoDb();
    console.log(query);
  }, []);
  return (
    <Box h={"90vh"} position={"relative"}>
      {/* <button onClick={() => fetchDataFormDynamoDb()}> Fetch </button>
            <button onClick={() => addDataToDynamoDB()}> Put </button>
            {
                datos.map((dato, index)=>{
                    return(
                        <div>
                            {dato.Nombre}
                        </div>
                    )
                })
            } */}

      <iframe
        src={src}
        width="100%"
        height="100%"
        controls={false}
        ref={listElement}
        id={"presentaciones"}
        border="none"
        allowfullscreen="true"
        overflow="hidden"
        style={{ backgroundColor: "#f5f5f5" }}
      />

      <Box opacity={0.9} mx={"5"} p={5}>
        <Button variant={"outline"} colorScheme={"orange"} onClick={regresar}>
          <IoMdArrowBack size={"25px"} />
          Regresar
        </Button>
      </Box>
    </Box>
  );
};

export default ShareProject;
