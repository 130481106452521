import * as AWS from "aws-sdk";
import axios from "axios";
import { customAlphabet } from "nanoid";
//AWS.config.update({ region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' }); //DESARROLLO
AWS.config.update({
  region: "us-east-1",
  secretAccessKey: "3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi",
  accessKeyId: "AKIARVGY33F62XNKJJK3",
}); //PRODUCCION
const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: "2022-01-01" });
const nanoid = customAlphabet("0123456789", 30);

export const update_propuesta = (id, ids) => async (dispatch) => {
  //console.log("Este es el nuevo estatus---", id)

  const params = {
    TableName: "Propuestas",
    Key: {
      id: id,
    },
    UpdateExpression: "set #novisible = :novisible",
    ExpressionAttributeNames: {
      "#novisible": "novisible",
    },
    ExpressionAttributeValues: {
      ":novisible": ids,
    },
  };

  docClient.update(params, function (err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      dispatch({ type: "UPDATE_PROPUESTA", payload: "Guardado..." });
    }
  });
};
export const update_presentaciones = (id, ids) => async (dispatch) => {
  //console.log("Este es el nuevo estatus---", id)

  const params = {
    TableName: "Presentaciones",
    Key: {
      id: id,
    },
    UpdateExpression: "set #novisible = :novisible",
    ExpressionAttributeNames: {
      "#novisible": "novisible",
    },
    ExpressionAttributeValues: {
      ":novisible": ids,
    },
  };

  docClient.update(params, function (err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      dispatch({ type: "UPDATE_PRESENTACIONES", payload: ids });
    }
  });
};
export const update_contrato = (id, ids) => async (dispatch) => {
  console.log("Este es el nuevo estatus---", id, ids);

  const params = {
    TableName: "Contratos",
    Key: {
      id: id,
    },
    UpdateExpression: "set #novisible = :novisible",
    ExpressionAttributeNames: {
      "#novisible": "novisible",
    },
    ExpressionAttributeValues: {
      ":novisible": ids,
    },
  };

  docClient.update(params, function (err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      dispatch({ type: "UPDATE_CONTRATOS", payload: "Guardado..." });
    }
  });
};
export const update_logo =
  (datosAWS, user, urlStrapi, token) => async (dispatch) => {
    //console.log(datosAWS, user, urlStrapi, token)
    dispatch({ type: "LOAD_VENDEDOR_REQUEST" });
    const url = `https://${datosAWS.bucket}.s3.amazonaws.com/${datosAWS.key}`;
    const urlC = urlStrapi + "/users/" + user;
    const data = {
      Image: url,
    };
    const response = await fetch(urlC, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      // La actualización fue exitosa, puedes hacer algo aquí
      dispatch({ type: "UPDATE_VENDEDOR", payload: url });
    } else {
      // La actualización falló, puedes hacer algo aquí
      dispatch({ type: "VENDEDOR_ERROR" });
    }
  };
export const update_logo_customer =
  (datosAWS, cliente, urlStrapi, token) => async (dispatch) => {
    //console.log(datosAWS, cliente, urlStrapi, token)
    dispatch({ type: "LOAD_CLIENTE_REQUEST" });
    dispatch({ type: "LOAN_MENSAJE" });
    const url = `https://${datosAWS.bucket}.s3.amazonaws.com/${datosAWS.key}`;
    const urlC = urlStrapi + "/clientes/" + cliente;
    const data = {
      Logo: url,
    };
    const response = await fetch(urlC, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      // La actualización fue exitosa, puedes hacer algo aquí
      dispatch({ type: "UPDATE_CLIENTE", payload: url });
    } else {
      // La actualización falló, puedes hacer algo aquí
      dispatch({ type: "CLIENTE_ERROR" });
    }
  };
export const load_mensaje = () => async (dispatch) => {
  dispatch({ type: "LOAD_MENSAJE" });
};
export const success_mensaje = () => async (dispatch) => {
  dispatch({ type: "SUCCESS_MENSAJE" });
};
export const clean_mensaje = () => async (dispatch) => {
  dispatch({ type: "CLEAN_MENSAJE" });
};
export const update_media = (id, tipo) => async (dispatch) => {
  //console.log("Este es el nuevo estatus---", id)

  const params = {
    TableName: "Media",
    Key: {
      id: id,
    },
    UpdateExpression: "set #tipo = :tipo",
    ExpressionAttributeNames: {
      "#tipo": "tipo",
    },
    ExpressionAttributeValues: {
      ":tipo": tipo,
    },
  };

  docClient.update(params, function (err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      dispatch({ type: "UPDATE_MEDIA", payload: tipo });
    }
  });
};
export const update_clientes_batch =
  (
    urlStrapi,
    token,
    nombre,
    razonSocial,
    ciudad,
    estado,
    pais,
    calle,
    numero,
    colonia,
    codigo,
    tipo,
    vendedor,
    nanoid,
    username,
    cardid,
  ) =>
  async (dispatch) => {
    //console.log("Este es el nuevo estatus---", id)
    dispatch({ type: "LOAD_MENSAJE_CLIENTES" });
    const response = await fetch(urlStrapi + "/clientes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        razonSocial: razonSocial,
        nombre: nombre,
        ciudad: ciudad,
        estado: estado,
        pais: pais,
        calle: calle,
        numero: numero,
        colonia: colonia,
        codigoPostal: codigo,
        tipo: tipo,
        //   nombreContacto:nombreContacto,
        //   correo: correo,
        //    telefono: telefono,
        vendedor: vendedor,
        token: nanoid,
        username,
        estatus: "Nuevo",
        fechaCreacion: new Date(),
        CARDID: cardid,
      }),
    });

    dispatch({ type: "SUCCESS_MENSAJE_CLIENTES" });

    setTimeout(() => {
      dispatch({ type: "CLEAN_MENSAJE_CLIENTES" });
    }, 1000);
    setTimeout(() => {
      dispatch({ type: "EXITOSO_MENSAJE_CLIENTES" });
    }, 1100);

    return response;
  };
export const load_clientes = () => async (dispatch) => {
  dispatch({ type: "LOAD_CLIENTES" });
};
export const ready_clientes = (data) => async (dispatch) => {
  dispatch({ type: "SUCCESS_CLIENTES", payload: data });
};
export const clean_clientes = () => async (dispatch) => {
  dispatch({ type: "CLEAN_CLIENTES" });
};
export const clean_mensaje_clientes = () => async (dispatch) => {
  dispatch({ type: "CLEAN_MENSAJE_CLIENTES" });
};
//LINKS
export const create_link = (link) => async (dispatch) => {
  const datos = {
    id: nanoid().toString(),
    link: link,
  };

  var params = {
    TableName: "Links",
    Item: datos,
  };
  docClient.put(params, function (err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      console.log("Inserción correcto", data);
      dispatch({ type: "CREATE_LINK" });
    }
  });
};
export const get_link = () => async (dispatch) => {
  dispatch({ type: "LOAD_LINK" });
  try {
    var params = {
      TableName: "Links",
    };

    docClient.scan(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        dispatch({ type: "GET_LINK", payload: data.Items });
      }
    });
  } catch (error) {
    return [];
  }
};
export const delete_link = (id) => async (dispatch) => {
  //console.log("Este es el nuevo estatus---", id)

  const params = {
    TableName: "Links",
    Key: {
      id: id,
    },
  };

  docClient.delete(params, function (err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      dispatch({ type: "DELETE_LINK" });
    }
  });
};
//CONTRATOS
export const create_contrato = (data, urlContrato) => async (dispatch) => {
  const datos = {
    id: nanoid().toString(),
    nombre: data.nombre,
    tipo: data.tipo,
    url: urlContrato,
    fechaEmision: data.fechaEmision,
    fechaFirma: "0",
    proyecto: "0",
  };
  var params = {
    TableName: "Contratos",
    Item: datos,
  };
  docClient.put(params, function (err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      console.log("Inserción correcto", data);
      dispatch({ type: "CREATE_CONTRATOS" });
    }
  });
};
export const get_contratos = () => async (dispatch) => {
  dispatch({ type: "LOAD_CONTRATOS" });
  try {
    var params = {
      TableName: "Contratos",
    };
    docClient.scan(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        dispatch({ type: "GET_CONTRATOS", payload: data.Items });
      }
    });
  } catch (error) {
    return [];
  }
};
export const delete_contrato = (id) => async (dispatch) => {
  //console.log("Este es el nuevo estatus---", id)

  const params = {
    TableName: "Contratos",
    Key: {
      id: id,
    },
  };

  docClient.delete(params, function (err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      dispatch({ type: "DELETE_CONTRATOS" });
    }
  });
};
//CLIENTES CRM
export const get_crm = (urlCassandra, tokenCasandra) => async (dispatch) => {
  dispatch({ type: "LOAD_CRM" });
  try {
    //console.log("refetch")
    //const urlC = `${urlCassandra}collections/attachmens${user.id.toString()}?page-size=20`
    const url =
      "https://servicesemail-production.herokuapp.com/api/clientes_crm/";
    const result = await axios(url, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    // const configuracion =  await Object.keys(result.data.data).map((item) => ({id: item, ...result.data.data[item]}))
    console.log("DESDEREDUCER", result.data.undefined.local);
    dispatch({ type: "GET_CRM", payload: result.data.undefined });
  } catch (error) {
    return [];
  }
};
export const get_customers =
  (tipo, tokenCrm, urlStrapi) => async (dispatch) => {
    dispatch({ type: "LOAD_CUSTOMER" });
    try {
      if (tipo === false) {
        const clientes = await axios(urlStrapi + "/clientes");

        console.log("[RESULTADO]", clientes.data);
        dispatch({ type: "GET_CUSTOMERS", payload: clientes.data });
      }
    } catch (error) {
      return [];
    }
  };
export const get_customer =
  (tipo, tokenCrm, urlStrapi, query) => async (dispatch) => {
    dispatch({ type: "LOAD_CUSTOMER" });
    try {
      if (tipo === true) {
        const url = `https://servicesemail-production.herokuapp.com/api/crm?busqueda=${query}`;
        const result = await axios(url, {
          method: "GET",
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        });
        dispatch({ type: "GET_CUSTOMERS", payload: result.data });
      }
      if (tipo === false) {
        const urlC = `${urlStrapi}/clientes?nombre_contains=${query}`;
        const customerData = await axios.get(urlC, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(customerData);
        dispatch({ type: "GET_CUSTOMERS", payload: customerData.data });
      }
    } catch (error) {
      return [];
    }
  };
export const get_vendedor = (urlStrapi, id, tipo) => async (dispatch) => {
  dispatch({ type: "LOAD_USUARIO" });
  try {
    const response = await axios.get(urlStrapi + "/api/getVendedor/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch({ type: "GET_USUARIO", payload: response.data });
  } catch (error) {
    return [];
  }
};
