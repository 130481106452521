import React from 'react';
import DetallesClientes from  '../../components/Cliente/Detalles';
const Detalles = () => {
    return (
        <>
           <DetallesClientes/> 
        </>
    );
};

export default Detalles;