import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Logo from "../../assets/LOGOS_NCTECH-01.svg";
import LogoA from "../../assets/logo-nctech-1-1.svg";
import Cover from "../../assets/forgot-v2.jpg";
import {
  Input,
  VStack,
  Toast,
  SimpleGrid,
  LinkOverlay,
  HStack,
  Image,
  Stack,
  useToast,
  Button,
  Text,
  Heading,
  Container,
  Box,
  Center,
  Collapse,
  Fade,
  ScaleFade,
  Slide,
  SlideFade,
  useDisclosure,
  Flex,
  Spacer,
  Link,
} from "@chakra-ui/react";

import { FaLinkedin, FaLinkedinIn, FaWhatsappSquare } from "react-icons/fa";
import { SiGmail } from "react-icons/si";

export default function Forgot() {
  const toast = useToast();
  const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI);
  const [useremail, setUseremail] = useState("");

  const forgot = () => {
    console.log(useremail);
    axios
      .post(urlStrapi + "/auth/forgot-password", {
        email: useremail, // user's email
      })
      .then((response) => {
        window.location = "forgotconfirmate";
      })
      .catch((error) => {
        //console.log('An error occurred:', error.response.data.data[0].messages[0].message);
        toasError(error.response.data.data[0].messages[0].message);
      });
  };
  const toasError = (e) => {
    toast({
      title: "Error.",
      description: e,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };
  const toas = () => {
    toast({
      title: "Error.",
      description:
        "El campo correo no puede estar vacio, verifica y vuelve a intentar.",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };
  function redirect() {
    window.location = "/";
  }
  return (
    <Stack direction={{ base: "column", lg: "row" }} m={"0px"} spacing={0}>
      <Box
        mt={0}
        visibility={{ base: "hidden", lg: "visible" }}
        w={{ base: "0px", lg: "100%" }}
        h={{ base: "0px", lg: "100vh" }}
        className={"bg-login-color"}
      >
        <Link href="/">
          <Image
            src={Logo}
            w={"132px"}
            mt={"5"}
            ml={"10"}
            position={"absolute"}
          />
        </Link>
        {/* Logo */}

        <Center>
          <Box w={"100%"}>
            <Image
              src={Cover}
              w={{ lg: "100%" }}
              h={"100vh"}
              objectFit={"cover"}
            />
          </Box>
        </Center>
        {/* Ilustración */}
        <HStack spacing={"10"}>
          <Box
            bottom={10}
            position={"absolute"}
            left={10}
            visibility={{ base: "hidden", lg: "visible" }}
          >
            <FaWhatsappSquare fontSize={"20pt"} color={"white"} />
          </Box>
          <Spacer />
          <Box
            bottom={10}
            position={"absolute"}
            left={10}
            visibility={{ base: "hidden", lg: "visible" }}
          >
            <SiGmail fontSize={"20pt"} color={"white"} />
          </Box>
          <Box
            bottom={10}
            position={"absolute"}
            left={20}
            visibility={{ base: "hidden", lg: "visible" }}
          >
            <FaLinkedin fontSize={"20pt"} color={"white"} />
          </Box>
        </HStack>

        {/* Redes Sociales */}
      </Box>
      {/* Fin primera sección */}
      <Box w={{ base: "100%", lg: "60%" }} h="100vh" bg="gray.50" m={0}>
        {/* Inicio-Logo */}
        <Center>
          <Box mt={10}>
            <Link href="/">
              <Image
                visibility={{ base: "visible", lg: "hidden" }}
                src={LogoA}
                w={"132px"}
                mt={"5"}
              />
            </Link>
          </Box>
        </Center>
        {/* Fin-Logo */}
        {/* Inicio-titulos */}

        {/* Fin-titulos */}
        {/* Inicio-formulario */}
        <Stack pt={{ base: 28, lg: 16, "2xl": 52 }}>
          <VStack w={"100%"} p={3}>
            <Text
              align={{ base: "center" }}
              fontFamily={"Roboto"}
              fontWeight={"bold"}
              fontSize={"38px"}
              color={"gray.700"}
              mb={3}
            >
              ¿Olvidaste tú contraseña?
            </Text>
            <Text
              align={"center"}
              w={"70%"}
              fontWeight={"regular"}
              fontSize={"17px"}
              color={"gray.600"}
              fontFamily={"Roboto"}
            >
              Por favor, escribe tú dirección de correo electrónico y haz clic
              en <strong>Restablecer contraseña</strong>
            </Text>
            <Text
              align={"center"}
              w={"70%"}
              fontWeight={"regular"}
              fontSize={"17px"}
              color={"gray.600"}
              fontFamily={"Roboto"}
              mb={5}
            >
              Te enviaremos un enlace y podrás cambiarla.
            </Text>
            <Center w={"100%"}>
              <Input
                h={14}
                onChange={(e) => setUseremail(e.target.value)}
                w={"70%"}
                type="email"
                placeholder="ejemplo@ejemplo.com"
              />
            </Center>
          </VStack>
          <Center mt={10}>
            {useremail == "" ? (
              <Button
                onClick={() => toas()}
                h={"60px"}
                variant={"outline"}
                w="300px"
                colorScheme={"red"}
                fontWeight={"medium"}
                className="font"
              >
                Restablecer Contraseña
              </Button>
            ) : (
              <Button
                h={"60px"}
                onClick={() => forgot()}
                variant={"outline"}
                w="300px"
                bg={"yellow.400"}
                fontWeight={"medium"}
                className="font"
              >
                Restablecer Contraseña
              </Button>
            )}
          </Center>
        </Stack>
        {/* fin-formulario */}
        {/* Inicio Redes Sociales Mobile */}
        <Center>
          <SimpleGrid bottom={14} position={"absolute"} columns={3} spacing={5}>
            <Box visibility={{ base: "visible", lg: "hidden" }}>
              <FaWhatsappSquare fontSize={"20pt"} color={"gray"} />
            </Box>
            <Box visibility={{ base: "visible", lg: "hidden" }}>
              <SiGmail fontSize={"20pt"} color={"gray"} />
            </Box>
            <Box visibility={{ base: "visible", lg: "hidden" }}>
              <FaLinkedin fontSize={"20pt"} color={"gray"} />
            </Box>
          </SimpleGrid>
        </Center>
        {/* Fin Redes Sociales */}
      </Box>
    </Stack>
  );
}
