import { Box } from "@chakra-ui/layout";
import React from "react";
import GraficasGerente from "../../components/Gerente/Graficas";
const Graficas = () => {
  return (
    <Box bg={"gray.50"} h={"100vh"}>
      <GraficasGerente />
    </Box>
  );
};

export default Graficas;
