import React, { useState } from "react";
import {
  Spinner,
  Input,
  Button,
  Box,
  Center,
  useDisclosure,
  Flex,
  Link,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tag,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  TagLabel,
  Select,
} from "@chakra-ui/react";
import Header from "../../Header";
import { AddIcon, DragHandleIcon } from "@chakra-ui/icons";
import useUser from "../../context/useUser";
import { useQuery, useMutation, useQueryClient } from "react-query";
import MenuVendedor from "../Utilidades/MenuVendedor";
import { BiSlideshow } from "react-icons/bi";
import { Space, Table, Empty } from "antd";
import "antd/dist/antd.css";
import { RenderIf } from "../../../hooks/renderIF";
import MenuGerente from "../../Gerente/Utilidades/MenuGerente";
import { connect } from "react-redux";
import {
  create_video_children,
  get_videos_by_id,
  get_videos_children,
  delete_video,
} from "../../../actions/videos";
import { useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
const _VideosDetails = (props) => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [url, setUrl] = useState();
  const [nombre, setNombre] = useState("");
  const [tipo, setTipo] = useState("");
  const [padre, setPadre] = useState("");
  const { Column } = Table;
  const {
    create_video_children,
    videos,
    get_videos_by_id,
    get_videos_children,
    videosChildren,
    delete_video,
  } = props || {};
  const [estadoApp, setEstadoApp] = useState();
  let { id } = useParams();

  const debouncedUpdates = useDebouncedCallback(async () => {
    setEstadoApp("Creando...");
    // Simulate a delay in saving.
    setTimeout(() => {
      setEstadoApp("Listo");
    }, 500);
    setTimeout(() => {
      setEstadoApp();
      get_videos_children(id);
    }, 1000);
  }, 950);
  const debouncedDelete = useDebouncedCallback(async () => {
    setEstadoApp("Eliminado...");
    // Simulate a delay in saving.
    setTimeout(() => {
      setEstadoApp("Listo");
    }, 500);
    setTimeout(() => {
      setEstadoApp();
      get_videos_children(id);
    }, 1000);
  }, 950);

  async function getVideos() {
    get_videos_by_id(id);
    get_videos_children(id);
  }
  const crearVideo = async (data) => {
    create_video_children(nombre, url, tipo, id, padre);
    debouncedUpdates();
    onClose();
  };

  const eliminarVideo = async (id) => {
    try {
      delete_video(id);
      debouncedDelete();
    } catch (error) {
      console.error(error);
    }
  };

  useMutation(crearVideo, {
    onSuccess: () => {
      queryClient.invalidateQueries("VIDEOS");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const { isLoading } = useQuery("VIDEOS", getVideos, {});
  if (isLoading) {
    return (
      <Center mt={"10%"}>
        <Spinner color={"green.700"} />
      </Center>
    );
  }

  const handlerNombre = (e) => {
    setNombre(e);
  };
  const handlerUrl = (e) => {
    setUrl(e);
  };

  console.log(
    "FILTRO",
    videosChildren.filter((a) => a.tipo === "Padre"),
  );
  return (
    <>
      <Modal
        size={"xl"}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        zIndex={"9999"}
      >
        <div>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              fontSize={"lg"}
              fontWeight={"bold"}
              fontFamily={"system-ui, sans-serif"}
            >
              Subir nuevo video
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Box
                my={1}
                fontSize={"sm"}
                fontWeight={"medium"}
                color={"gray.500"}
                fontFamily={"system-ui, sans-serif"}
              >
                Selecciona un Tipo(Padre o Hijo)
              </Box>
              <Select
                placeholder="Selecciona un Tipo"
                bg={"white"}
                onChange={(e) => setTipo(e.target.value)}
                my={2}
              >
                <option value="Padre">Padre</option>
                <option value="Hijo">Hijo</option>
              </Select>
              <RenderIf isTrue={tipo === "Hijo"}>
                <Box p={"4"} borderWidth={"1px"} rounded={"lg"} bg={"gray.50"}>
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"system-ui, sans-serif"}
                  >
                    Selecciona un padre
                  </Box>
                  <Select
                    placeholder="Selecciona un Padre"
                    bg={"white"}
                    onChange={(e) => setPadre(e.target.value)}
                    my={2}
                  >
                    {videosChildren
                      .filter((a) => a.tipo === "Padre")
                      .map((o, i) => {
                        return (
                          <option key={i} value={o.id}>
                            {o.nombre}
                          </option>
                        );
                      })}
                  </Select>
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"system-ui, sans-serif"}
                  >
                    Título para tu video
                  </Box>
                  <Input
                    bg={"white"}
                    onChange={(e) => handlerNombre(e.target.value)}
                    type={"text"}
                    placeholder={"Video de prueba"}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    color={"gray.800"}
                    fontFamily={"system-ui, sans-serif"}
                  />
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"system-ui, sans-serif"}
                  >
                    Ingresa la URL de tu video
                  </Box>
                  <Input
                    bg={"white"}
                    onChange={(e) => handlerUrl(e.target.value)}
                    type={"text"}
                    placeholder={"URL de tu video"}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    color={"gray.800"}
                    fontFamily={"system-ui, sans-serif"}
                  />
                </Box>
              </RenderIf>
              <RenderIf isTrue={tipo === "Padre"}>
                <Box p={"4"} borderWidth={"1px"} rounded={"lg"} bg={"gray.50"}>
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"system-ui, sans-serif"}
                  >
                    Título para tu video
                  </Box>
                  <Input
                    bg={"white"}
                    onChange={(e) => handlerNombre(e.target.value)}
                    type={"text"}
                    placeholder={"Video de prueba"}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    color={"gray.800"}
                    fontFamily={"system-ui, sans-serif"}
                  />
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"system-ui, sans-serif"}
                  >
                    Ingresa la URL de tu video
                  </Box>
                  <Input
                    bg={"white"}
                    onChange={(e) => handlerUrl(e.target.value)}
                    type={"text"}
                    placeholder={"URL de tu video"}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    color={"gray.800"}
                    fontFamily={"system-ui, sans-serif"}
                  />
                </Box>
              </RenderIf>
            </ModalBody>
            <ModalFooter>
              <RenderIf isTrue={tipo !== ""}>
                <Button onClick={crearVideo}>Crear</Button>
              </RenderIf>
            </ModalFooter>
          </ModalContent>
        </div>
      </Modal>
      <Flex direction={{ base: "column", lg: "row" }} bg={"pink"} h={"100vh"}>
        <RenderIf isTrue={user.type !== 3}>
          <MenuVendedor />
        </RenderIf>
        <RenderIf isTrue={user.type === 3}>
          <MenuGerente />
        </RenderIf>
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>

            <Box bg={"gray.200"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
              <Flex direction={"column"}>
                <Box bg={"gray.100"} h={{ base: "25vh", lg: "25vh" }}>
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bgGradient="linear(to-r, gray.50, gray.200)"
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"gray.200"}
                      w={{ base: "100%", lg: "60%" }}
                      h={{ base: "25vh", lg: "25vh" }}
                    >
                      <Flex direction={"column"}>
                        <Box bg={"gray.300"} h={"12.5vh"}>
                          <Flex direction={"row"}>
                            <Box bg={"gray.500"} w={"70%"} h={"12.5vh"}>
                              <Flex direction={"column"}>
                                <Box bg={"gray.200"} w={"100%"} h={"8.25vh"}>
                                  {videos !== undefined ? (
                                    <Box
                                      alignSelf={"end"}
                                      fontSize={{
                                        lg: "1w",
                                        base: "23px",
                                        "2xl": "2w",
                                      }}
                                      fontWeight={"semibold"}
                                      color={"gray.700"}
                                      fontFamily={"system-ui, sans-serif"}
                                      ml={{ lg: "0", base: "5" }}
                                      mt={{ lg: "6", base: "5" }}
                                    >
                                      Videos Hijos / {videos.nombre}
                                    </Box>
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                                <Box bg={"gray.200"} w={"100%"}>
                                  <Box
                                    fontSize={"17px"}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    ml={{ lg: "0", base: "5" }}
                                    fontFamily={"system-ui, sans-serif"}
                                  ></Box>
                                </Box>
                                <Box bg={"gray.200"} w={"100%"} h={"4.25vh"}>
                                  <Box
                                    my={2}
                                    fontSize={"sm"}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    fontFamily={"system-ui, sans-serif"}
                                    ml={{ lg: "0", base: "5" }}
                                    mb={{ lg: "10", base: "0" }}
                                  >
                                    Aquí puedes incorporar videos adicionales
                                    relacionados con este contenido.
                                  </Box>
                                </Box>
                              </Flex>
                            </Box>
                            <Box bg={"gray.200"} w={"30%"} h={"12.5vh"}>
                              <Flex direction={"column"} justifyContent={"end"}>
                                <Box
                                  bg={"gray.200"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                  align={"end"}
                                  mt={8}
                                  pr={{ base: 10, lg: 0 }}
                                >
                                  <Tooltip
                                    label="Subir nuevo video hijo"
                                    aria-label="A tooltip"
                                    placement="right"
                                    borderRadius={"sm"}
                                  >
                                    <Button
                                      colorScheme={"orange"}
                                      onClick={onOpen}
                                    >
                                      <AddIcon />
                                    </Button>
                                  </Tooltip>
                                </Box>
                                <Box
                                  bg={"grya.100"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                ></Box>
                                <Box
                                  bg={"grya.100"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                ></Box>
                              </Flex>
                            </Box>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bgGradient="linear(to-r, gray.200, gray.50)"
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
                <Box
                  bg={"white"}
                  h={{ base: "65vh", lg: "65vh" }}
                  pl={{ base: 0, lg: 0 }}
                  pr={{ base: 0, lg: 0 }}
                >
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"white"}
                      w={{ base: "0", lg: "90%" }}
                      h={{ base: "0", lg: "65vh" }}
                    >
                      {videosChildren !== undefined ? (
                        <Box bg={"white"} w={"100%"} mt={5}>
                          <Center>
                            <Box
                              borderWidth={1}
                              borderRadius={"12px"}
                              zIndex={1}
                              py={3}
                            >
                              <Center>
                                <Box fontWeight="bold">{estadoApp}</Box>
                              </Center>
                              <Table
                                dataSource={videosChildren}
                                bordered
                                pagination={{
                                  pageSize: 5,
                                }}
                              >
                                <Column
                                  title="Nombre del Video"
                                  dataIndex="nombrePresentacion"
                                  key="nombrePresentacion"
                                  render={(text, record, index) => (
                                    <Box>
                                      <Link href={"#"}>
                                        <Flex>
                                          <Box
                                            fontSize={"21px"}
                                            fontWeight={"medium"}
                                            color={"gray.700"}
                                            mr={"3"}
                                          >
                                            <BiSlideshow />
                                          </Box>
                                          {record.nombre}
                                        </Flex>
                                      </Link>
                                    </Box>
                                  )}
                                />
                                <Column
                                  title="Tipo"
                                  dataIndex="tipo"
                                  key="tipo"
                                  render={(tipo) => (
                                    <Tag bg={"#f1ddbf"}>
                                      <TagLabel color={"#525e75"}>
                                        {tipo}
                                      </TagLabel>
                                    </Tag>
                                  )}
                                />

                                <Column
                                  title="Acción"
                                  dataIndex="estatusAdmin"
                                  key="estatusAdmin"
                                  render={(text, record, index) => (
                                    <Space size="middle">
                                      <Menu>
                                        <MenuButton
                                          as={Button}
                                          rightIcon={<DragHandleIcon />}
                                        >
                                          Acciones
                                        </MenuButton>
                                        <MenuList>
                                          <MenuItem
                                            onClick={() =>
                                              eliminarVideo(record.id)
                                            }
                                          >
                                            Eliminar
                                          </MenuItem>
                                        </MenuList>
                                      </Menu>
                                    </Space>
                                  )}
                                />
                              </Table>
                            </Box>
                          </Center>
                        </Box>
                      ) : (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            <span>Todavía no se ha subido ningún vídeo.</span>
                          }
                        />
                      )}
                    </Box>
                    {/* Tabla para movil*/}
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

//REDUX
const mapStateProps = (state) => ({
  videos: state.videosReducer.videos,
  videosChildren: state.videosReducer.videosChildren,
});

const VideosDetails = connect(mapStateProps, {
  create_video_children,
  get_videos_by_id,
  get_videos_children,
  delete_video,
})(_VideosDetails);

export default VideosDetails;
