import React, { useContext, useEffect, useState, useRef } from "react";
import { Button, Box, useDisclosure, Flex, Divider } from "@chakra-ui/react";
import Header from "../Header";
import { PhoneIcon, AddIcon, WarningIcon } from "@chakra-ui/icons";
import useUser from "../context/useUser";
import useToken from "../useToken";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import MenuVendedor from "./Utilidades/MenuGerente";
import userEvent from "@testing-library/user-event";
import { useParams } from "react-router-dom";
import {
  BsFillTelephoneFill,
  BsPinMapFill,
  BsPeopleFill,
  BsFilter,
  BsCalendarDate,
} from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { HiDocumentText, HiDotsHorizontal } from "react-icons/hi";
import { GoPrimitiveDot } from "react-icons/go";
import Moment from "react-moment";
import "moment-timezone";
import { MdNewLabel } from "react-icons/md";
import { DownOutlined } from "@ant-design/icons";
import { Badge, Table } from "antd";
import * as AWS from "aws-sdk";
import { RenderIf } from "../../hooks/renderIF";
AWS.config.update({
  region: "us-east-1",
  secretAccessKey: "3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi",
  accessKeyId: "AKIARVGY33F62XNKJJK3",
});
//AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' });
const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: "2022-01-01" });
const Detalles = () => {
  const contactRef = useRef();
  let { id } = useParams();
  const [cliente, setCliente] = useState();
  const queryClient = useQueryClient();
  const [datos, setDatos] = useState([]);
  const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI);
  const [urlCassandra, setUrlCassandra] = useState(
    process.env.REACT_APP_URL_CASSANDRA,
  );
  const [tokenCasandra, setTokenCassandra] = useState(
    process.env.REACT_APP_TOKEN_CASSANDRA,
  );
  const { user, setUser } = useUser();
  const [proyectos, setProyectos] = useState([]);
  const [notas, setNotas] = useState("");
  const { isOpen: isColapse, onToggle } = useDisclosure({
    defaultIsOpen: false,
  });
  const [rowActual, setRowActual] = useState();
  const [rowActualVendedores, setRowActualVendedores] = useState();
  const [datosProyectos, setDatosProyectos] = useState();
  const [datosProyectosVendedores, setDatosProyectosVendedores] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandedRowKeysVendedores, setExpandedRowKeysVendedores] = useState(
    [],
  );
  const [bandera, setBandera] = useState(true);
  const [vendedores, setVendedores] = useState(false);
  const [contactos, setContactos] = useState(false);
  const [titulo, setTitulo] = useState();
  const [listaVendedores, setListaVendedores] = useState([]);
  const [datosContactos, setDatosContactos] = useState();

  const [fuente] = useState(
    "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
  );

  const { Column, ColumnGroup } = Table;

  const fechClientes = async () => {
    try {
      const proyecto = 1;
      const urlC = `${urlStrapi}/clientes`;
      const clientes = await axios.get(urlC, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      //console.log("datos", clientes.data)
      const result = clientes.data;
      const res = result.map((o) => {
        //console.log("datos", o)
        return {
          id: o.id,
          CARDID: o.CARDID,
          razonSocial: o.razonSocial,
          nombreContacto: o.nombreContacto,
          correo: o.correo,
          created_at: o.created_at,
        };
      });
      //console.log(res)
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  const usuarios = async (actual) => {
    fetchProyectos(actual);
  };
  const usuariosVendedores = async (actual) => {
    fetchProyectosPorVendedores(actual);
  };

  const porVendedores = async () => {
    try {
      const urlC = `${urlStrapi}/users`;
      const users = await axios.get(urlC, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      //console.log(users)
      //console.log("Usuarios", users.data)
      return users.data;

      //fetchProyectos(users.data.map((o) => o.id ), actual)
    } catch (error) {
      console.log(error);
    }
  };
  async function fetchProyectosPorVendedores(vendedor) {
    //console.log(vendedor)
    try {
      var params = {
        TableName: "Proyectos",
        FilterExpression: "#idProyecto = :idProyecto",
        ExpressionAttributeNames: {
          "#idProyecto": "vendedor",
        },
        ExpressionAttributeValues: {
          ":idProyecto": vendedor.toString(),
        },
      };

      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          console.log("Query succeeded--PROYECTOS.");
          //console.log(data.Items);
          setDatosProyectosVendedores(data.Items);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchProyectos(cliente) {
    console.log(cliente);
    try {
      var params = {
        TableName: "Proyectos",
        FilterExpression: "#idProyecto = :idProyecto",
        ExpressionAttributeNames: {
          "#idProyecto": "idProyecto",
        },
        ExpressionAttributeValues: {
          ":idProyecto": cliente.toString(),
        },
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          console.log("Query succeeded--PROYECTOS.");
          console.log(data.Items);
          setDatosProyectos(data.Items);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchContactos(cliente) {
    try {
      const proyecto = 1;
      const urlC = `${urlStrapi}/clientes`;
      const clientes = await axios.get(urlC, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      //console.log("datos", clientes.data)
      const result = clientes.data;
      const res = result.map((o) => {
        //console.log("datos", o)
        return {
          text: o.nombreContacto,
          value: o.nombreContacto,
        };
      });
      const uniqueIds = new Set();
      const unique = res.filter((element) => {
        const isDuplicate = uniqueIds.has(element.text);
        uniqueIds.add(element.text);
        if (!isDuplicate) {
          return true;
        }
        return false;
      });
      setDatosContactos(unique);
      return unique;
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchVendedores(id) {
    //console.log("Id", id)

    try {
      const proyecto = 1;
      const urlC = `${urlStrapi}/users?id=${id}`;
      const vendedores = await axios.get(urlC, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      // console.log("datos", vendedores.data[0].username)
      //     const result = vendedores.data

      //     const res = result.map((o) => {
      //     //console.log("datos", o)
      //        return {
      //             username: o.username,
      //         }

      //     })

      //     setNombre(res.username)
      //    // console.log(res)

      return vendedores.data[0].username;
    } catch (error) {
      console.log(error);
    }
  }

  const fechClientesByVendedor = async (id) => {
    try {
      const proyecto = 1;
      const urlC = `${urlStrapi}/clientes?vendedor=${id}`;
      const clientes = await axios.get(urlC, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      //console.log("datos", clientes.data)
      const result = clientes.data;
      const res = result.map((o) => {
        //console.log("datos", o)
        return {
          id: o.id,
          CARDID: o.CARDID,
          razonSocial: o.razonSocial,
          nombreContacto: o.nombreContacto,
          correo: o.correo,
          created_at: o.created_at,
        };
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const armarReporte = (proyectos) => {
    const newArr = proyectos;
    const res = proyectos.map((o) => {
      newArr.push(o);

      return newArr;
      //console.log(o)
    });
    //console.log("Data-res", res[0])
  };

  const fetchProyectosByVendedor = async (usuario, actual) => {
    console.log("fetchProyectosByVendedor", usuario, actual);
    try {
      //const mappedArray = await Promise.all(

      actual.map(async (o) => {
        const urlC = `${urlCassandra}collections/proyectos${usuario}${o.id}?page-size=20`;
        const ventasData = await axios.get(urlC, {
          method: "GET",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            "X-Cassandra-Token": tokenCasandra,
          },
        });
        const proyectos = Object.keys(ventasData.data.data).map((item) => ({
          id: item,
          ...ventasData.data.data[item],
        }));
        Promise.all(proyectos).then(function (results) {
          console.log("promesa", results);
          localStorage.setItem("proyectos", JSON.stringify(results));
        });
        // })
      });

      //localStorage.setItem('proyectos', JSON.stringify(res));

      // )
      //armarReporte(res)
      //  const newArr = pro
      //  const res = pro.map((o)=>{
      //          newArr.push(o)
      //          return newArr
      //      //console.log(o)
      //      })
      //  console.log("Data-res", res[0])
      //  //getSales(res[0])
      //  armarReporte(res[0])

      //armarReporte(res)

      //    })

      //     res
      //     .then(
      //         (val) => val
      //     )

      //console.log("Proyectos", proyectos)
      // const res = await Promise.all(
      // proyectos.map(async(o) => {
      //     //const vendedorName = await fetchVendedores(o.vendedor)
      //     //console.log("desdeProyectos", vendedorName)
      //     return {
      //         nombreProyecto: o.nombreProyecto,
      //         lineaNegocioNombre: o.lineaNegocioNombre,
      //         categoria: o.categoria,
      //         vendedor: o.vendedor,
      //         estatus: o.estatus,
      //         tipo: o.tipo,
      //         fechaCreacion: o.fechaCreacion,
      //     }
      // })
      // )

      // return res
    } catch (error) {
      console.log(error);
    }
  };

  const notificaciones = () => {
    const telefono = `525548225097`;
    const mensaje = `{
                "messaging_product": "whatsapp",
                "to": "${telefono}", 
                "type": "template", 
                "template": { 
                "name": "project_share_nctech", 
                "language": { "code": "es_MX" },
                "components": [
                                {
                                    "type": "body",
                                    "parameters": 
                                        [
                                            {
                                            "type": "text",
                                            "text": "${datos.contacto}"
                                            },
                                        ] 
                                }
                            ]
                            }
                }`;
    const headers = {
      Authorization:
        "Bearer EAAGIZAMLyTpUBAAZCOF3yYSb7aE45MIj3T6BpBNome5yS0yxC2BHA37N1anVpoltnHeDxlRCZBfPZA2ZBWzno22TAkgNqnoU2zqZCy0sTkO0TQ1ZBwuu5XOWgTGWBOANUo6xYgH6OruZAoZC9qUt5eBIhpamQv2etu32rSy4HqDHShfJ0ZAKnZCy83GZC4EGs7FU4lKKfZAsRhMt1jAZDZD",
      "Content-Type": "application/json",
    };
    fetch("https://graph.facebook.com/v13.0/103800795803097/messages", {
      method: "POST",
      body: mensaje,
      headers: headers,
    });
  };

  useEffect(() => {}, [proyectos]);

  async function GenerarReporte() {
    try {
      var params = {
        TableName: "Proyectos",
        // FilterExpression: "#idProyecto = :idProyecto",
        // ExpressionAttributeNames: {
        //     "#idProyecto": "idProyecto",
        // },
        // ExpressionAttributeValues: {
        //     ":idProyecto": id
        // }
      };

      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          console.log("Query succeeded--PROYECTOS.");
          //console.log(data.Items);
          setDatosProyectos(data.Items);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  const query = useQuery("PROYECTOS", fechClientes);
  const result = query.data;
  const { queryVendedores, refetch: refetchVendedores } = useQuery(
    "VENDEDORES",
    porVendedores,
    {
      onSuccess: (data) => {
        setListaVendedores(data);
      },
    },
  );
  const { refetch: refetchContactos } = useQuery("CONTACTOS", fetchContactos, {
    onSuccess: (data) => {
      console.log(data);
    },
  });

  const addCliente = () => {
    window.location.href = "/vendedor/clientes/new";
  };
  const handlerNotas = (event) => {
    var today = new Date();
    const fecha = today.toLocaleDateString("es-MX");
    const str = event;
    const res = str.replace("/hoy", fecha);
    setNotas(res);
  };
  const expandedRowRender = () => {
    const columns = [
      {
        title: "Nombre del Proyecto",
        dataIndex: "nombreProyecto",
        key: "nombreProyecto",
      },
      {
        title: "Linea de Negocios",
        dataIndex: "lineaNegocioNombre",
        key: "lineaNegocioNombre",
      },
      {
        title: "ID de la oportunidad",
        dataIndex: "lineaNegocioNombre",
        key: "lineaNegocioNombre",
      },
      {
        title: "Categoria",
        dataIndex: "categoria",
        key: "categoria",
      },
      {
        title: "Vendedor",
        dataIndex: "vendedorName",
        key: "vendedorName",
      },
      {
        title: "Estatus",
        dataIndex: "estatus",
        key: "estatus",
        render: (record) => (
          <span>
            <Badge status="success" />
            {record}
          </span>
        ),
      },
      {
        title: "Tipo de Proyecto",
        dataIndex: "tipo",
        key: "tipo",
      },
      {
        title: "Fecha Creación",
        dataIndex: "fechaCreacion",
        key: "fechaCreacion",
        render: (record) => (
          <span>
            <Moment locale="ES" format="DD/MM/yyyy hh:mm:ss">
              {record}
            </Moment>
          </span>
        ),
      },
    ];
    return (
      <>
        <RenderIf isTrue={bandera == true}>
          <Table
            fontFamily={fuente}
            bg={"green-50"}
            rowKey={(record) => record.id}
            columns={columns}
            expandable={{
              defaultExpandedRowKeys: ["0"],
            }}
            dataSource={datosProyectos}
            pagination={false}
          />
        </RenderIf>
        <RenderIf isTrue={vendedores == true}>
          <Table
            fontFamily={fuente}
            bg={"green-50"}
            rowKey={(record) => record.id}
            columns={columns}
            expandable={{
              defaultExpandedRowKeys: ["0"],
            }}
            dataSource={datosProyectosVendedores}
            pagination={false}
          />
        </RenderIf>
        <RenderIf isTrue={contactos == true}>
          <Table
            fontFamily={fuente}
            bg={"green-50"}
            rowKey={(record) => record.id}
            columns={columns}
            expandable={{
              defaultExpandedRowKeys: ["0"],
            }}
            dataSource={datosProyectos}
            pagination={false}
          />
        </RenderIf>
      </>
    );
  };
  const columnsContactos = [
    {
      title: "CARD ID",
      dataIndex: "CARDID",
      key: "CARDID",
    },
    {
      title: "Razón Social",
      dataIndex: "razonSocial",
      key: "razonSocial",
    },
    {
      title: "Nombre del Contacto",
      dataIndex: "nombreContacto",
      key: "nombreContacto",
      filters: datosContactos,
      onFilter: (value, record) =>
        record.nombreContacto
          ? record.nombreContacto
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : false,
    },
    {
      title: "Correo del Contacto",
      dataIndex: "correo",
      key: "correo",
    },
  ];
  const columnsVendesores = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "NOMBRE VENDEDOR",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "EMAIL VENDEDOR",
      dataIndex: "email",
      key: "email",
    },
  ];
  const columns = [
    {
      title: "CARD ID",
      dataIndex: "CARDID",
      key: "CARDID",
    },
    {
      title: "Razón Social",
      dataIndex: "razonSocial",
      key: "razonSocial",
    },
    {
      title: "Nombre del Contacto",
      dataIndex: "nombreContacto",
      key: "nombreContacto",
    },
    {
      title: "Correo del Contacto",
      dataIndex: "correo",
      key: "correo",
    },
  ];
  const onTableRowExpand = (expanded, record) => {
    const keys = [];
    if (expanded) {
      keys.push(record.id); // I have set my record.id as row key. Check the documentation for more details.
      setRowActual(record.id);
      usuarios(record.id);
    }
    if (expanded == false) {
      setDatosProyectos([]);
    }
    setExpandedRowKeys(keys);
  };
  const onTableRowExpandVendedores = (expanded, record) => {
    const keys = [];
    if (expanded) {
      keys.push(record.id); // I have set my record.id as row key. Check the documentation for more details.
      setRowActualVendedores(record.id);
      usuariosVendedores(record.id);
    }
    if (expanded == false) {
      setDatosProyectosVendedores([]);
    }
    setExpandedRowKeysVendedores(keys);
  };
  const porCliente = (estado) => {
    setTitulo("Cliente");
    setBandera(estado);
    setVendedores(false);
    setContactos(false);
  };
  const porVendedor = (estado) => {
    setTitulo("Vendedor");
    setBandera(false);
    setContactos(false);
    setVendedores(estado);
    refetchVendedores();
  };
  const porContacto = (estado) => {
    setTitulo("Contacto");
    setContactos(estado);
    setBandera(false);
    setVendedores(false);
  };

  return (
    <>
      <Flex direction={{ base: "column", lg: "row" }} bg={"white"} h={"100vh"}>
        <MenuVendedor />
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"white"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>
            <Box bg={"white"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
              <Flex direction={"column"}>
                <Box bg={"white"}>
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "20%" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box bg={"white"} w={{ base: "100%", lg: "60%" }}>
                      <Flex direction={"column"}>
                        <Box bg={"white"}>
                          <Flex direction={"row"}>
                            <Box bg={"white"} w={"70%"}>
                              <Flex direction={"column"}>
                                <Box bg={"white"} w={"100%"}>
                                  <Box
                                    alignSelf={"end"}
                                    fontSize={{
                                      lg: "17px",
                                      base: "2.0rem",
                                      "2xl": "33px",
                                    }}
                                    fontWeight={"bold"}
                                    color={"gray.700"}
                                    fontFamily={fuente}
                                    ml={{ lg: "0", base: "5" }}
                                    mt={{ lg: "8", base: "5" }}
                                  >
                                    ¡Resumen de Proyectos!
                                  </Box>
                                </Box>
                                <Box bg={"white"} w={"100%"}>
                                  <Box
                                    fontSize={{
                                      lg: "sm",
                                      base: "sm",
                                      "2xl": "sm",
                                    }}
                                    fontFamily={fuente}
                                    fontWeight={"medium"}
                                    color={"blue.500"}
                                    ml={{ lg: "0", base: "5" }}
                                    mb={{ lg: "10", base: "0" }}
                                  ></Box>
                                </Box>
                              </Flex>
                            </Box>
                            <Box bg={"white"} w={"30%"}>
                              <Flex direction={"column"} justifyContent={"end"}>
                                <Box
                                  bg={"white"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                  align={"end"}
                                  mt={8}
                                  pr={{ base: 10, lg: 0 }}
                                ></Box>
                                <Box bg={"white"} w={"100%"} h={"4.16vh"}></Box>
                                <Box bg={"white"} w={"100%"} h={"4.16vh"}></Box>
                              </Flex>
                            </Box>
                          </Flex>
                        </Box>
                        <Divider />
                      </Flex>
                    </Box>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "20%" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
                <Box
                  bg={"white"}
                  h={{ base: "65vh", lg: "65vh" }}
                  pl={{ base: 0, lg: 0 }}
                  pr={{ base: 0, lg: 0 }}
                >
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"white"}
                      w={{ base: "0", lg: "100%" }}
                      h={{ base: "0", lg: "65vh" }}
                    >
                      <Box
                        fontSize={"16px"}
                        fontWeight={"medium"}
                        color={"gray.600"}
                        my={5}
                        fontFamily={fuente}
                      >
                        {" "}
                        Listado de proyectos por {titulo}
                      </Box>
                      <Box
                        fontSize={"15px"}
                        fontWeight={"Bold"}
                        color={"gray.600"}
                        my={5}
                        fontFamily={fuente}
                      >
                        {" "}
                        Filtros
                      </Box>
                      <Flex direction={"row"} mb={5}>
                        <Button
                          alignSelf={"center"}
                          mx={2}
                          onClick={() => porCliente(true)}
                        >
                          <BsFilter px={2} fontFamily={fuente} /> por
                          cliente(Default)
                        </Button>
                        <Button
                          alignSelf={"center"}
                          mx={2}
                          onClick={() => porVendedor(true)}
                        >
                          <BsFilter px={2} fontFamily={fuente} /> por vendedor
                        </Button>
                        <Button
                          alignSelf={"center"}
                          mx={2}
                          onClick={() => porContacto(true)}
                        >
                          <BsFilter px={2} fontFamily={fuente} />
                          por contacto
                        </Button>
                      </Flex>
                      <Box borderWidth={1} borderRadius={"12px"} py={3} mr={10}>
                        <RenderIf isTrue={bandera == true}>
                          <Table
                            rowKey={(record) => record.id}
                            columns={columns}
                            expandable={{
                              expandedRowRender,
                              expandedRowKeys: expandedRowKeys,
                              onExpand: onTableRowExpand,
                              fixed: "right",
                            }}
                            dataSource={result}
                            size="small"
                            fontFamily={fuente}
                          />
                        </RenderIf>
                        <RenderIf isTrue={vendedores == true}>
                          <Table
                            rowKey={(record) => record.id}
                            columns={columnsVendesores}
                            expandable={{
                              expandedRowRender,
                              expandedRowKeys: expandedRowKeysVendedores,
                              onExpand: onTableRowExpandVendedores,
                              fixed: "right",
                            }}
                            dataSource={listaVendedores}
                            size="small"
                            fontFamily={fuente}
                          />
                        </RenderIf>
                        <RenderIf isTrue={contactos == true}>
                          <Table
                            rowKey={(record) => record.id}
                            columns={columnsContactos}
                            expandable={{
                              expandedRowRender,
                              expandedRowKeys: expandedRowKeys,
                              onExpand: onTableRowExpand,
                              fixed: "right",
                            }}
                            dataSource={result}
                            size="small"
                            fontFamily={fuente}
                          />
                        </RenderIf>
                      </Box>
                    </Box>
                    {/* Tabla para movil*/}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Detalles;
