import React, { useState } from "react";
import {
  Spinner,
  Input,
  Button,
  Box,
  Center,
  useDisclosure,
  Flex,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Select,
} from "@chakra-ui/react";
import Header from "../../Header";
import { AddIcon, DragHandleIcon } from "@chakra-ui/icons";
import useUser from "../../context/useUser";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import MenuVendedor from "../Utilidades/MenuVendedor";
import UploadContrato from "../../UploadContratotoS3";
import "moment-timezone";
import { Space, Table } from "antd";
import "antd/dist/antd.css";
import { RenderIf } from "../../../hooks/renderIF";
import MenuGerente from "../../Gerente/Utilidades/MenuGerente";
const Contratos = () => {
  const queryClient = useQueryClient();
  const [datos, setDatos] = useState([]);
  const [urlCassandra] = useState(process.env.REACT_APP_URL_CASSANDRA);
  const [tokenCasandra] = useState(process.env.REACT_APP_TOKEN_CASSANDRA);
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [nombreContrato, setNombreContrato] = useState("");
  const [tipoContrato, setTipoContrato] = useState("");
  const [fechaEmision, setFechaEmision] = useState("");
  const [urlContrato, setUrlContrato] = useState("");
  const [setCargando] = useState(false);
  const { Column } = Table;
  async function fetchPresentaciones() {
    //console.log("refetch")
    //const urlC = `${urlCassandra}collections/contratos${user.id.toString()}?page-size=20`
    const urlC = `https://servicesemail-production.herokuapp.com/api/contratos`;
    const ventasData = await axios(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    console.log(ventasData.response);
    const presentaciones = await Object.keys(ventasData.data).map((item) => ({
      id: item,
      ...ventasData.data[item],
    }));
    return presentaciones;
  }
  const crearPresentacion = async (data) => {
    const urlC = `${urlCassandra}collections/contratos${user.id.toString()}`;
    const response = await fetch(urlC, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "X-Cassandra-Token": tokenCasandra,
      },
      body: JSON.stringify({
        nombre: nombreContrato,
        tipo: tipoContrato,
        fechaEmision: fechaEmision,
        fechaCreacion: Date.now(),
        status: "Activo",
        url: urlContrato,
        provider: "s3",
      }),
    });

    if (response.status === 500) {
      console.log("Segundo Intento");
      const urlC = `${urlCassandra}collections/contratos${user.id.toString()}`;
      await fetch(urlC, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "X-Cassandra-Token": tokenCasandra,
        },
        body: JSON.stringify({
          nombre: nombreContrato,
          tipo: tipoContrato,
          fechaEmision: fechaEmision,
          fechaCreacion: Date.now(),
          status: "Activo",
          url: urlContrato,
          provider: "s3",
        }),
      });
      onClose();
    }

    onClose();
    return response;
  };

  const eliminarPresentacion = async (id) => {
    console.log(id);
    //const urlC = `${urlCassandra}collections/contratos${user.id.toString()}/${id}`
    const urlC = `https://servicesemail-production.herokuapp.com/api/eliminarContratos/${id}`;
    await fetch(urlC, {
      method: "DELETE",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  };
  async function actualizar() {
    const urlC = `${urlCassandra}collections/contratos${user.id.toString()}?page-size=20`;
    const ventasData = await axios(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "X-Cassandra-Token": tokenCasandra,
      },
    });
    console.log(ventasData.response);
    const presentaciones = await Object.keys(ventasData.data.data).map(
      (item) => ({ id: item, ...ventasData.data.data[item] }),
    );
    return presentaciones;
  }
  // const mutation_duplicar = useMutation(duplicarPresentacion,   {
  //     onSuccess: () => {
  //         queryClient.invalidateQueries('PRESENTACIONES')
  //     },
  //     onError: (error) => {
  //         console.log(error)
  //     }
  // })
  const mutation_delete = useMutation(eliminarPresentacion, {
    onSuccess: () => {
      queryClient.invalidateQueries("PRESENTACIONES");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  useMutation(crearPresentacion, {
    onSuccess: () => {
      queryClient.invalidateQueries("PRESENTACIONES");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { isLoading, refetch } = useQuery(
    "PRESENTACIONES",
    fetchPresentaciones,
    {
      onSuccess: (data) => {
        setDatos(data);
      },
      cacheTime: Infinity,
    },
  );
  if (isLoading) {
    return (
      <Center mt={"10%"}>
        <Spinner color={"green.700"} />
      </Center>
    );
  }

  const handleDelete = async (id) => {
    mutation_delete.mutate(id);
  };
  const handlerNombrePresentacion = (e) => {
    setNombreContrato(e);
  };
  const handlerTipoContrato = (e) => {
    setTipoContrato(e);
  };
  const handlerFechaEmision = (e) => {
    setFechaEmision(e);
  };
  const query = (e) => {
    setUrlContrato(e);
    setCargando(false);
  };
  // const duplicar = (nombrePresentacion,status,url,fechaCreacion, provider) =>{
  //     console.log("Duplicar")
  //     mutation_duplicar.mutate({nombrePresentacion,status,url,fechaCreacion, provider})
  // }
  console.log(user);
  return (
    <>
      <Modal
        size={"xl"}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        zIndex={"9999"}
      >
        <div>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              fontSize={"lg"}
              fontWeight={"bold"}
              fontFamily={"system-ui, sans-serif"}
            >
              Subir nuevo contrato
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Box p={"4"} borderWidth={"1px"} rounded={"lg"} bg={"gray.50"}>
                <Box
                  my={1}
                  fontSize={"sm"}
                  fontWeight={"medium"}
                  color={"gray.500"}
                  fontFamily={"system-ui, sans-serif"}
                >
                  Escribe un nombre para tu contrato
                </Box>
                <Input
                  bg={"white"}
                  onChange={(e) => handlerNombrePresentacion(e.target.value)}
                  type={"text"}
                  placeholder={"Contrato de Prueba"}
                  fontSize={"sm"}
                  fontWeight={"normal"}
                  color={"gray.800"}
                  fontFamily={"system-ui, sans-serif"}
                />
                <Box
                  my={1}
                  fontSize={"sm"}
                  fontWeight={"medium"}
                  color={"gray.500"}
                  fontFamily={"system-ui, sans-serif"}
                >
                  Selecciona el tipo de documento
                </Box>
                <Select
                  bg={"white"}
                  onChange={(e) => handlerTipoContrato(e.target.value)}
                  type={"text"}
                  placeholder={"Selecciona aquí..."}
                  fontSize={"sm"}
                  fontWeight={"normal"}
                  color={"gray.800"}
                  fontFamily={"system-ui, sans-serif"}
                >
                  <option value="Contrato de Servicio">
                    Contrato de Servicio
                  </option>
                  <option value="Contrato de Compra-Venta">
                    Contrato de Compra-Venta
                  </option>
                  <option value="Acuerdo de confidencialidad">
                    Acuerdo de confidencialidad
                  </option>
                  <option value="Acuerdos comerciales">
                    Acuerdos comerciales
                  </option>
                  <option value="Otros">Otros</option>
                </Select>
                <Box
                  my={1}
                  fontSize={"sm"}
                  fontWeight={"medium"}
                  color={"gray.500"}
                  fontFamily={"system-ui, sans-serif"}
                >
                  Fecha de Emisión
                </Box>
                <Input
                  bg={"white"}
                  onChange={(e) => handlerFechaEmision(e.target.value)}
                  type={"date"}
                  placeholder={"Contrato de Prueba"}
                  fontSize={"sm"}
                  fontWeight={"normal"}
                  color={"gray.800"}
                  fontFamily={"system-ui, sans-serif"}
                />
                <RenderIf isTrue={nombreContrato !== ""}>
                  <UploadContrato
                    nombre={nombreContrato}
                    tipoContrato={tipoContrato}
                    fechaEmision={fechaEmision}
                    cerrar={onClose}
                    query={refetch}
                  />
                </RenderIf>
              </Box>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </div>
      </Modal>
      <Flex direction={{ base: "column", lg: "row" }} bg={"pink"} h={"100vh"}>
        <RenderIf isTrue={user.type !== 3}>
          <MenuVendedor />
        </RenderIf>
        <RenderIf isTrue={user.type === 3}>
          <MenuGerente />
        </RenderIf>
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>

            <Box bg={"gray.200"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
              <Flex direction={"column"}>
                <Box bg={"gray.100"} h={{ base: "25vh", lg: "25vh" }}>
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bgGradient="linear(to-r, gray.50, gray.200)"
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"gray.200"}
                      w={{ base: "100%", lg: "60%" }}
                      h={{ base: "25vh", lg: "25vh" }}
                    >
                      <Flex direction={"column"}>
                        <Box bg={"gray.300"} h={"12.5vh"}>
                          <Flex direction={"row"}>
                            <Box bg={"gray.500"} w={"70%"} h={"12.5vh"}>
                              <Flex direction={"column"}>
                                <Box bg={"gray.200"} w={"100%"} h={"8.25vh"}>
                                  <Box
                                    alignSelf={"end"}
                                    fontSize={{
                                      lg: "21px",
                                      base: "23px",
                                      "2xl": "33px",
                                    }}
                                    fontWeight={"semibold"}
                                    color={"gray.700"}
                                    fontFamily={"system-ui, sans-serif"}
                                    ml={{ lg: "0", base: "5" }}
                                    mt={{ lg: "6", base: "5" }}
                                  >
                                    Biblioteca de Contratos
                                  </Box>
                                </Box>
                                <Box bg={"gray.200"} w={"100%"}>
                                  <Box
                                    fontSize={"17px"}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    ml={{ lg: "0", base: "5" }}
                                    fontFamily={"system-ui, sans-serif"}
                                  ></Box>
                                </Box>
                                <Box bg={"gray.200"} w={"100%"} h={"4.25vh"}>
                                  <Box
                                    fontSize={"sm"}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    fontFamily={"system-ui, sans-serif"}
                                    ml={{ lg: "0", base: "5" }}
                                    mb={{ lg: "10", base: "0" }}
                                  >
                                    Aquí podras subir y ver los contratos de tu
                                    compañia
                                  </Box>
                                </Box>
                              </Flex>
                            </Box>
                            <Box bg={"gray.200"} w={"30%"} h={"12.5vh"}>
                              <Flex direction={"column"} justifyContent={"end"}>
                                <Box
                                  bg={"gray.200"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                  align={"end"}
                                  mt={8}
                                  pr={{ base: 10, lg: 0 }}
                                >
                                  <Tooltip
                                    label="Subir nueva presentación"
                                    aria-label="A tooltip"
                                    placement="right"
                                    borderRadius={"sm"}
                                  >
                                    <Button
                                      colorScheme={"orange"}
                                      onClick={onOpen}
                                    >
                                      <AddIcon />
                                    </Button>
                                  </Tooltip>
                                </Box>
                                <Box
                                  bg={"grya.100"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                ></Box>
                                <Box
                                  bg={"grya.100"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                ></Box>
                              </Flex>
                            </Box>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bgGradient="linear(to-r, gray.200, gray.50)"
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
                <Box
                  bg={"white"}
                  h={{ base: "65vh", lg: "65vh" }}
                  pl={{ base: 0, lg: 0 }}
                  pr={{ base: 0, lg: 0 }}
                >
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"white"}
                      w={{ base: "0", lg: "90%" }}
                      h={{ base: "0", lg: "65vh" }}
                    >
                      {datos ? (
                        <Box bg={"white"} w={"100%"} mt={5}>
                          <Center>
                            <Box
                              borderWidth={1}
                              borderRadius={"12px"}
                              zIndex={1}
                              py={3}
                            >
                              <Table
                                dataSource={datos}
                                bordered
                                size="sm"
                                pagination={{
                                  pageSize: 5,
                                }}
                              >
                                <Column
                                  title="Nombre del Documento"
                                  dataIndex="nombre"
                                  key="nombre"
                                  render={(text, record, index) => {
                                    return (
                                      <Box key={index}>{record.nombre}</Box>
                                    );
                                  }}
                                />
                                <Column
                                  title="Tipo de Documento"
                                  dataIndex="tipo"
                                  key="tipo"
                                  render={(text, record, index) => {
                                    return <Box key={index}>{record.tipo}</Box>;
                                  }}
                                />
                                <Column
                                  title="Fecha de Emisión"
                                  dataIndex="fechaEmision"
                                  key="fechaEmision"
                                  render={(text, record, index) => {
                                    return (
                                      <Box key={index}>
                                        {record.fechaEmision}
                                      </Box>
                                    );
                                  }}
                                />
                                <Column
                                  title="Acción"
                                  dataIndex="estatusAdmin"
                                  key="estatusAdmin"
                                  render={(text, record, index) => (
                                    <Space size="middle">
                                      <Menu>
                                        <MenuButton
                                          as={Button}
                                          rightIcon={<DragHandleIcon />}
                                        >
                                          Acciones
                                        </MenuButton>
                                        <MenuList>
                                          <MenuItem
                                            onClick={() =>
                                              handleDelete(record.id)
                                            }
                                          >
                                            Eliminar
                                          </MenuItem>
                                        </MenuList>
                                      </Menu>
                                    </Space>
                                  )}
                                />
                              </Table>
                            </Box>
                          </Center>
                        </Box>
                      ) : (
                        "Aún no cuentas con contratos"
                      )}
                    </Box>
                    {/* Tabla para movil*/}
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Contratos;
