import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import Routes from "./components/routes";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <div>
          <Routes />
        </div>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
