import React, { useRef, useCallback, useState, useEffect } from "react";
import Webcam from "react-webcam";
import { uploadFile } from "react-s3";
import { useToast, Button, Box, Flex, Input } from "@chakra-ui/react";
import * as AWS from "aws-sdk";
import { nanoid } from "nanoid";

const S3_BUCKET = "nctech-videos";
const REGION = "us-east-1";
const ACCESS_KEY = "AKIARVGY33F62XNKJJK3";
const SECRET_ACCESS_KEY = "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";

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};
//AWS.config.update({ region: 'us-east-1', secretAccessKey: 'DNtf9KVvFwLGdzIFSq4vR3V8+K+4vMAfIO/DdKnv', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' });
AWS.config.update({
  region: "us-east-1",
  secretAccessKey: "3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi",
  accessKeyId: "AKIARVGY33F62XNKJJK3",
});

const SubirVideo = () => {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [subiendo, setSubiendo] = useState(false);
  const [nombre, setNombre] = useState();
  const [device, setDevice] = useState(null);
  const toast = useToast();
  const docClient = new AWS.DynamoDB.DocumentClient({
    apiVersion: "2022-01-01",
  });

  function getQueryParams(qs) {
    qs = qs.split("+").join(" ");

    var params = {},
      tokens,
      re = /[?&]?([^=]+)=([^&]*)/g;

    //while (tokens = re.exec(qs)) {
    while ((tokens = re.exec(qs))) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
    }

    return params;
  }
  function getPlatform() {
    var uA = navigator.userAgent || navigator.vendor || window.opera;
    if (
      (/iPad|iPhone|iPod/.test(uA) && !window.MSStream) ||
      (uA.includes("Mac") && "ontouchend" in document)
    )
      return "iOS";

    var i,
      os = ["Windows", "Android", "Unix", "Mac", "Linux", "BlackBerry"];
    for (i = 0; i < os.length; i++)
      if (new RegExp(os[i], "i").test(uA)) return os[i];
  }

  const putData = async (tableName, data) => {
    console.log(tableName, data);
    var params = {
      TableName: tableName,
      Item: data,
    };

    await docClient.put(params, function (err, data) {
      if (err) {
        console.log("Error", err);
      } else {
        aviso();
        setCargando(false);
        setSubiendo(false);
      }
    });
  };

  const crearVideo = async (data) => {
    const query = getQueryParams(document.location.search);

    const videosData = {
      id: nanoid(),
      nombreVideo: data.key,
      fechaCreacion: Date.now(),
      status: "Activo",
      url: data.location,
      usuario: query.token,
      tipo: "",
    };
    await putData("Videos", videosData);
  };
  const handleStartCaptureClick = useCallback(() => {
    const s = getPlatform();
    setCapturing(true);

    if (s === "iOS") {
      //setBandera(s)
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        //mimeType: "video/webm"
        mimeType: "video/mp4",
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable,
      );
      mediaRecorderRef.current.start();
    } else {
      console.log("entra oTRO");
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: "video/webm",
        //mimeType: "video/mp4"
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable,
      );
      mediaRecorderRef.current.start();
    }
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks],
  );

  const handleStopCaptureClick = useCallback(() => {
    setSubiendo(true);
    setCapturing(false);
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  function aviso(data) {
    toast({
      title: "Video subido satisfactoriamente",
      description: "",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  }

  const handleDownload = useCallback(async () => {
    console.log("nombre", nombre);
    setCargando(true);
    setSubiendo(true);
    if (recordedChunks.length) {
      if (device === "iOS") {
        const blob = new Blob(recordedChunks, {
          type: "video/mp4",
        });
        blob.lastModifiedDate = new Date();
        const fecha = new Date();
        //const nombre = `nombre`
        blob.name = nombre + ".mp4";

        await uploadFile(blob, config)
          .then((data) => crearVideo(data))
          .catch((err) => console.error(err));

        setRecordedChunks([]);
      } else {
        const blob = new Blob(recordedChunks, {
          type: "video/webm",
        });
        blob.lastModifiedDate = new Date();
        const fecha = new Date();
        //const nombre = `nombre`
        blob.name = nombre + ".mp4";

        await uploadFile(blob, config)
          .then((data) => crearVideo(data))
          .catch((err) => console.error(err));

        setRecordedChunks([]);
      }
    }
  }, [recordedChunks]);

  useEffect(() => {
    const s = getPlatform();
    setDevice(s);
  }, []);

  const handlerChange = (e) => {
    console.log(e.target.value);
    setNombre(e.target.value);
  };
  return (
    <Flex direction={"column"}>
      <>
        <Webcam audio={true} ref={webcamRef} />
        <Flex justify={"center"}>
          <Box alignSelf={"center"}>
            {subiendo === false ? (
              <>
                {capturing ? (
                  <Flex direction={"column"} justify={"center"} w={"100%"}>
                    <Box
                      textAlign={"center"}
                      fontFamily={"system-ui"}
                      fontWeight={"medium"}
                      fontSize={"15pt"}
                      my={"2"}
                    >
                      Detener Grabación
                    </Box>
                    <Box
                      alignSelf={"center"}
                      rounded={"full"}
                      bg={"pink"}
                      overflow={"hidden"}
                      w={14}
                      p={2}
                    >
                      <Button
                        _hover={{ bg: "red.500" }}
                        onClick={handleStopCaptureClick}
                        bg={"red"}
                        rounded={"15px"}
                      ></Button>
                    </Box>
                  </Flex>
                ) : (
                  <Flex direction={"column"} justify={"center"} w={"100%"}>
                    <Box
                      textAlign={"center"}
                      fontFamily={"system-ui"}
                      fontWeight={"medium"}
                      fontSize={"15pt"}
                      my={"2"}
                    >
                      Nombre de para tu video
                    </Box>
                    <Input
                      type="text"
                      onChange={(e) => handlerChange(e)}
                      placeholder={"Escribe un nombre para tu video..."}
                    />
                    <Box
                      textAlign={"center"}
                      fontFamily={"system-ui"}
                      fontWeight={"medium"}
                      fontSize={"15pt"}
                      my={"2"}
                    >
                      Comenzar a grabar
                    </Box>
                    <Box
                      alignSelf={"center"}
                      rounded={"full"}
                      bg={"pink"}
                      overflow={"hidden"}
                      w={14}
                      p={2}
                    >
                      <Button
                        _hover={{ bg: "red.500" }}
                        onClick={handleStartCaptureClick}
                        bg={"red"}
                        rounded={"full"}
                      ></Button>
                    </Box>
                  </Flex>
                )}{" "}
              </>
            ) : (
              <>
                {recordedChunks.length > 0 && (
                  <Flex direction={"column"} justify={"center"} w={"100%"}>
                    <Box alignSelf={"center"} mt={10}>
                      <Button
                        isLoading={cargando}
                        loadingText="Subiendo video..."
                        colorScheme="teal"
                        variant="outline"
                        onClick={handleDownload}
                        rounded={"lg"}
                      >
                        Subir video
                      </Button>
                    </Box>
                  </Flex>
                )}
              </>
            )}
          </Box>
        </Flex>
      </>
    </Flex>
  );
};

export default SubirVideo;
